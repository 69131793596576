export const validateChargeTicket = (user, allCpts, cptIds, forceClinic) => {

    if (user.role === '1' || user.role === '4') { return true; } // ignore validation for corporate users OR training

    let userType = `${user.role}` === '3' ? 'CRNA' : 'PA';

    let cptsIdList = cptIds.map(cpt => cpt.id);

    // MUST build CPTs in exact order on the charge ticket (map from All CPTs list form the API)
    let cpts = [];
    for (let i = 0; i < cptsIdList.length; i++) {
        let foundCpt = allCpts.find(cpt => cpt.id === cptsIdList[i]);
        if (foundCpt) { cpts.push(foundCpt); }
    }

    let error = {
        errorType: 'warn',
        errorMsg: ''
    };

    const isClinic = forceClinic || userType === 'PA'; // JSON.stringify(cpts).includes("\"code\":\"99");    
    if (isClinic) { // CLINIC

        // WARNING
        if (isClinicCptCodeWarning(`${cpts[0].id}`)) {
            error.errorType = 'warn';
            error.errorMsg = `You’ve entered a CPT code or CPT code combination that is rare (${cpts[0].code}). Are you sure you want to submit this ticket?`;
            if (window.confirm(error.errorMsg)) {
                return true;
            } else {
                return false;
            }
        }

        // ERROR (not valid)
        if (!isClinicCptCodeValid(`${cpts[0].id}`)) {
            error.errorType = 'error';
            error.errorMsg = `You’ve entered an incorrect CPT code or CPT code combination (${cpts[0].code}). Please correct and submit charge ticket. Typically, the first CPT code is the problem.`;
            window.alert(error.errorMsg);
            return false;
        }
    }
    else { // PROCEDURE

        // ERROR for Primary (first) CPT code
        if (!isProcedurePrimaryCptValid(`${cpts[0].id}`)) {
            error.errorType = 'error';
            error.errorMsg = `You’ve entered an incorrect CPT code or CPT code combination (${cpts[0].code}). Please correct and submit charge ticket. Typically, the first CPT code is the problem.`;
            window.alert(error.errorMsg);
            return false;
        }

        // ERROR for all other (2+) CPT codes
        for (let i = 1; i < cpts.length; i++) {
            if (!isProcedureSecondaryCptValid(`${cpts[i].id}`)) {
                error.errorType = 'error';
                error.errorMsg = `You’ve entered an incorrect CPT code or CPT code combination (${cpts[i].code}). Please correct and submit charge ticket.`;
                window.alert(error.errorMsg);
                return false;
            }
        }
    }
    
    return true;
}

function isClinicCptCodeValid(cpt) {
    let validClinicCptCodes = [
        "30",
        "66",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "88",
        "98",
        "99",
        "101"
    ];

    return validClinicCptCodes.includes(cpt);
}

function isClinicCptCodeWarning(cpt) {
    let warningClinicCptCodes = [
        "6",
        "7",
        "16",
        "19",
        "95",
        "20610-50"
    ];

    return warningClinicCptCodes.includes(cpt);
}

function isProcedurePrimaryCptValid(cpt) {
    let invalidPrimaryCptCodes = [
        "33",
        "35",
        "36",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "57",
        "59",
        "63",
        "64",
        "67",
        "75",
        "77",
        "78",
        "92",
        "96",
        "106",
        "108",
        "109",
        "113",
        "114",
        "115",
        "116",
        "117",
        "118",
        "119",
        "120",
        "121",
        "122",
        "123",
        "124",
        "125",
        "126",
        "127",
        "128",
        "129",
        "130",
        "131",
        "132",
        "133",
        "134",
        "135",
        "136",
        "148",
        "149",
        "150",
        "151",
        "157",
        "158",
        "159",
        "160",
        "161",
        "166",
        "168",
        "169",
        "171",
        "172",
        "175",
        "177",
        "185",
        "186",
        "187",
        "188",
        "193",
        "194",
        "195",
        "196",
        "214",
        "215",
        "216",
        "220",
        "221",
        "222",
        "223",
        "224",
        "225",
        "226",
        "227",
        "234"
    ];

    return !invalidPrimaryCptCodes.includes(cpt);
}

function isProcedureSecondaryCptValid(cpt) {
    let invalidSecondaryCptCodes = [
        "2",
        "4",
        "5",
        "12",
        // "13",
        "17",
        "22",
        "31",
        "32",
        "39",
        "47",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        // "55",
        "56",
        "58",
        "60",
        "61",
        "62",
        "65",
        "76",
        "79",
        "80",
        "81",
        "84",
        "86",
        "89",
        "91",
        "93",
        "103",
        "137",
        "154",
        // "173",
        "184",
        "192",
        "202",
        "203",
        "204",
        "30",
        "66",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "88",
        "98",
        "99",
        "101",
        "207",
        "210",
        "213",
        "219",
        "238"
    ];

    return !invalidSecondaryCptCodes.includes(cpt);
}
