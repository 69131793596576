import React, { useState } from "react"
import SEO from "./seo"
import { gql } from "apollo-boost"
import { useMutation } from 'react-apollo';
import Button from '@material-ui/core/Button';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import PermissionsProvider, { roleOptions } from "../providers/permissions-provider";

const CREATE_USER = gql`
	mutation createUser($user: UserInput!) {
		createUser(user: $user) {
            id
		}
	}
`

const UPDATE_USER = gql`
	mutation updateUser($user: UserUpdateInput!) {
		updateUser(user: $user) {
            id
		}
	}
`

const Profile = (props) => {
    const inputStyle = "m-1 shadow appearance-none border rounded max-w-sm py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    const [firstName, setFirstName] = useState(props.selectedUser ? props.selectedUser.firstName : null)
    const [lastName, setLastName] = useState(props.selectedUser ? props.selectedUser.lastName : null)
    const [email, setEmail] = useState(props.selectedUser ? props.selectedUser.email : null)
    const [kareoId, setKareoId] = useState(props.selectedUser ? props.selectedUser.kareoId : null)
    const [role, setRole] = useState(props.selectedUser.roles ? props.selectedUser.roles[0].id : null)
    const [newPassword, setNewPassword] = useState(null)
    const [resetPassword, setResetPassword] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [errors, setErrors] = useState(null)
    const [spinning, setSpinning] = useState(false)
    const [createUser] = useMutation(CREATE_USER)
    const [updateUser] = useMutation(UPDATE_USER)

    const [roles, setRoles] = useState(props.selectedUser.roles)

    async function cancel(e) {
        setSpinning(true)
        await props.refetch()
        props.setSelectedUser(null)
    }

    async function resetUserPassword(e) {
        setSpinning(true);
        let user = {
            id: props.selectedUser.id,
            kareoId: props.selectedUser.kareoId,
            email: props.selectedUser.email,
            firstName: props.selectedUser.firstName,
            lastName: props.selectedUser.lastName,
            isActive: props.selectedUser.isActive === 'true',
            isLocked: props.selectedUser.isLocked === 'true',
            requirePasswordReset: true
        };
        try {
            await updateUser({ variables: { user: user } });
            cancel(e);
        } catch (err) {
            setSpinning(false);
            alert('Error saving new settings. Please try again.')
        }
    }

    const handleRoleChange = (role, event) => {
        console.log(`Event (${event.target.name} | ${event.target.checked}) => `, role);
        if (event.target.checked) {
            // add to user roles
            let newRoles = roles ? [...roles] : [];
            newRoles.push(role);
            console.log(`setting new roles = `, newRoles);
            setRoles(newRoles);
        } else {
            // remove from user roles
            let newRoles = roles.filter(x => `${x.id}` !== `${role.id}`);
            console.log(`setting new roles = `, newRoles);
            setRoles(newRoles);
        }
    };

    async function handleSubmit(e) {
        e.preventDefault()
        setSpinning(true)
        let newUser = {
            isActive: true,
            isLocked: false,
            requirePasswordReset: false
        }

        if (newPassword) {
            if (newPassword !== confirmPassword) {
                setErrors("Passwords do not mach")
                setSpinning(false)
                return null
            }

            var date = new Date();
            // date.setFullYear(date.getFullYear() - 1);
            newUser["password"] = newPassword
            newUser["passwordDate"] = date
        }

        if (!firstName || firstName === '') {
            setErrors("Must enter a First Name")
            setSpinning(false)
            return null
        }
        else newUser["firstName"] = firstName

        if (!lastName || lastName === '') {
            setErrors("Must enter a Last Name")
            setSpinning(false)
            return null
        }
        else newUser["lastName"] = lastName

        if (!email || email === '') {
            setErrors("Must enter an email")
            setSpinning(false)
            return null
        }
        else newUser["email"] = email

        if (kareoId && kareoId !== '') newUser["kareoId"] = kareoId

        // if (!role) {
        //     setErrors("Must select a role")
        //     setSpinning(false)
        //     return null
        // }
        // else
        //     newUser["roles"] = [{ id: role }]

        if (!roles.length) {
            setErrors("Must select a role");
            setSpinning(false);
            return null;
        } else {
            let roleIds = roles.map(x => { return { id: x.id } });
            console.log(`setting role ids = `, roleIds);
            newUser["roles"] = roleIds;
        }

        if (props.selectedUser === "New User") {
            if (!newPassword || newPassword === '') {
                setErrors("Must enter a valid password")
                setSpinning(false)
                return null
            }
            await createUser({ variables: { user: newUser } })
        }
        else {
            newUser["id"] = props.selectedUser.id
            await updateUser({ variables: { user: newUser } })
        }
        cancel(e)
    }

    const EditButtons = () => {
        if (spinning)
            return (
                <svg id="spinner" className="mt-2 animate-spin -ml-1 mr-3 h-5 w-5 text-blue" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            )
        else if (props.selectedUser)
            return (
                <div className="mt-2">
                    <button onClick={(event) => handleSubmit(event)} id="submit" className="inline-flex items-center bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                        Submit
                    </button>
                    {' '}
                    <button onClick={(event) => { cancel(event) }} id="cancel" className="inline-flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" >
                        Cancel
                    </button>
                </div>
            )
        else
            return (
                <button onClick={(event) => handleSubmit(event)} id="submit" className="mt-2 inline-flex items-center bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Submit
                </button>
            )
    }

    return (
        <>
            <SEO title="Add/Edit User" />
            <h1>Add/Edit User</h1>
            <div className="mt-1">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
                    First Name:
                </label>
                <input aria-label="firstName" onChange={(event) => setFirstName(event.target.value)} className={inputStyle} id="firstName" type="text" placeholder="First Name" value={firstName} />
            </div>
            <div className="mt-1">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
                    Last Name:
                </label>
                <input aria-label="lastName" onChange={(event) => setLastName(event.target.value)} className={inputStyle} id="lastName" type="text" placeholder="Last Name" value={lastName} />
            </div>
            <div className="mt-1">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                    Email:
                </label>
                <input aria-label="email" onChange={(event) => setEmail(event.target.value)} className={inputStyle} id="email" type="text" placeholder="Email" value={email} />
            </div>
            <div className="mt-1">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="kareoId">
                    Kareo ID:
                </label>
                <input aria-label="kareoId" onChange={(event) => setKareoId(event.target.value)} className={inputStyle} id="email" type="text" placeholder="Kareo ID" value={kareoId} />
            </div>
            <div class="mt-1">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
                    Roles:
                </label>
                <FormControl required sx={{ m: 3 }} component="fieldset" variant="standard">
                    {/* <FormLabel component="legend">Assign role</FormLabel> */}
                    <FormGroup>
                        {roleOptions.map(roleOption =>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={PermissionsProvider.hasRole({ roles: roles }, roleOption.name)} onChange={evt => handleRoleChange(roleOption, evt)} name={roleOption.name} />
                                }
                                label={roleOption.name}
                            />
                        )}
                    </FormGroup>
                </FormControl>

                {/* <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
                    Role:
                </label>
                <div>
                    <label class="inline-flex items-center">
                        <input type="radio" checked={role === "1" ? true : false} onChange={(event) => setRole(event.target.value)} class="form-radio" name="role" value="1" />
                        <span class="ml-2">Admin</span>
                    </label>
                    <label class="inline-flex items-center ml-6">
                        <input type="radio" checked={role === "2" ? true : false} onChange={(event) => setRole(event.target.value)} class="form-radio" name="role" value="2" />
                        <span class="ml-2">Clinic</span>
                    </label>
                    <label class="inline-flex items-center ml-6">
                        <input type="radio" checked={role === "3" ? true : false} onChange={(event) => setRole(event.target.value)} class="form-radio" name="role" value="3" />
                        <span class="ml-2">Procedure</span>
                    </label>
                    <label class="inline-flex items-center ml-6">
                        <input type="radio" checked={role === "4" ? true : false} onChange={(event) => setRole(event.target.value)} class="form-radio" name="role" value="4" />
                        <span class="ml-2">Training</span>
                    </label>
                </div> */}
            </div>

            <div className="mt-1">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newPassword">
                    Temporary Password
                </label>
                <input aria-label="newPassword" className={inputStyle + (true ? null : "border-red-500")} type="password" name="newPassword" onChange={(event) => setNewPassword(event.target.value)} placeholder="******************" />
            </div>
            <div className="mt-1" style={{ display: (newPassword ? "block" : "none") }}>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
                    Confirm Password
                </label>
                <input aria-label="confirmPassword" className={inputStyle + (true ? null : "border-red-500")} type="password" name="confirmPassword" onChange={(event) => setConfirmPassword(event.target.value)} placeholder="******************" />
            </div>

            {errors ? <p className="mt-2 text-red-500 text-xs italic">{errors}</p> : null}

            <EditButtons selectedUser={props.selectedUser} />

            <div className="mt-1">
                <Button onClick={(event) => { resetUserPassword(event) }} variant="contained" color="secondary">
                    Reset Password
                </Button>
            </div>
        </>
    )
}

export default Profile