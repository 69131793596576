import React, { useState } from "react"
import { useQuery } from 'react-apollo'
import { gql } from "apollo-boost";
import { GraphQLClient } from 'graphql-request';
import { api } from '../../env/connection';

import { monthFormatter } from './report-utils';
import { ChargeClient } from '../../providers/charge-client';
import { GET_KAREO_LOCATIONS } from '../queries/queries';
import { getDateKey } from "../../utils/dateUtils";

const chargeClient = new ChargeClient();

export default function ReportDataProvider() {

    const client = new GraphQLClient(`${api}/`, {
        headers: {
            authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJja2I5dXd2M2ZzNXBhMDk2ODRqYjFvOG1lIiwiaWF0IjoxNTkyMzQxODY3fQ.r13nZ63HjDZgYkt8m9w1hJYWxSrUBLlGopMW0S8AF2Y',
        },
    });

    let lastMonth = new Date()
    lastMonth.setMonth(lastMonth.getMonth() - 1)
    let thisMonth = new Date()

    // kareo
    const key = "KAREO_KEY";
    const password = "KAREO_PASSWORD";
    const username = "KAREO_USERNAME";
    // // const appointments = useQuery(GET_APPOINTMENTS, { variables: { xml: getXml(date), url: url, headers: JSON.stringify(apptsHeaders) } })

    // const key = process.env.KAREO_KEY
    // const password = process.env.KAREO_PASSWORD
    // const username = process.env.KAREO_USERNAME

    const GET_APPOINTMENTS = gql`
      query appointments ($xml: String!, $url: String!, $headers: String!) {
        appointments (xml: $xml, url: $url, headers: $headers) {
          appointments
        }
      }
    `;

    const longMonth = new Intl.DateTimeFormat('en-us', {
        month: 'short'
    }).format(thisMonth);

    const longLastMonth = new Intl.DateTimeFormat('en-us', {
        month: 'short'
    }).format(lastMonth);

    thisMonth = monthFormatter.format(thisMonth)
    lastMonth = monthFormatter.format(lastMonth)

    const GET_CHARGE_TICKETS = gql`
    query chargeTickets ($filter: String!, $skip: Int!, $take: Int!) {
        chargeTickets (filter: $filter, orderBy: {date: asc}, skip: $skip, take: $take) {
            count
            chargeTickets {
                id
                kareoApptId
                user {
                    id
                    firstName
                    lastName
                    kareoId
                    roles {
                        id
                    }
                }
                date
                createdDate
                modifiedDate
                location
                serviceLocationName
                patientName
                mrn
                insurance
                procedures
                chargeTicketCpts {
                    cpt {
                        id
                        code
                        primaryUnits
                        secondaryUnits
                        class
                    }
                    sequence
                }
            }
        }
    }`;

    async function getHpmChargeData(startDate, endDate, location) {
        let filter = {
            AND:
                [
                    {
                        date: {
                            lt: `${endDate.toISOString()}`
                        }
                    },
                    {
                        date: {
                            gte: `${startDate.toISOString().split('T')[0]}T00:00:00.001Z`
                        }
                    }
                ]
        };

        if (location) {
            filter.location = location;
        }

        console.log(`Start = ${startDate.toISOString()}, End = ${endDate.toISOString()}`);

        const data = await client.request(GET_CHARGE_TICKETS, {
            filter: JSON.stringify(filter),
            skip: 0,
            take: 10000
        });

        return data.chargeTickets.chargeTickets;
    }

    async function getKareoAppointmentData(startDate, endDate, location, kareoId) {
        const chargeSheets = await chargeClient.getAppointments({
            startDate: `${startDate.toISOString().split('T')[0]}T00:00:00.001Z`,
            endDate: `${endDate.toISOString().split('T')[0]}T23:59:59.999Z`,
        });

        // Add time stamp with adjusted time zone
        const appointments = chargeSheets.map(x => {
            const fixedDate =  x.date.toISOString().replace('Z', '-08:00');
            return {
                ...x,
                // dateKey: getDateKey(new Date(Date.parse(fixedDate))),
                dateKey: getDateKey(new Date(`${x.date}`)),
                fixedDate: new Date(Date.parse(fixedDate))
            };
        });

        console.log(`getKareoAppointmentData: Report-Data-Provider Appointments: `, appointments)
        return appointments;

        // OLD KAREO RETRIEVAL AND PARSING PROCESS
        // let chargeSheets = [];
        // // let xml = !!location ? getAppointmentsByLocationXml(location, startDate, endDate) : getAppointmentsXml(startDate, endDate);
        // let xml = getAppointmentsXml(startDate, endDate, location, kareoId);

        // const url = 'https://webservice.kareo.com/services/soap/2.1/KareoServices.svc';
        // const apptsHeaders = {
        //     'Content-Type': 'text/xml;charset=UTF-8',
        //     SOAPAction: 'http://www.kareo.com/api/schemas/KareoServices/GetAppointments',
        // };

        // const data = await client.request(GET_APPOINTMENTS, {
        //     xml: xml,
        //     url: url,
        //     headers: JSON.stringify(apptsHeaders)
        // }
        // );

        // // console.log('Kareo SOAP data = ', data);

        // let parser = new DOMParser();
        // let appts = parser.parseFromString(data.appointments.appointments, "text/xml");
        // let pts = appts.getElementsByTagName("AppointmentData");

        // for (let i = 0; i < pts.length; i++) {
        //     let patient = pts[i];
        //     // console.log(patient.childNodes);

        //     let startDate = patient.getElementsByTagName("StartDate").item(0).firstChild ? patient.getElementsByTagName("StartDate").item(0).firstChild.nodeValue : null;
        //     let location = patient.getElementsByTagName("PracticeName").item(0).firstChild ? patient.getElementsByTagName("PracticeName").item(0).firstChild.nodeValue : null;
        //     let serviceLocationName = patient.getElementsByTagName("ServiceLocationName").item(0).firstChild ? patient.getElementsByTagName("ServiceLocationName").item(0).firstChild.nodeValue : null;
        //     let resourceName1 = patient.getElementsByTagName("ResourceName1").item(0).firstChild ? patient.getElementsByTagName("ResourceName1").item(0).firstChild.nodeValue: null;
        //     let mrn = patient.getElementsByTagName("PatientID").item(0).firstChild ? patient.getElementsByTagName("PatientID").item(0).firstChild.nodeValue : null;
        //     let name = patient.getElementsByTagName("PatientFullName").item(0).firstChild ? patient.getElementsByTagName("PatientFullName").item(0).firstChild.nodeValue : null;
        //     let appointmentStatus = patient.getElementsByTagName("ConfirmationStatus").item(0).firstChild ? patient.getElementsByTagName("ConfirmationStatus").item(0).firstChild.nodeValue : null;
        //     let appointmentReason1 = patient.getElementsByTagName("AppointmentReason1").item(0).firstChild ? patient.getElementsByTagName("AppointmentReason1").item(0).firstChild.nodeValue : null;
        //     let createdDate = patient.getElementsByTagName("CreatedDate").item(0).firstChild ? patient.getElementsByTagName("CreatedDate").item(0).firstChild.nodeValue : null;
        //     let lastModifiedDate = patient.getElementsByTagName("LastModifiedDate").item(0).firstChild ? patient.getElementsByTagName("LastModifiedDate").item(0).firstChild.nodeValue : null;
        //     let patientCaseID = patient.getElementsByTagName("PatientCaseID").item(0).firstChild ? patient.getElementsByTagName("PatientCaseID").item(0).firstChild.nodeValue : null;
        //     let practiceId = patient.getElementsByTagName("PracticeID").item(0).firstChild ? patient.getElementsByTagName("PracticeID").item(0).firstChild.nodeValue: null;
        //     let practiceName = patient.getElementsByTagName("PracticeName").item(0).firstChild ? patient.getElementsByTagName("PracticeName").item(0).firstChild.nodeValue : null;
        //     let appointmentId = patient.getElementsByTagName("ID").item(0).firstChild ? patient.getElementsByTagName("ID").item(0).firstChild.nodeValue : null;

        //     // console.log(`location = ${location} | serviceLocationName = ${serviceLocationName}`);
        //     const date = startDate === null ? null : new Date(startDate)
        //     let chargeSheet =
        //     {
        //         "id": appointmentId,
        //         "date": date, // `${new Date(startDate).toLocaleDateString('en-US')}`,
        //         "location": location,
        //         "serviceLocationName": serviceLocationName,
        //         "resourceName": resourceName1,
        //         "mrn": mrn,
        //         "patientName": name,
        //         "appointmentStatus": appointmentStatus,
        //         "appointmentReason": appointmentReason1,
        //         "patientCaseID": patientCaseID,
        //         "practiceId": practiceId,
        //         "practiceName": practiceName,
        //         "createdDate": createdDate,
        //         "lastModifiedDate": lastModifiedDate,
        //     };
        //     chargeSheets.push(chargeSheet);
        // }

        // if (kareoId) {
        //     chargeSheets = chargeSheets.filter(x => x.resourceName === kareoId);
        // }

        // return chargeSheets;
    }

//     function getAppointmentsXml(startDate, endDate, location, kareoId) {
//         let start = `
//   <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:sch="http://www.kareo.com/api/schemas/">
//   <soapenv:Header/>
//   <soapenv:Body>
//       <sch:GetAppointments>
//         <sch:request>
//             <sch:RequestHeader>
//                 <sch:CustomerKey>${key}</sch:CustomerKey>
//                 <sch:Password>${password}</sch:Password>
//                 <sch:User>${username}</sch:User>
//             </sch:RequestHeader>
//             <sch:Fields />`;

//             let filtersStart = `
//             <sch:Filter>`;

//             let usedFilters = ``;

//             if (kareoId) {
//                 // NOTE: the Kareo API only uses this if it is before EndDate and StartDate :(
//                 usedFilters += `
//                 <sch:ResourceName>${kareoId}</sch:ResourceName>`;
//             }
//             if (location) {
//                 usedFilters += `
//                 <sch:PracticeName>${location}</sch:PracticeName>`;
//             }

//             let filtersEnd = `
//                 <sch:EndDate>${endDate.toISOString()}</sch:EndDate>
//                 <sch:StartDate>${startDate.toISOString()}</sch:StartDate>
//             </sch:Filter>`;

//             let filters = `
//                 ${filtersStart}
//                 ${usedFilters}
//                 ${filtersEnd}
//             `;

//         let end = `</sch:request>
//       </sch:GetAppointments>
//   </soapenv:Body>
//   </soapenv:Envelope>`;

//         var final = `
//             ${start}
//             ${filters}
//             ${end}
//         `;

//         console.log('FINAL REQ =', final);

//         return final;
//     }


    async function getKareoPatientData() {
        let patients = [];
        let xml = getPatientsXml();

        const url = 'https://webservice.kareo.com/services/soap/2.1/KareoServices.svc';
        const patientsHeaders = {
            'Content-Type': 'text/xml;charset=UTF-8',
            SOAPAction: 'http://www.kareo.com/api/schemas/KareoServices/GetPatients',
        };

        const data = await client.request(GET_APPOINTMENTS, {
            xml: xml,
            url: url,
            headers: JSON.stringify(patientsHeaders)
        });

        console.log('Kareo SOAP Patient data = ', data);

        let parser = new DOMParser();
        let appts = parser.parseFromString(data.appointments.appointments, "text/xml");
        let pts = appts.getElementsByTagName("PatientData");

        for (let i = 0; i < pts.length; i++) {
            let patient = pts[i];

            if (!patient) { continue; }
            if (!patient.getElementsByTagName("ID")) { continue; }

            let mrn;
            try {
                mrn = patient.getElementsByTagName("ID").item(0).firstChild.nodeValue;
            } catch {
                continue;
            }

            let mobilePhone;
            try {
                mobilePhone = patient.getElementsByTagName("MobilePhone").item(0).firstChild.nodeValue;
            } catch {
                continue;
            }

            // console.log(`location = ${location} | serviceLocationName = ${serviceLocationName}`);
            let patientItem =
            {
                "mrn": mrn,
                "phoneNumber": mobilePhone,
            };
            // console.log(`patientItem = `, patientItem);
            patients.push(patientItem);
        }

        return patients;
    }

    function getPatientsXml() {
        return `
  <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:sch="http://www.kareo.com/api/schemas/">
  <soapenv:Header/>
  <soapenv:Body>
      <sch:GetPatients>
        <sch:request>
            <sch:RequestHeader>
                <sch:CustomerKey>${key}</sch:CustomerKey>
                <sch:Password>${password}</sch:Password>
                <sch:User>${username}</sch:User>
            </sch:RequestHeader>
            <sch:Fields>
                <sch:ID>true</sch:ID>
                <sch:MobilePhone>true</sch:MobilePhone>
            </sch:Fields>
            <sch:Filter/>
        </sch:request>
      </sch:GetPatients>
  </soapenv:Body>
  </soapenv:Envelope>`
    }


    const useGetKareoLocationsData = (filter) => {
        const [locations, setLocations] = useState([]);
        const [totalLocations, setTotalLocations] = useState([]);
        const { loading, error, data } = useQuery(GET_KAREO_LOCATIONS, {
            variables: { filter: filter },
            onCompleted: (response) => {
                if (response && response.kareoLocations) {
                    const locationsToFilterOut = ['2024proj-KS/MO3x', '2024proj-MT/ID2x', '2024proj-WY1x'];

                    const onlyLocationNames = response.kareoLocations.map(location => location.practiceName).filter(locationName => !locationsToFilterOut.includes(locationName));
                    onlyLocationNames.sort();
                    setLocations(onlyLocationNames);

                    const filteredTotalLocations = response.kareoLocations.filter(location => !locationsToFilterOut.includes(location.practiceName))
                        .sort((a, b) => {
                            const practiceNameA = a.practiceName.toLowerCase();
                            const practiceNameB = b.practiceName.toLowerCase();

                            if (practiceNameA < practiceNameB) {
                                return -1;
                            }
                            if (practiceNameA > practiceNameB) {
                                return 1;
                            }
                            return 0;
                        });
                    setTotalLocations(filteredTotalLocations);
                }
            }
         });

        return { loading, error, data: locations, locationData: totalLocations};

        // OLD KAREO RETRIEVAL AND PARSING PROCESS
        // let locations = {};
        // let xml = getProviderLocationsXml();

        // const url = 'https://webservice.kareo.com/services/soap/2.1/KareoServices.svc';
        // const apptsHeaders = {
        //     'Content-Type': 'text/xml;charset=UTF-8',
        //     SOAPAction: 'http://www.kareo.com/api/schemas/KareoServices/GetProviders',
        // };

        // const data = await client.request(GET_APPOINTMENTS, {
        //     xml: xml,
        //     url: url,
        //     headers: JSON.stringify(apptsHeaders)
        // }
        // );

        // console.log('Kareo SOAP data = ', data);

        // let parser = new DOMParser();
        // let appts = parser.parseFromString(data.appointments.appointments, "text/xml");
        // let providers = appts.getElementsByTagName("ProviderData");

        // for (let i = 0; i < providers.length; i++) {
        //     let provider = providers[i];
        //     let location = provider.getElementsByTagName("PracticeName").item(0).firstChild.nodeValue;
        //     locations[location] = true;
        // }

        // return Object.keys(locations);
    }

//     function getProviderLocationsXml() {
//         return `
//   <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:sch="http://www.kareo.com/api/schemas/">
//   <soapenv:Header/>
//   <soapenv:Body>
//       <sch:GetProviders>
//         <sch:request>
//             <sch:RequestHeader>
//                 <sch:CustomerKey>${key}</sch:CustomerKey>
//                 <sch:Password>${password}</sch:Password>
//                 <sch:User>${username}</sch:User>
//             </sch:RequestHeader>
//             <sch:Fields>
//                 <sch:PracticeName>true</sch:PracticeName>
//             </sch:Fields>
//         </sch:request>
//       </sch:GetProviders>
//   </soapenv:Body>
//   </soapenv:Envelope>`
//     }

    return {
        getHpmChargeData: getHpmChargeData,
        getKareoAppointmentData: getKareoAppointmentData,
        getKareoLocationsData: useGetKareoLocationsData,
        getKareoPatientData: getKareoPatientData
    };
}



