import React, { useState, useEffect } from 'react'
import SEO from "./seo"
import { gql } from "apollo-boost"
import { useQuery, useMutation } from 'react-apollo'
import Ticket from './ticket'
import { getUser } from "../services/auth"
import MaterialTable from "material-table"
import TablePagination from '@material-ui/core/TablePagination'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import { IconButton, Accordion, AccordionSummary, AccordionDetails, Button, FormControl, FormControlLabel, InputLabel, Menu, MenuItem, RadioGroup, Radio, Select, TextField, Typography, Popper, MenuList, Paper } from '@material-ui/core/'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en';
import dayjs from 'dayjs';
import Icon from '@material-ui/core/Icon'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChargeSheet from './charge-sheet'
import ClearIcon from '@material-ui/icons/Clear';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Snackbar from '@material-ui/core/Snackbar';
import LockIcon from '@material-ui/icons/Lock';

import { makeStyles } from "@material-ui/core/styles";

import { GET_KAREO_LOCATIONS, GET_LOCATION_CPTS } from './queries/queries';
import { ChargeClient } from '../providers/charge-client'
import PermissionsProvider, { HPM_PERMISSIONS } from '../providers/permissions-provider'

const useStyles = makeStyles((theme) => ({
    activeFilters: {
        color: theme.palette.secondary.main
    },
}));

const GET_CHARGE_TICKETS = gql`
    query chargeTickets ($filter: String!, $skip: Int!, $take: Int!) {
        chargeTickets (orderBy: {id: desc}, filter: $filter, skip: $skip, take: $take) {
            count
            chargeTickets {
                id
                kareoApptId
                user {
                    id
                    firstName
                    lastName
                    roles {
                        id
                    }
                }
                date
                location
                createdDate
                modifiedDate
                serviceLocationName
                patientName
                mrn
                insurance
                procedures
                trainer
                studentType
                imagingEquipment
                isLocked
                approvedById
                referredTo
                referredBy
                procedureAbortedDetails
                mbbCount
                estimatedTime
                startTime
                chargeTicketCpts {
                    cpt {
                        id
                        description
                        code
                        primaryUnits
                        secondaryUnits
                        class
                        type
                    }
                    sequence
                    cptType
                }
                ticketStatus {
                    downloadDate
                    user {
                        id
                    }
                }
                chargeTicketIcds {
                    icdCode {
                        id
                        code
                        description
                    }
                    sequence
                }
            }
        }
    }
`
const CREATE_TICKET_STATUS = gql`
	mutation createTicketStatus($ticketStatus: TicketStatusInput!) {
		createTicketStatus(ticketStatus: $ticketStatus) {
            id
		}
	}
`

const GET_USERS = gql`
    query users {
        users {
            users {
                id
                firstName
                lastName
                email
                kareoId
                roles {
                    id
                }
            }
        }
    }
`

const dateFormatter = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC'
});

const chargeClient = new ChargeClient();

const TicketView = () => {
    const classes = useStyles();
    const user = getUser();
    let columns = [
        { title: "Date", field: "date", render: (row) => dateFormatter.format(new Date(row.date)) },
        { title: "Location", field: "location" },
        { title: "Patient Name", field: "patientName" },
        { title: "Downloaded", field: "downloadDate", render: (row) => row.downloadDate ? dateFormatter.format(new Date(row.downloadDate)) : '' },
        { title: "Created On", field: "date", render: (row) => dateFormatter.format(new Date(row.createdDate)) },
    ]
    const [pageNumber, setPageNumber] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [selectedTicket, setSelectedTicket] = useState(null)
    // const filter = getUser().role === "1" ? "" : "\"userId\": " + getUser().id;
    const filter = PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.REPORTS_CHARGE_TICKETS_PROVIDERS) ? '' : "\"userId\": " + getUser().id;
    const [createTicketStatus] = useMutation(CREATE_TICKET_STATUS)
    const chargeTickets = useQuery(GET_CHARGE_TICKETS, { variables: { filter: "{ " + filter + " }", skip: 0, take: 100 } })
    const changePage = (_, page) => {
        setPageNumber(page)
        chargeTickets.refetch({ skip: ((page) * rowsPerPage) })
    }
    const kareoLocationsQuery = useQuery(GET_KAREO_LOCATIONS, { variables: { filter: "" } });
    const [kareoLocations, setKareoLocations] = useState([]);
    // const locationCptsQuery = useQuery(GET_LOCATION_CPTS, { variables: { filter: "" } });
    const [name, setName] = useState("")
    const [HPM_ID, setHPM_ID] = useState("")
    const [location, setLocation] = useState("")
    const [provider, setProvider] = useState("")
    const [visitType, setVisitType] = useState("")
    const [date, setDate] = useState("")
    const users = useQuery(GET_USERS);
    const [userOptions, setuserOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    function showToast(msg) {
        setSnackbarMessage(msg);
        setShowSnackbar(true);
    }

    async function getUsers() {
        try {
            let allUsers = await users.refetch();
            let userList = allUsers.data.users.users
                .map(user => {
                    return { value: `${user.firstName} ${user.lastName}`, label: `${user.firstName} ${user.lastName}`, id: user.id };
                });
            userList = userList.filter(x => x.value !== null);
            setuserOptions(userList);
        } catch (error) {
            console.log(error);
        }
    }

    async function getLocations() {
        try {
            let response = await kareoLocationsQuery.refetch();
            setKareoLocations(response.data.kareoLocations);
            let locationList = response.data.kareoLocations
                .filter(x => x.kareoId && `${x.id}` !== `0`)
                .map(location => {
                    return { value: `${location.id}`, label: `${location.practiceName.trim()}` };
                });
            locationList = locationList.filter(x => x.value !== null);
            const sortedList = locationList.sort((a, b) => {
                var textA = a.label.toUpperCase();
                var textB = b.label.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            })
            setLocationOptions(sortedList);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getUsers();
        getLocations();
    }, [])

    const handleSearch = (e) => {
        e.preventDefault();
        handleFilterClose();

        const inputData = {}

        if (name !== "") {
            inputData["patientName"] = {
                contains: name,
                mode: "insensitive"
            }
        }

        if (HPM_ID !== "") {
            inputData["id"] = {
                equals: parseInt(HPM_ID)
            }
        }

        if (location !== "") {
            inputData["locationId"] = {
                equals: parseInt(location)
            }
        }

        if (provider !== "") {
            inputData["user"] = {
                id: {
                    equals: parseInt(provider)
                }
            }
        }

        if (visitType === "Clinic") {
            inputData["chargeticketcpt"] = {
                every: {
                    cpt: {
                        code: {
                            startsWith: "99"
                        }
                    }
                }
            }
        } else if (visitType === "Procedure") {
            inputData["chargeticketcpt"] = {
                every: {
                    cpt: {
                        code: {
                            not: {
                                startsWith: "99"
                            }
                        }
                    }
                }
            }
        }

        if (date !== "") {
            const formattedDate = new Date(date).toISOString()
            const startOfNextDay = dayjs(formattedDate).startOf('day').add(1, 'day').toISOString()

            inputData["date"] = {
                gte: formattedDate,
                lt: startOfNextDay
            }
        }

        const search = {
            filter: JSON.stringify(inputData),
            skip: 0
        }

        setPageNumber(0)

        chargeTickets.refetch(search)

        // setName("")
        // setHPM_ID("")
        // setLocation("")
        // setProvider("")
        // setVisitType("")
        // setDate("")
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value)
        setPageNumber(0)
        chargeTickets.refetch()
    }

    const [filterAnchor, setFilterAnchor] = React.useState(null);
    const handleFilterClick = (event) => {
        if (filterAnchor) {
            setFilterAnchor(null);
        } else {
            setFilterAnchor(event.currentTarget);
        }
    };
    const handleFilterClose = () => {
        setFilterAnchor(null);
    };
    const clearFilters = () => {
        setName("");
        setHPM_ID("");
        setLocation("");
        setProvider("");
        setVisitType("");
        setDate("");
    };

    const [showTicketLock, setShowTicketLock] = React.useState(false);
    const [ticketLockAnchor, setTicketLockAnchor] = React.useState(null);
    const [ticketLockStartDate, setTicketLockStartDate] = React.useState(null);
    const [ticketLockEndDate, setTicketLockEndDate] = React.useState(null);
    const handleTicketLockClick = (event) => {
        setTicketLockAnchor(event.currentTarget);
        setShowTicketLock(true);
    };
    const handleTicketLockClose = () => {
        setTicketLockAnchor(null);
        setShowTicketLock(false);
        setTicketLockStartDate(null);
        setTicketLockEndDate(null);
    };

    const submitTicketLock = async (lockType) => {
        let count = 0;
        try {
            if (lockType === 'lock') {
                count = await chargeClient.lockTickets({
                    startDate: ticketLockStartDate,
                    endDate: ticketLockEndDate
                });
                showToast(`Successfully locked ${count} tickets`);
            } else {
                count = await chargeClient.unlockTickets({
                    startDate: ticketLockStartDate,
                    endDate: ticketLockEndDate
                });
                showToast(`Successfully unlocked ${count} tickets`);
            }
        } catch (err) {
            console.error(`Error with ticket locking: `, err);
            showToast(`Error with ticket ${lockType}`);
        }
    }

    const doNothing = () => { };



    const hasActiveFilters = () => {
        return HPM_ID || location || provider || visitType || date;
    };

    const components = {
        Pagination: props => (
            <TablePagination
                {...props}
                count={chargeTickets.data.chargeTickets.count}
                page={pageNumber}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[25, 50, 100]}
                onChangeRowsPerPage={(e) => {
                    handleChangeRowsPerPage(e)
                }}
                onChangePage={(e, page) => {
                    changePage(e, page)
                }}
                handleFirstPageButtonClick={(event) => {
                    changePage(event, 0)
                }}
                handleBackButtonClick={(event) => {
                    changePage(event, pageNumber - 1)
                }}
                handleNextButtonClick={(event) => {
                    changePage(event, pageNumber + 1)
                }}
                handleLastPageButtonClick={(event) => {
                    changePage(event, Math.max(0, Math.ceil(chargeTickets.data.chargeTickets.count / 10) - 1))
                }}
            />
        ),
        Action: props => {
            if (props.action.icon === 'edit') {
                return (
                    <IconButton aria-label={props.action.icon} size="small"
                        onClick={() => { setSelectedTicket(props.data) }}
                    >
                        <Icon>{props.action.icon}</Icon>
                    </IconButton>
                )
            }
            else if (props.action.icon === 'download') {
                return (
                    <IconButton aria-label={props.action.icon} size="small"
                        onClick={async () => {
                            let date = new Date()
                            await createTicketStatus({
                                variables: {
                                    ticketStatus: {
                                        chargeTicketId: props.data.id,
                                        userId: getUser().id,
                                        downloadDate: date
                                    }
                                }
                            })
                            const doc = <Ticket ticket={props.data} createTicketStatus={createTicketStatus} />
                            const asPdf = pdf([]) // {} is important, throws without an argument
                            asPdf.updateContainer(doc)
                            const blob = await asPdf.toBlob()
                            saveAs(blob, (JSON.stringify(props.data.chargeTicketCpts).includes("\"code\":\"99") ? "Clinic Visit_" : "") + props.data.location + "_" + props.data.patientName + "_" + dateFormatter.format(new Date(props.data.date)).replace(/\//g, '-') + '.pdf')
                        }}
                    >
                        <Icon>{props.action.icon}</Icon>
                    </IconButton>
                )
            }
        }
    }
    if (selectedTicket) {
        return (<ChargeSheet refetch={chargeTickets.refetch} setSelectedTicket={setSelectedTicket} selectedTicket={selectedTicket} />)
    }
    else if (chargeTickets.data && !chargeTickets.loading) {
        return (
            <div className="mb-20">
                <SEO title="View Charge Tickets" />
                <h1>View Charge Tickets</h1>

                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "auto" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "auto" }}>
                        <div style={{ marginRight: '16px' }}>
                            <TextField
                                label="Patient Name"
                                style={{ marginBottom: "8px" }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div style={{ marginRight: '16px' }}>
                            <Button onClick={(e) => handleSearch(e)} variant="contained" color="primary">Search</Button>
                        </div>
                        <div>
                            <Button onClick={handleFilterClick}>
                                <div className={hasActiveFilters() ? classes.activeFilters : ''}>
                                    Filters
                                </div>
                            </Button>

                            <Popper open={Boolean(filterAnchor)} anchorEl={filterAnchor} disablePortal={false} placement='bottom-start' style={{ background: 'white', zIndex: 999 }}>
                                <Paper elevation={3}>
                                    {/* <MenuList autoFocusItem={Boolean(filterAnchor)} id="menu-list-grow"> */}
                                        <MenuItem onClick={handleFilterClose}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
                                                <IconButton>
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            </div>
                                        </MenuItem>

                                        <MenuItem disabled>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                                <h3>Search Filters</h3>
                                            </div>
                                        </MenuItem>

                                        <MenuItem onClick={doNothing}>
                                            <TextField
                                                label="HPM ID"
                                                type="number"
                                                style={{ width: '100%', margin: "8px 0" }}
                                                value={HPM_ID}
                                                onChange={(e) => setHPM_ID(e.target.value)}
                                            />
                                        </MenuItem>
                                        <MenuItem onClick={doNothing}>
                                            <FormControl style={{ width: '100%', margin: "8px 0" }}>
                                                <InputLabel id="location-label">Location</InputLabel>
                                                <Select
                                                    labelId="location-label"
                                                    label="Location"
                                                    value={location}
                                                    onChange={(e) => setLocation(e.target.value)}
                                                >
                                                    {locationOptions.map((location) => (
                                                        <MenuItem key={location.value} value={location.value}>
                                                            {location.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </MenuItem>
                                        <MenuItem onClick={doNothing}>
                                            <FormControl style={{ width: '100%', margin: "8px 0" }}>
                                                <InputLabel id="provider-label">Provider</InputLabel>
                                                <Select
                                                    labelId="provider-label"
                                                    label="Provider"
                                                    value={provider}
                                                    onChange={(e) => setProvider(e.target.value)}
                                                >
                                                    {userOptions.map((user) => (
                                                        <MenuItem key={user.id} value={user.id}>
                                                            {user.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </MenuItem>
                                        <MenuItem onClick={doNothing}>
                                            <FormControl style={{ width: '100%', margin: "8px 0" }}>
                                                <InputLabel id="type-label">Visit Type</InputLabel>
                                                <Select
                                                    labelId="type-label"
                                                    label="Visit Type"
                                                    value={visitType}
                                                    onChange={(e) => setVisitType(e.target.value)}
                                                >
                                                    <MenuItem key={1} value="All">
                                                        All
                                                    </MenuItem>
                                                    <MenuItem key={2} value="Clinic">
                                                        Clinic
                                                    </MenuItem>
                                                    <MenuItem key={3} value="Procedure">
                                                        Procedure
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </MenuItem>
                                        <MenuItem onClick={doNothing}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                                <DatePicker
                                                    label="Ticket Date"
                                                    onChange={(e) => setDate(e.$d)}
                                                />
                                            </LocalizationProvider>
                                        </MenuItem>

                                        <br />
                                        <MenuItem onClick={clearFilters}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                                <Button variant="outlined" color="secondary" size="small" onClick={clearFilters}>
                                                    Clear Filters
                                                </Button>
                                            </div>
                                        </MenuItem>
                                    {/* </MenuList> */}
                                </Paper>
                            </Popper>
                        </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "auto", marginRight: '16px' }}>

                        { PermissionsProvider.hasRole(user, 'Corporate') &&
                            <>
                                <Button onClick={handleTicketLockClick} variant="outlined" color="primary">
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: '8px' }}>
                                        <LockIcon color="primary" fontSize="small" />
                                    </div>
                                </Button>
                                <Popper open={showTicketLock} anchorEl={ticketLockAnchor} disablePortal={false} placement='bottom-start' style={{ background: 'white', zIndex: 999 }}>
                                    <ClickAwayListener onClickAway={doNothing}>
                                        <Paper elevation={3} style={{ padding: '16px' }}>
                                            {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
                                        <IconButton onClick={handleTicketLockClose}>
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    </div> */}
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", position: 'relative', padding: '8px 16px 32px' }}>
                                                <h4 style={{ margin: 0 }}>Ticket Locking</h4>
                                                <div style={{ position: 'absolute', right: 0, display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
                                                    <IconButton onClick={handleTicketLockClose}>
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "space-around", width: "100%", gap: '8px' }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                                    <DatePicker
                                                        label="Start Date"
                                                        onChange={(e) => setTicketLockStartDate(e.$d)}
                                                    />
                                                </LocalizationProvider>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                                    <DatePicker
                                                        label="End Date"
                                                        onChange={(e) => setTicketLockEndDate(e.$d)}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                            <br />
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: '8px', width: "100%" }}>
                                                <Button variant="outlined" color="secondary" size="small"
                                                    onClick={() => submitTicketLock('lock')} style={{ width: "100%" }}
                                                    disabled={!ticketLockStartDate || !ticketLockEndDate}
                                                >
                                                    Lock
                                                </Button>
                                                <Button variant="outlined" color="primary" size="small"
                                                    onClick={() => submitTicketLock('unlock')} style={{ width: "100%" }}
                                                    disabled={!ticketLockStartDate || !ticketLockEndDate}
                                                >
                                                    Unlock
                                                </Button>
                                            </div>
                                        </Paper>
                                    </ClickAwayListener>
                                </Popper>
                            </>
                        }
                    </div>
                </div>
                {/* </div> */}

                <br />
                <div>
                    <MaterialTable style={{ padding: "10px" }}
                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} of {count}'
                            },
                            toolbar: {
                                nRowsSelected: '{0} row(s) selected'
                            },
                            header: {
                                actions: 'Actions'
                            },
                            body: {
                                emptyDataSourceMessage: 'No records to display',
                                filterRow: {
                                    filterTooltip: 'Filter'
                                }
                            }
                        }}
                        columns={columns}
                        data={() =>
                            new Promise((resolve, reject) => {
                                resolve({
                                    data: chargeTickets.data.chargeTickets.chargeTickets.map(chargeTicket => {
                                        let downloadDate = ''
                                        chargeTicket.ticketStatus.map(status => {
                                            if (status.user.id === getUser().id)
                                                downloadDate = status.downloadDate
                                            return 0
                                        })
                                        return ({ ...chargeTicket, downloadDate: downloadDate })
                                    }),
                                    page: pageNumber,
                                    totalCount: chargeTickets.data.chargeTickets.count
                                })
                            })
                        }
                        components={components}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Edit'
                            },
                            {
                                icon: 'download',
                                tooltip: 'Download'
                            },
                            // rowData => ({
                            //     icon: 'edit',
                            //     tooltip: 'Locked',
                            //     hidden: !rowData.isLocked
                            // }),
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            pageSize: rowsPerPage,
                            search: false
                        }}
                        title=""
                    />
                </div>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={showSnackbar}
                    onClose={() => setShowSnackbar(false)}
                    autoHideDuration={3000}
                    message={snackbarMessage}
                />

            </div >
        )
    }
    return (
        <>
            <SEO title="Tickets" />
            <h1>View Charge Tickets</h1>
            <h2>Loading...</h2>
        </>
    )
}
export default TicketView