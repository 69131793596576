import React, { useEffect, useState } from 'react';
// import Img from "gatsby-image";
// import { useStaticQuery, graphql } from "gatsby";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
// import { gql } from "apollo-boost"
// import { GraphQLClient } from 'graphql-request'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';


// import * as Excel from "exceljs";
// import { read, utils, writeFileXLSX } from 'xlsx';
// import { read, utils, writeFileXLSX } from 'xlsx-style';

import { Renderer } from 'xlsx-renderer';
import { saveAs } from "file-saver";

// import { similarity } from 'string-similarity';

import ReportDataProvider from './report-data-provider';
import * as DataUtils from './report-data-utils';
import { Workbook } from 'exceljs';

import { LocationsUnitsProvider } from '../../utils/unitUtils';
import { getMonths, dateFormatter } from './report-utils';

import { useQuery } from 'react-apollo';
import { GET_USERS } from '../queries/queries';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        marginRight: 0
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
        flexGrow: 1,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    flexContainer: {
        display: 'flex'
    },
    flexItem: {
        width: '100%',
        flexGrow: 1,
        margin: 8,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

async function writeToTemplate(viewModel) {

    console.log(`repwriteToTemplateort => `, viewModel);

    // let usedMonths = Object.keys(viewModel.monthGroups);
    // for (const monthKey of usedMonths) {
    //     viewModel[monthKey] = viewModel.monthGroups[monthKey];
    // }

    // let response = await fetch('https://public-hpm-portal-files.s3.us-east-2.amazonaws.com/TEMPLATE_HPM-Itemized-Bills.xlsx');
    // let response = await fetch('https://public-hpm-portal-files.s3.us-east-2.amazonaws.com/TEMPLATE_HPM_v2-Itemized-Bills.xlsx');

    let response;

    if (viewModel.metadata.location.includes('Powell')) {
        // response = await fetch('/assets/TEMPLATE_HPM_v2-Itemized-Bills_Patient-Count.xlsx');
        response = await fetch('https://public-hpm-portal-files.s3.us-east-2.amazonaws.com/TEMPLATE_HPM_v3-Itemized-Bills_Patient-Count.xlsx');
    } else {
        // response = await fetch('/assets/TEMPLATE_HPM_v2-Itemized-Bills.xlsx');
        response = await fetch('https://public-hpm-portal-files.s3.us-east-2.amazonaws.com/TEMPLATE_HPM_v2-Itemized-Bills.xlsx');
    }

    let buffer = await response.arrayBuffer();
    const renderer = new Renderer();
    const report = await renderer.renderFromArrayBuffer(buffer, viewModel);
    report.removeWorksheet('TEMP');

    let usedMonths = Object.keys(viewModel.monthGroups);

    console.log(`report = `, report);

    // remove unused sheets
    let unusedMonths = DataUtils.monthNames.filter(month => !usedMonths.includes(month));
    for (const monthKey of unusedMonths) {
        // remove unused sheets
        report.removeWorksheet(monthKey);
    }

    for (const monthKey of usedMonths) {
        let monthGroup = viewModel.monthGroups[monthKey];
        // set the year for used sheets
        let sheet = report.getWorksheet(monthKey);
        sheet.name = `${monthGroup.name} ${monthGroup.year}`;
    }

    for (let worksheet of report.worksheets) {
        // delete the Z column where template info is
        worksheet.spliceColumns(26, 1);
    }

    const output = await report.xlsx.writeBuffer();
    // await saveAs(new Blob([output]), `${viewModel.metadata.location}_HPM-Itemized-Bill-Report_${(new Date()).toLocaleDateString('en-US')}.xlsx`);
    await saveAs(new Blob([output]), `${viewModel.metadata.location} - ${viewModel.metadata.month.replace(',', '')} Encounter Report.xlsx`);

}

export default function ItemizedBillReportDialog() {

    const usersQuery = useQuery(GET_USERS);

    const [open, setOpen] = React.useState(false);
    const [isBuilding, setIsBuilding] = React.useState(false);
    const [locations, setLocations] = React.useState([]);

    const provider = ReportDataProvider();
    const { loading, error, data, locationData } = provider.getKareoLocationsData('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();

    const [facilityOption, setFacilityOption] = useState(null);
    const handleFacilitySelection = (event) => {
        const option = event.target.value;
        setFacilityOption(option);
        setUseMonthOnly(option.practiceName === 'All Locations');
    };

    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    useEffect(() => {
        if (locationData && locationData.length !== 0) {
            console.log('Itemized-Bill-Report:: Locations Set');
            const locationsList = [
                {
                    practiceName: 'All Locations'
                }
                , ...locationData];
            setLocations(locationsList)
        }
    }, [locationData]);

    LocationsUnitsProvider.refreshData();
    const locationUnitsProvider = LocationsUnitsProvider;

    const [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers();
    }, []);

    async function getUsers() {
        try {
            let allUsers = await usersQuery.refetch();
            console.log(`all users = `, allUsers);
            let userList = allUsers.data.users.users
                // .filter(user => user.roles[0].id !== '1') // && user.roles[0].id !== '4')
                .sort((a, b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0));
            setUsers(userList);
        } catch (error) {
            console.error(error);
        }
    }

    const [useMonthOnly, setUseMonthOnly] = useState(false);
    const [selectedMonthYear, setSelectedMonthYear] = useState(null);
    function handleSelectMonth(evt) {
        setSelectedMonthYear(evt);

        let date = new Date(evt);
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        firstDay.setHours(0, 0, 0, 0);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        lastDay.setHours(23, 59, 59, 999);

        setSelectedStartDate(firstDay);
        setSelectedEndDate(lastDay);
    }

    async function buildReport() {
        setIsBuilding(true);

        console.log(`Building Report...`);

        let months = getMonths(selectedStartDate, selectedEndDate);
        if (months.length > 12) {
            alert('Cannot select more than 12 months of data. Please adjust your time frame and try again.');
            setIsBuilding(false);
            return;
        }

        let reportData;

        const facilityOptions = facilityOption.practiceName === 'All Locations' ? locations.filter(x => !!x.id) : [facilityOption];

        for (let facility of facilityOptions) {

            try {
                reportData = await buildReportData(facility);
            } catch (e) {
                setIsBuilding(false);
                throw e;
            }

            reportData.metadata = {
                createdDate: `${new Date()}`,
                createdBy: `HPM Charge User`,
                location: `${facility.practiceName} ${facility.facilityState}`,
                month: `${DataUtils.monthNames[selectedStartDate.getMonth()]}, ${selectedStartDate.getFullYear()}`,
                startDate: `${selectedStartDate.toLocaleDateString('en-US')}`,
                endDate: `${selectedEndDate.toLocaleDateString('en-US')}`,
                unitsGrandTotal: reportData.unitsGrandTotal,
                patientsGrandTotal: reportData.patientsGrandTotal,
            };

            let usedMonths = Object.keys(reportData.monthGroups);
            for (const monthKey of usedMonths) {
                reportData[monthKey] = reportData.monthGroups[monthKey];

                if (!reportData.clinicFinalTotal || reportData.metadata.location.includes('Powell')) {
                    let patientCount = 0;
                    reportData[monthKey].days.forEach(day => {
                        let dayPatientCount = 0;
                        day.tickets.forEach(ticket => {
                            ticket.data.clinicUnits = !!ticket.data.procedureUnits ? '' : 1;
                            patientCount += !!ticket.data.clinicUnits ? 1 : 0;
                            dayPatientCount += !!ticket.data.clinicUnits ? 1 : 0;
                        });
                        day.patientTotal = dayPatientCount;
                    });
                    // add to patient count
                    reportData[monthKey].patientsGrandTotal = patientCount;
                }
            }

            console.log(`reportData = `, reportData);

            console.log(`Writing report data to file...`);
            try {
                await writeToTemplate(reportData);
            } catch (e) {
                setIsBuilding(false);
                throw e;
            }
        }


        console.log(`Done!`);

        setIsBuilding(false);
        handleClose();
        setFacilityOption(null);
    }

    async function buildReportData(facility) {

        selectedStartDate.setHours(0, 0, 0, 1);
        selectedEndDate.setHours(23, 59, 59, 0);

        let ticketData;
        let ticketDataMap;

        // get charge ticket data
        ticketData = await provider.getHpmChargeData(selectedStartDate, selectedEndDate, facility.practiceName);
        // console.log('HPM Charge data = ', ticketData);
        ticketDataMap = getHpmChargeMap(ticketData);
        console.log('HPM Charge data map = ', ticketDataMap);

        return {
            ticketGroups: ticketDataMap.ticketGroups,
            monthGroups: ticketDataMap.monthGroups,
            unitsGrandTotal: ticketDataMap.unitsGrandTotal,
            patientsGrandTotal: ticketDataMap.patientsGrandTotal,
            clinicFinalTotal: ticketDataMap.clinicFinalTotal,
            procedureFinalTotal: ticketDataMap.procedureFinalTotal,
        }
    }

    function getHpmChargeMap(data, filter) {

        // map all of Daren Badura's tickets to a single user
        const darenUser = users.find(x => `${x.id}` === `40`);
        if (darenUser) {
            for (let tk of data) {
                if (`${tk.user.id}` === '18') {
                    tk.userId = 40;
                    tk.user = darenUser;
                }
            }
        }

        let clinicFinalTotal = 0;
        let procedureFinalTotal = 0;

        let tickets = [];
        for (let i = 0; i < data.length; i++) {

            // skip training/testing data
            if (
                data[i].location.toLowerCase().includes('training')
                || data[i].location.toLowerCase().includes('test')
                || data[i].patientName.toLowerCase().includes('training')
                || data[i].patientName.toLowerCase().includes('test')
            ) { continue; }


            let resourceNameParts = data[i].user.kareoId.trim().split(' ');
            let resourceName = `${resourceNameParts[0]}${resourceNameParts[resourceNameParts.length - 1]}`;

            let patientNameParts = data[i].patientName.replace(/&QUOT;/g, '').trim().split(' ');
            let patientNameKey = `${patientNameParts[0]}${patientNameParts[patientNameParts.length - 1]}`;

            data[i].date = new Date(data[i].date);
            let key = `${patientNameKey}${dateFormatter.format(new Date(data[i].date)).replace(/\//g, '')}${resourceName}`.replace(/\s/g, '').trim().toLowerCase();

            if (filter && !filter(data[i])) {
                continue;
            }

            data[i].date = `${dateFormatter.format(new Date(data[i].date))}`; // `${data[i].date.toLocaleDateString('en-US')}`;
            data[i].patientName = data[i].patientName.replace(/&QUOT;/g, '');

            // setup CPTs...
            // sort by sequence number
            data[i].chargeTicketCpts.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1);

            // set up first sequence CPT
            data[i].cpt1 = data[i].chargeTicketCpts && data[i].chargeTicketCpts.length > 0 ? data[i].chargeTicketCpts[0].cpt.code : null;

            // add remaining CPTs
            for (let j = 1; j < 6; j++) {
                data[i][`cpt${j + 1}`] = data[i].chargeTicketCpts[j] && !`${data[i].cpt1}`.startsWith('99') // don't include ordered CPTs (i.e. for clinic visits)
                    ? data[i].chargeTicketCpts[j].cpt.code
                    : null;
            }

            // // don't include ordered CPTs (i.e. for clinic visits)
            // if (!`${data[i].cpt1}`.startsWith('99')) {
            //     data[i].cpt2 = data[i].chargeTicketCpts && data[i].chargeTicketCpts.length > 1 ? data[i].chargeTicketCpts[1].cpt.code : null;
            //     data[i].cpt3 = data[i].chargeTicketCpts && data[i].chargeTicketCpts.length > 2 ? data[i].chargeTicketCpts[2].cpt.code : null;
            // }

            tickets.push({
                key: key,
                date: data[i].date,
                data: data[i]
            });
        }

        let groups = DataUtils.groupBy(tickets, 'date');

        // group by date
        let days = [];
        Object.keys(groups).forEach(group => {
            const groupTickets = groups[group];
            if (groupTickets) {
                days.push({
                    date: group,
                    patientTotal: groupTickets.length,
                    clinicTotals: 0, // TODO
                    procedureTotals: 0, // TODO
                    tickets: groupTickets
                });
            }
        });
        console.log(`days = `, days);


        // group dates into months
        let months = getMonths(selectedStartDate, selectedEndDate);
        console.log(`selected months to group by = `, months);

        let monthGroups = {}; // Map<month,datesGroup>
        months.forEach(month => {
            let monthVal = parseInt(month.split('/')[0]);
            let monthName = `${DataUtils.monthNames[monthVal - 1]}`;
            let date = new Date(month);
            let lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            monthGroups[monthName] = {
                name: monthName,
                year: `${(new Date(month)).getFullYear()}`,
                number: monthVal,
                date: month,
                days: [],
                unitsGrandTotal: 0,
                patientsGrandTotal: 0,
                lastDayOfMonth: lastDayOfMonth.getDate(),
                clinicGrandTotal: 0, // TODO
                procedureGrandTotal: 0, // TODO
            };

            days.forEach(dateGroup => {

                let groupMonth = parseInt(dateGroup.date.split('/')[0]);
                if (groupMonth === monthVal) {
                    monthGroups[monthName].days.push(dateGroup);
                }
            });
        });


        Object.keys(monthGroups).forEach(key => {
            let month = monthGroups[key];
            console.log(`Month (${month.name}) = `, month);

            month.days.forEach(day => {
                console.log(`   Day (${day.date}) = `, day);

                day.totalUnits = 0;
                day.clinicTotals = 0;
                day.procedureTotals = 0;

                // console.log(`group tickets = `, group.tickets);
                day.tickets.sort((a, b) => {
                    // sort by procedure type (clinic first)
                    const isClinicA = JSON.stringify(a.data.chargeTicketCpts).includes("\"code\":\"99");
                    const isClinicB = JSON.stringify(b.data.chargeTicketCpts).includes("\"code\":\"99");
                    if (!isClinicA && isClinicB) { return 1; }
                    if (isClinicA && !isClinicB) { return -1; }
                    // then by provider
                    if (a.data.user.kareoId > b.data.user.kareoId) { return 1; }
                    if (a.data.user.kareoId < b.data.user.kareoId) { return -1; }
                    // then by patient name
                    else { return a.data.patientName.localeCompare(b.data.patientName); }
                });

                // ((a.data.user.kareoId > b.data.user.kareoId) ? 1 : -1) || a.data.patientName.localeCompare(b.data.patientName) });
                // console.log(`group tickets (after) = `, group.tickets);

                day.tickets.forEach(ticket => {

                    // add to patient count
                    month.patientsGrandTotal++;

                    // compute the units
                    ticket.data.units = 0;
                    ticket.data.clinicUnits = 0; // TODO
                    ticket.data.procedureUnits = 0; // TODO
                    const isClinic = JSON.stringify(ticket.data.chargeTicketCpts).includes("\"code\":\"99");
                    const providerType = ticket.data.user.roles[0].id === "3" ? "CRNA" : "PA";

                    ticket.data.chargeTicketCpts.forEach(chargeTicketCpt => {
                        let units = locationUnitsProvider.getUnits(ticket.data, chargeTicketCpt, providerType, ticket.data.location, isClinic); // (chargeTicketCpt.cpt.code.startsWith("99") ? getUnits(chargeTicketCpt, providerType, ticket.data.location, isClinic) : 0);
                        // console.log(`UNITS = ${units}`);
                        ticket.data.units += parseInt(`${units}`);
                    });
                    ticket.data.units = LocationsUnitsProvider.applyUnitCap(ticket.data.units, ticket.data.location);
                    ticket.data.units = ticket.data.units !== 0 ? ticket.data.units : null;

                    if (isClinic) {
                        ticket.data.clinicUnits = ticket.data.units ? ticket.data.units : null;
                        ticket.data.procedureUnits = null;
                    } else {
                        ticket.data.procedureUnits = ticket.data.units ? ticket.data.units : null;
                        ticket.data.clinicUnits = null;
                    }

                    if (ticket.data.units) { day.totalUnits += ticket.data.units; }
                    if (ticket.data.clinicUnits) { day.clinicTotals += ticket.data.clinicUnits; }
                    if (ticket.data.procedureUnits) { day.procedureTotals += ticket.data.procedureUnits; }

                });

                month.unitsGrandTotal += day.totalUnits
                month.clinicGrandTotal += day.clinicTotals
                month.procedureGrandTotal += day.procedureTotals


            });

            clinicFinalTotal += month.clinicGrandTotal;
            procedureFinalTotal += month.procedureGrandTotal;
        });

        return {
            unitsGrandTotal: 0, // unitsGrandTotal,
            patientsFinalTotal: 0, // patientsGrandTotal,
            clinicFinalTotal: clinicFinalTotal,
            procedureFinalTotal: procedureFinalTotal,
            ticketGroups: days,
            monthGroups: monthGroups
        };
    }

    if (isBuilding) {
        return (
            <div>
                <Card className={classes.root}>
                    <CardActionArea onClick={handleClickOpen}>
                        {/* <Img fixed={discrepancyReportImage.placeholderImage.childImageSharp.fixed} /> */}
                        <img src={require('../../images/Itemized-Bill-Report.png').default} />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Itemized Bill Report
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Itemized summary of units charged to a facility for a given month.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    {/* <CardActions>
                        <Button size="small" color="primary" onClick={handleClickOpen}>
                            Build Report
                        </Button>
                    </CardActions> */}
                </Card>


                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Building Itemized Bill Report</DialogTitle>
                    <DialogContent>
                        <div className={classes.flexCenter}>
                            <CircularProgress />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

    return (
        <div>
            <Card className={classes.root}>
                <CardActionArea onClick={handleClickOpen}>
                    {/* <Img fixed={discrepancyReportImage.placeholderImage.childImageSharp.fixed} /> */}
                    <img src={require('../../images/Itemized-Bill-Report.png').default} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Itemized Bill Report
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Itemized summary of units charged to a facility for a given month.
                        </Typography>
                    </CardContent>
                </CardActionArea>
                {/* <CardActions>
                    <Button size="small" color="primary" onClick={handleClickOpen}>
                        Build Report
                    </Button>
                </CardActions> */}
            </Card>


            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create Itemized Bill Report</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The itemized bill report shows units billed to facilities based on HPM Charge Ticket data.
                    </DialogContentText>
                    <div className={classes.flexContainer}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="dataSource1">Location</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={facilityOption}
                                onChange={handleFacilitySelection}
                            >
                                {/* <MenuItem value={'Clayton'}>Clayton</MenuItem>
                                <MenuItem value={'Lakewood'}>Lakewood</MenuItem> */}

                                {
                                    locations.map((location, i) => <MenuItem value={location}>{location.practiceName}</MenuItem>)
                                }


                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.flexContainer}>

                        {!useMonthOnly &&
                            <>
                                <div className={classes.flexItem}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="Start Date"
                                            value={selectedStartDate}
                                            onChange={handleStartDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className={classes.flexItem}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.flexItem}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="End Date"
                                            value={selectedEndDate}
                                            onChange={handleEndDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </>
                        }

                        {useMonthOnly &&
                            <div className={classes.flexItem}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.flexItem}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%' }}
                                        views={['year', 'month']}
                                        variant="inline"
                                        margin="normal"
                                        label="Year and Month"
                                        minDate={new Date('2000-01-01')}
                                        maxDate={new Date()}
                                        value={selectedMonthYear}
                                        onChange={handleSelectMonth}
                                        renderInput={(params) => <TextField {...params} helperText={null} />}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        }


                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => buildReport()} color="primary" variant="contained" disabled={!facilityOption}>
                        Build Report
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
