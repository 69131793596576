import { gql } from "apollo-boost";

export const GET_KAREO_LOCATIONS = gql`
    query kareoLocations($filter: String!) {
        kareoLocations(filter: $filter) {
            id,
            kareoId,
            practiceName,
            chargeType,
            chargeTypeDate,
            facilityState,
            tiers {
                id
                tierId
                tierName
                startDate
                endDate
            }                
        }
    }
`;

export const GET_LOCATION_CPTS = gql`
    query locationCpts($filter: String!) {
        locationCpts(filter: $filter) {
            locationId,
            locationDescription,
            cptId,
            cptCode,
            primaryUnits,
            secondaryUnits
        }
    }
`;

export const GET_CPT_TIERS = gql`
    query cptTiers($filter: String!) {
        cptTiers(filter: $filter) {
            id,
            tierId,
            tierName,
            cptId,
            cptCode,
            primaryUnits,
            secondaryUnits
        }
    }
`;

export const GET_USERS = gql`
    query users {
        users {
            users {
                id
                firstName
                lastName
                email
                kareoId
                roles {
                    id
                }
            }
        }
    }
`;

export const GET_REFERRING_PROVIDERS = gql`
    query referringproviders($locationId: ID!) {
        referringproviders(locationId: $locationId) {
            id
            name
        }
    }
`;