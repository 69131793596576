
import React, { useEffect } from 'react';
import SEO from "../seo";

import './schedule.css';

// import TableauReport from 'tableau-react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import * as DataUtils from '../reports/report-data-utils';

import EventIcon from '@material-ui/icons/Event';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import Box from '@material-ui/core/Box';

import { ChargeClient } from "../../providers/charge-client";

const chargeClient = new ChargeClient();

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ width: `100%`, height: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0} sx={{ height: '100%' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const ScheduleDashboard = () => {

    const [value, setValue] = React.useState(0);
    const [viewType, setViewType] = React.useState('calendar'); // calendar | provider
    const [months, setMonths] = React.useState([]); // calendar | provider
    const [clinicDays, setClinicDays] = React.useState([]); // calendar | provider
    const [procedureDays, setProcedureDays] = React.useState([]); // calendar | provider
    const [locations, setLocations] = React.useState([]); // calendar | provider
    const [providers, setProviders] = React.useState([]); // calendar | provider

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        async function fetchData() {

            const today = new Date();
            const thirtyDays = new Date();
            thirtyDays.setDate(thirtyDays.getDate() + 30);

            const appts = await chargeClient.getAppointments({
                startDate: `${today.toISOString().split('T')[0]}T00:00:00.001Z`,
                endDate: `${thirtyDays.toISOString().split('T')[0]}T23:59:59.001Z`, // thirtyDays,
            });
            console.log(`[ScheduleDashboard] :: appts = `, appts);

            appts.forEach(appt => {
                appt.key = _getDateKey(appt.date);
            });

            const clinicAppts = appts.filter(x => 
                x.appointmentReason.toLowerCase() !== 'procedure'
                && x.appointmentStatus === 'Scheduled'
            );
            const procedureAppts = appts.filter(x => 
                x.appointmentReason.toLowerCase() === 'procedure'
                && x.appointmentStatus === 'Scheduled'
            );

            // build view data
            const clinicDateGroups = DataUtils.groupBy(clinicAppts, 'key');
            const procedureDateGroups = DataUtils.groupBy(procedureAppts, 'key');
            const locationGroups = DataUtils.groupBy(appts, 'location');
            const providerGroups = DataUtils.groupBy(appts, 'userId');

            console.log(`[ScheduleDashboard] :: clinicDateGroups = `, clinicDateGroups);
            console.log(`[ScheduleDashboard] :: procedureDateGroups = `, procedureDateGroups);
            console.log(`[ScheduleDashboard] :: locationGroups = `, locationGroups);
            console.log(`[ScheduleDashboard] :: providerGroups = `, providerGroups);

            const clinicDates = Object.entries(clinicDateGroups).map(([dateKey, appts]) => ({
                key: _getDateKey(dateKey),
                monthKey: _getMonthKey(dateKey),
                date: new Date(`${dateKey}`),
                data: appts
            }));
            clinicDates.sort((a, b) => +a.date - +b.date);
            console.log(`[ScheduleDashboard] :: clinicDates = `, clinicDates);
            const clinicKeys = clinicDates.map(x => x.monthKey);
            setClinicDays(clinicKeys);


            const procedureDates = Object.entries(procedureDateGroups).map(([dateKey, appts]) => ({
                key: _getDateKey(dateKey),
                monthKey: _getMonthKey(dateKey),
                date: new Date(`${dateKey}`),
                data: appts
            }));
            procedureDates.sort((a, b) => +a.date - +b.date);
            console.log(`[ScheduleDashboard] :: procedureDates = `, procedureDates);
            const procedureKeys = procedureDates.map(x => x.monthKey);
            setProcedureDays(procedureKeys);

            const allDates = [...clinicDates, ...procedureDates];

            // setup view data for calendar: location => clinic | procedure
            const locations = Object.entries(locationGroups).map(([k, v]) => ({
                name: k,
                appointments: v,
                clinicDays: [],
                procedureDays: [],
            }));
            // console.log(`[ScheduleDashboard] :: locations = `, locations);
            locations.forEach(location => {
                let totalClinicCount = 0;
                let totalProcedureCount = 0;

                clinicDates.forEach(date => {
                    const newDay = { key: date.key, clinicCount: 0, procedureCount: 0 };
                    const matchingClinicAppts = location.appointments.filter(x => x.key === date.key && x.appointmentReason.toLowerCase() !== 'procedure');
                    newDay.clinicCount += matchingClinicAppts.length;
                    totalClinicCount += matchingClinicAppts.length;
                    location.clinicDays.push(newDay);
                });

                procedureDates.forEach(date => {
                    const newDay = { key: date.key, clinicCount: 0, procedureCount: 0 };
                    const matchingProcedureAppts = location.appointments.filter(x => x.key === date.key && x.appointmentReason.toLowerCase() === 'procedure');
                    newDay.procedureCount += matchingProcedureAppts.length;
                    totalProcedureCount += matchingProcedureAppts.length;
                    location.procedureDays.push(newDay);
                });

                location.clinicTotal = totalClinicCount;
                location.procedureTotal = totalProcedureCount;
            });
            console.log(`[ScheduleDashboard] :: locations = `, locations);

            locations.sort((a, b) => a.name.localeCompare(b.name));
            setLocations(locations);


            // setup view data for provider: provider => location => date/month => clinic | procedure
            const usedMonthsKeys = [];
            const usedMonths = [];
            const providersList = Object.entries(providerGroups).map(([k, v]) => ({
                userId: k,
                name: v.length ? v[0].resourceName : '',
                appointments: v,
                days: []
            }));
            // console.log(`[ScheduleDashboard] :: providers = `, providers);

            providersList.forEach(provider => {
                const locGroups = DataUtils.groupBy(provider.appointments, 'location');
                provider.locGroups = locGroups;
                const locs = Object.entries(locGroups).map(([k, v]) => ({
                    name: k,
                    appointments: v,
                    months: [],
                    clinicTotal: 0,
                    procedureTotal: 0
                }));

                locs.forEach(location => {
                    // location.appointments.sort((a,b) => +b.date - +a.date);

                    location.appointments.forEach(appt => {
                        appt.month = _getMonthName(new Date(`${appt.date}`));
                        if (!usedMonthsKeys.includes(appt.month)) {
                            usedMonthsKeys.push(appt.month);
                            usedMonths.push({
                                date: new Date(`${appt.date}`),
                                month: appt.month
                            });
                        }
                    });
                });

                usedMonths.sort((a, b) => +a.date - +b.date);
                console.log(`[ScheduleDashboard] :: usedMonths = `, usedMonths);

                locs.forEach(location => {
                    location.months = usedMonths.map(x => { return { name: x.month, date: x.date, appointments: [], clinicCount: 0, procedureCount: 0 } });
                });

                locs.forEach(location => {
                    location.months.forEach(month => {
                        const matchingClinicAppts = location.appointments.filter(x => x.month === month.name && x.appointmentReason.toLowerCase() !== 'procedure');
                        const matchingProcedureAppts = location.appointments.filter(x => x.month === month.name && x.appointmentReason.toLowerCase() === 'procedure');
                        month.appointments = [...matchingClinicAppts, ...matchingProcedureAppts];
                        month.clinicCount = matchingClinicAppts.length;
                        month.procedureCount = matchingProcedureAppts.length;
                        location.clinicTotal += month.clinicCount;
                        location.procedureTotal += month.procedureCount;
                    });
                });

                provider.locations = locs;

            });

            providersList.forEach(x => {
                const clinicAppts = x.appointments.filter(x => x.appointmentReason.toLowerCase() !== 'procedure');
                const procAppts = x.appointments.filter(x => x.appointmentReason.toLowerCase() === 'procedure');
                x.hasClinicData = clinicAppts.length > 0;
                x.hasProcedureData = procAppts.length > 0;
            });

            console.log(`[ScheduleDashboard] :: providers = `, providersList);
            setProviders(providersList);


            setMonths(usedMonths);

        }
        fetchData();

    }, []);

    const monthFormatter = new Intl.DateTimeFormat('en', { month: 'short' });
    function _getDateKey(date) {
        const theDate = new Date(`${date}`);
        // return `${theDate.getDate()}-${monthFormatter.format(theDate)}`;
        return `${theDate.getFullYear()}-${theDate.getMonth() + 1}-${theDate.getDate()}`
    }
    function _getMonthKey(date) {
        const theDate = new Date(`${date}`);
        return `${theDate.getDate()}-${monthFormatter.format(theDate)}`;
    }
    function _getMonthName(date) {
        const theDate = new Date(`${date}`);
        return `${monthFormatter.format(theDate)}`;
    }

    function downloadView(procedureType) {
        console.log(`downloading ${procedureType} | ${viewType}`);

        const rows = [];

        if (procedureType === 'clinic') {
            if (viewType === 'calendar') {
                // set up headers
                rows.push(['Location', ...clinicDays, 'Total']);
                // set up data
                for (let location of locations) {
                    let row = [location.name.replace(/,/g, '')];
                    for (let day of location.clinicDays) {
                        row.push(day.clinicCount ? day.clinicCount : '');
                    }
                    row.push(location.clinicTotal);
                    rows.push(row);
                }
            }
            if (viewType === 'provider') {
                // set up headers
                const monthNames = months.map((month) => month.month);
                rows.push(['Provider', ...monthNames, 'Total']);
                // set up data
                const clinicProviders = providers.filter(x => x.hasClinicData);
                for (let provider of clinicProviders) {
                    // set up provider name row
                    rows.push([provider.name.replace(/,/g, ''), ...monthNames.map(x => ''), '']);

                    // set up each location per provider
                    for (let location of provider.locations) {
                        const locationAmounts = location.months.map(x => x.clinicCount ? x.clinicCount : '');
                        rows.push([location.name.replace(/,/g, ''), ...locationAmounts, location.clinicTotal]);
                    }
                }
            }
        }
        if (procedureType === 'procedures') {
            if (viewType === 'calendar') {
                // set up headers
                rows.push(['Location', ...procedureDays, 'Total']);
                // set up data
                for (let location of locations) {
                    let row = [location.name.replace(/,/g, '')];
                    for (let day of location.procedureDays) {
                        row.push(day.procedureCount ? day.procedureCount : '');
                    }
                    row.push(location.procedureTotal);
                    rows.push(row);
                }
            }
            if (viewType === 'provider') {
                // set up headers
                const monthNames = months.map((month) => month.month);
                rows.push(['Provider', ...monthNames, 'Total']);
                // set up data
                const procedureProviders = providers.filter(x => x.hasProcedureData);
                for (let provider of procedureProviders) {
                    // set up provider name row
                    rows.push([provider.name.replace(/,/g, ''), ...monthNames.map(x => ''), '']);

                    // set up each location per provider
                    for (let location of provider.locations) {
                        const locationAmounts = location.months.map(x => x.procedureCount ? x.procedureCount : '');
                        rows.push([location.name.replace(/,/g, ''), ...locationAmounts, location.procedureTotal]);
                    }
                }
            }
        }

        let csvContent = rows.map(e => e.join(",")).join("\n");
        csvContent = csvContent.replace(/""/g, '');
        console.log(`csvContent = `, csvContent);

        let csvData = new Blob([csvContent], { type: 'text/csv' });
        let csvUrl = URL.createObjectURL(csvData);
        let link = document.createElement("a");
        link.setAttribute("href", csvUrl);
        link.setAttribute("download", `Schedule_${procedureType}_${viewType}_${(new Date()).toLocaleDateString('en-US')}.csv`);
        document.body.appendChild(link); // Required for FF

        link.click();
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: `100%`, height: '100%' }}>
            <SEO title="Schedule" />
            <h2 style={{ marginBottom: '4px' }}>Schedule</h2>

            <div style={{ display: 'flex', flexDirection: 'column', width: `100%`, height: '100%' }}>
                <AppBar position="static" style={{
                    display: 'flex', flexDirection: 'row',
                    justifyContent: 'space-between', alignItems: 'center',
                    width: `100%`, height: '100%',
                    paddingRight: '16px'
                }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Clinic" />
                        <Tab label="Procedures" />
                    </Tabs>
                    <Box className='test-btns'>
                        <ButtonGroup variant="contained" color="primary">
                            <Tooltip title="Calendar View" placement="top">
                                <Button onClick={(event) => setViewType('calendar')} style={{ background: viewType === 'calendar' ? 'rgb(69, 182, 243)' : '#0171B9' }}>
                                    <EventIcon></EventIcon>
                                </Button>
                            </Tooltip>
                            <Tooltip title="Provider View" placement="top">
                                <Button onClick={(event) => setViewType('provider')} style={{ background: viewType === 'provider' ? 'rgb(69, 182, 243)' : '#0171B9' }}>
                                    <AssignmentIndIcon></AssignmentIndIcon>
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Box>
                </AppBar>

                {viewType === 'calendar' &&
                    <div style={{ display: 'flex', flex: '1 1 auto', height: 'calc(100vh - 224px)', overflowX: 'auto', padding: '0 16px', minWidth: '100%' }}>

                        <TabPanel key={`calendar_clinic`} value={value} index={0} sx={{ height: '100%', overflow: 'auto', minWidth: '100%' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <h3 style={{ marginBottom: '8px', marginTop: '4px' }}>Next 30 Days</h3>
                                <Button variant="outlined" size="small" color="primary" onClick={(evt) => downloadView('clinic')}>Download</Button>
                            </Box>
                            <Box className='calendar-container'>
                                <Box className='calendar-row header' style={{ display: 'flex', width: '100%' }}>
                                    <Box className='calendar-row-item facility-name'></Box>
                                    {clinicDays.map((day) => (
                                        <Box className='calendar-row-item right-align right-border'>{day}</Box>
                                    ))}
                                    <Box className='calendar-row-item right-align'>Total</Box>
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    {locations.map((location) => {
                                        return (
                                            <Box className='calendar-row'>
                                                <Tooltip title={location.name} placement="right">
                                                    <Box className='calendar-row-item facility-name'>{location.name}</Box>
                                                </Tooltip>
                                                {location.clinicDays.map((day) => <Box className='calendar-row-item right-align right-border'>
                                                    {day.clinicCount ? day.clinicCount : ''}
                                                </Box>)}
                                                <Box className='calendar-row-item right-align total'>
                                                    {location.clinicTotal}
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </TabPanel>

                        <TabPanel key={`calendar_procedure`} value={value} index={1} sx={{ height: '100%', overflow: 'auto', minWidth: '100%' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <h3 style={{ marginBottom: '8px', marginTop: '4px' }}>Next 30 Days</h3>
                                <Button variant="outlined" size="small" color="primary" onClick={(evt) => downloadView('procedure')}>Download</Button>
                            </Box>
                            <Box className='calendar-container'>
                                <Box className='calendar-row header' style={{ display: 'flex', width: '100%' }}>
                                    <Box className='calendar-row-item facility-name'></Box>
                                    {procedureDays.map((day) => (
                                        <Box className='calendar-row-item right-align right-border'>{day}</Box>
                                    ))}
                                    <Box className='calendar-row-item right-align'>Total</Box>
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    {locations.map((location) => {
                                        return (
                                            <Box className='calendar-row'>
                                                <Tooltip title={location.name} placement="right">
                                                    <Box className='calendar-row-item facility-name'>{location.name}</Box>
                                                </Tooltip>
                                                {location.procedureDays.map((day) => <Box className='calendar-row-item right-align right-border'>
                                                    {day.procedureCount ? day.procedureCount : ''}
                                                </Box>)}
                                                <Box className='calendar-row-item right-align total'>
                                                    {location.procedureTotal}
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </TabPanel>

                    </div>
                }

                {viewType === 'provider' &&
                    <div style={{ display: 'flex', flex: '1 1 auto', height: 'calc(100vh - 224px)', overflowX: 'auto', minWidth: '100%' }}>

                        <TabPanel key={`provider_clinic`} value={value} index={0} sx={{ height: '100%', overflow: 'auto', minWidth: '100%' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <h3 style={{ marginBottom: '8px', marginTop: '4px' }}>Next 30 Days</h3>
                                <Button variant="outlined" size="small" color="primary" onClick={(evt) => downloadView('clinic')}>Download</Button>
                            </Box>
                            <Box className='provider-container'>
                                <Box className='provider-row header' style={{ display: 'flex', width: '100%' }}>
                                    <Box className='provider-row-item facility-name wide'></Box>
                                    {months.map((month) => (
                                        <Box key={`${month.month}_header`} className='provider-row-item right-align right-border'>{month.month}</Box>
                                    ))}
                                    <Box className='provider-row-item right-align total'>Total</Box>
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    {providers.map((provider) => {
                                        if (provider.hasClinicData) {
                                            return (
                                                <Box key={provider.userId} className='provider-group'>
                                                    <Box className='provider-row'>
                                                        <Tooltip title={provider.name} placement="right">
                                                            <Box className='provider-row-item provider-name wide'>{provider.name}</Box>
                                                        </Tooltip>
                                                        {months.map((month) => (
                                                            <Box key={`${provider.userId}_${month.month}`} className='provider-row-item right-align right-border'></Box>
                                                        ))}
                                                        <Box className='provider-row-item right-align'></Box>
                                                    </Box>
                                                    {provider.locations.map((location) =>
                                                        <Box key={location.name} className='provider-row'>
                                                            <Tooltip title={location.name} placement="right">
                                                                <Box className='provider-row-item facility-name wide'>{location.name}</Box>
                                                            </Tooltip>
                                                            {location.months.map((month) =>
                                                                <Box key={`${month.name}_clinic_count`} className='provider-row-item right-align right-border'>{month.clinicCount ? month.clinicCount : ''}</Box>
                                                            )}
                                                            <Box className='provider-row-item right-align total'>{location.clinicTotal}</Box>
                                                        </Box>
                                                    )}
                                                </Box>
                                            )
                                        }
                                    })}
                                </Box>
                            </Box>
                        </TabPanel>

                        <TabPanel key={`provider_procedure`} value={value} index={1} sx={{ height: '100%', overflow: 'auto', minWidth: '100%' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <h3 style={{ marginBottom: '8px', marginTop: '4px' }}>Next 30 Days</h3>
                                <Button variant="outlined" size="small" color="primary" onClick={(evt) => downloadView('procedure')}>Download</Button>
                            </Box>
                            <Box className='provider-container'>
                                <Box className='provider-row header' style={{ display: 'flex', width: '100%' }}>
                                    <Box className='provider-row-item facility-name wide'></Box>
                                    {months.map((month) => (
                                        <Box key={`${month.month}_header`} className='provider-row-item right-align right-border'>{month.month}</Box>
                                    ))}
                                    <Box className='provider-row-item right-align total'>Total</Box>
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    {providers.map((provider) => {
                                        if (provider.hasProcedureData) {
                                            return (
                                                <Box key={provider.userId} className='provider-group'>
                                                    <Box className='provider-row'>
                                                        <Box className='provider-row-item provider-name wide'>{provider.name}</Box>
                                                        {months.map((month) => (
                                                            <Box key={`${provider.userId}_${month.month}`} className='provider-row-item right-align right-border'></Box>
                                                        ))}
                                                        <Box className='provider-row-item right-align'></Box>
                                                    </Box>
                                                    {provider.locations.map((location) =>
                                                        <Box key={location.name} className='provider-row'>
                                                            <Box className='provider-row-item facility-name wide'>{location.name}</Box>
                                                            {location.months.map((month) =>
                                                                <Box key={`${month.name}_procedure_count`} className='provider-row-item right-align right-border'>{month.procedureCount ? month.procedureCount : ''}</Box>
                                                            )}
                                                            <Box className='provider-row-item right-align total'>{location.procedureTotal}</Box>
                                                        </Box>
                                                    )}
                                                </Box>
                                            )
                                        } else {
                                            return (<></>)
                                        }
                                    })}
                                </Box>
                            </Box>
                        </TabPanel>

                    </div>
                }
            </div>

        </div>
    )
}

export default ScheduleDashboard;

