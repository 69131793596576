import { unitRateExpections } from '../env/connection';
// import { GET_KAREO_LOCATIONS, GET_LOCATION_CPTS } from '../components/queries/queries';
import { api } from '../env/connection';
import { tokenProvider } from '../providers/tokenProvider';

export const ComparisonUnitsProvider = (() => {

    let kareoLocations = [];
    let cptTiers = [];
    let locationCpts = [];

    async function refreshData() {
        await new Promise(resolve => {
            const checkToken = setInterval(() => {
                if (tokenProvider.token !== null) {
                    clearInterval(checkToken)
                    resolve()
                }
            }, 250)
        })

        if (!kareoLocations.length) {
            // let locationsResponse = await kareoLocationsQuery.refetch();
            // kareoLocations = locationsResponse.data.kareoLocations;

            const kareoLocationsOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${tokenProvider.token}`,
                },
                body: JSON.stringify({
                    operationName: "kareoLocations",
                    variables: { "filter": "" },
                    query: `query kareoLocations($filter: String!) {
                        kareoLocations(filter: $filter) {
                            id
                            kareoId
                            practiceName
                            chargeType
                            chargeTypeDate
                            tierId
                            chargeCap
                            tiers {
                                id
                                locationId
                                tierId
                                tierName
                                startDate
                                endDate
                            }
                            facilityfees {
                                id
                                feeName
                                amount
                                billingType
                                createdById
                                modifiedById
                                createdDate
                                modifiedDate
                                effectiveDate
                            }
                        }
                    }`
                })
            };

            let locationsResponse = await fetch(api, kareoLocationsOptions);
            let locationsJson = await locationsResponse.json();
            kareoLocations = locationsJson.data.kareoLocations;
            // console.log(`kareoLocations = `, kareoLocations);
        } else {
            console.log(`ALREADY LOADED :: kareoLocations = `, kareoLocations);
        }

        if (!cptTiers.length) {
            // let locationCptsResponse = await locationCptsQuery.refetch();
            // locationCpts = locationCptsResponse.data.locationCpts;

            const cptTiersOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${tokenProvider.token}`,
                },
                body: JSON.stringify({
                    operationName: "cptTiers",
                    variables: { "filter": "" },
                    query: `query cptTiers($filter: String!) {
                        cptTiers(filter: $filter) {
                            id,
                            tierId,
                            tierName,
                            cptId,
                            cptCode,
                            primaryUnits,
                            secondaryUnits
                        }
                    }`
                })
            };

            let cptTiersResponse = await fetch(api, cptTiersOptions);
            let cptTiersJson = await cptTiersResponse.json();
            cptTiers = cptTiersJson.data.cptTiers;
            // console.log(`locationCpts = `, locationCpts);
        } else {
            console.log(`ALREADY LOADED :: cptTiers = `, cptTiers);
        }

        if (!locationCpts.length) {
            // let locationCptsResponse = await locationCptsQuery.refetch();
            // locationCpts = locationCptsResponse.data.locationCpts;

            const locationCptsOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${tokenProvider.token}`,
                },
                body: JSON.stringify({
                    operationName: "locationCpts",
                    variables: { "filter": "" },
                    query: `query locationCpts($filter: String!) {
                        locationCpts(filter: $filter) {
                            locationId,
                            locationDescription,
                            cptId,
                            cptCode,
                            primaryUnits,
                            secondaryUnits
                        }
                    }`
                })
            };

            let locationCptsResponse = await fetch(api, locationCptsOptions);
            let locationCptsJson = await locationCptsResponse.json();
            locationCpts = locationCptsJson.data.locationCpts;
            // console.log(`locationCpts = `, locationCpts);
        } else {
            console.log(`ALREADY LOADED :: locationCpts = `, locationCpts);
        }
    }

    function applyUnitCap(unitsTotalForVisit, locationName) {
        let finalUnits = unitsTotalForVisit;
        let foundLocation = kareoLocations.find(x => x.practiceName === locationName);
        if (foundLocation && foundLocation.chargeType !== 'Legacy') {
            const locationChargeCap = foundLocation.facilityfees.find(locationfee => locationfee.feeName === "ChargeCap");
            if (locationChargeCap && (parseFloat(`${finalUnits}`) > parseFloat(`${locationChargeCap.amount}`))) {
                finalUnits = parseFloat(`${locationChargeCap.amount}`);
            }
        } else if (foundLocation && foundLocation.chargeType === 'Legacy') {
            if (parseFloat(`${finalUnits}`) > 40) {
                finalUnits = 40;
            }
        }
        return finalUnits;
    }

    function getUnits(ticket, ticketCpt, providerType, locationName, isClinic, cptTierName, chargeCap) { // ignoreUnitCap) {

        // console.log(`kareoLocations = `, kareoLocations);
        // console.log(`locationCpts = `, locationCpts);

        let units = 0.0;

        let newLogicSuccess = false;
        const foundLocation = kareoLocations.find(x => x.practiceName === locationName);
        // const foundTier = getTier(ticket, foundLocation);
        const foundTier = { tierName: cptTierName };

        // first check that the new logic should be used based on the tier
        if (foundLocation && foundTier.tierName !== 'Legacy') { // && foundLocation.chargeType !== 'Legacy') {

            if (!ticketCpt) {
                console.log(`ERROR WITH TICKET CPTs`, ticket);
                console.logError(`ERROR WITH TICKET CPTs`, ticket);
            }
            if (!ticketCpt.cpt) {
                console.log(`ERROR WITH TICKET CPTs`, ticket);
                console.logError(`ERROR WITH TICKET CPTs`, ticket);
            }
            // console.log(`DO NEW 2022 LOGIC!!! => ${locationName} | cpt = ${ticketCpt.cpt.code}`, ticket);
            // do the NEW logic... (based on new contracts)

            // first try to apply TIER units
            let cptTier = cptTiers.find(x => `${x.cptId}` === `${ticketCpt.cpt.id}` && `${x.tierName}` === `${foundTier.tierName}`);
            if (!cptTier) {
                // try to get the DEFAULT unit rate
                // console.log(`Not found in TIER. Looking for default..`);
                cptTier = cptTiers.find(x => `${x.cptId}` === `${ticketCpt.cpt.id}` && x.tierId === 0);
            }
            if (cptTier) {
                // console.log(`Found cptTier:`, cptTier);
                // this CPT is part of the DEFALT tier
                if (ticketCpt.cpt.code.startsWith('99')) {
                    units = cptTier.primaryUnits;
                } else if (ticketCpt.sequence === 1) {
                    units = cptTier.primaryUnits;
                } else {
                    units = cptTier.secondaryUnits;
                }
                newLogicSuccess = true;
            }

            // // override with location-specific CPTs (if found)
            // let locationCpt = locationCpts.find(x => `${x.cptId}` === `${ticketCpt.cpt.id}` && `${x.locationId}` === `${foundLocation.id}`);
            // if (locationCpt) {
            //     // console.log(`Found LocationCPT:`, locationCpt);
            //     // this CPT has an exception for this location...
            //     if (ticketCpt.cpt.code.startsWith('99')) {
            //         units = cptTier.primaryUnits;
            //     } else
            //         if (ticketCpt.sequence === 1) {
            //             units = locationCpt.primaryUnits;
            //         } else {
            //             units = locationCpt.secondaryUnits;
            //         }
            //     newLogicSuccess = true;
            // }

            // don't charge for "ordered proceures" (i.e. from PAs doing a Clinic visit)
            // BUT unlike legacy computation, some facilities are charged for clinica visits (i.e. when IsClinic==true)
            if (providerType === 'PA') {
                if (!ticketCpt.cpt.code.startsWith('99')) {
                    // console.log(`Provider is 'PA' so units = 0`);
                    units = 0;
                }
            }

            // don't charge for "ordered proceures" (sequence != 1) during a Clinic Visit
            if (isClinic && ticketCpt.sequence !== 1 && !ticketCpt.cpt.code.startsWith('99')) {
                // console.log(`sequence is (${ticketCpt.sequence}) and does not start with '99' so units = 0`);
                units = 0;
            }

            // never charge for training
            if (locationName.toLowerCase().includes('training') || ticket.patientName.toLowerCase().includes('training') || locationName.toLowerCase().includes('test')) {
                // console.log(`'HPM Training' so units = 0`);
                units = 0;
            }

            // if (units > foundLocation.chargeCap) {
            //     console.log(`Applying charge cap (units => ${foundLocation.chargeCap}) for ${foundLocation.practiceName}`);
            //     units = foundLocation.chargeCap;
            // }

            // if (chargeCap && units > chargeCap) {
            //     console.log(`Applying selected charge cap (units => ${chargeCap}) for ${foundLocation.practiceName}`);
            //     units = chargeCap;
            // } else if (units > foundLocation.chargeCap) {
            //     console.log(`Applying charge cap (units => ${foundLocation.chargeCap}) for ${foundLocation.practiceName}`);
            //     units = foundLocation.chargeCap;
            // }
        }

        if (!newLogicSuccess) {
            // console.log(`DO OLD LEGACY LOGIC!!! => ${locationName} | cpt = ${ticketCpt.cpt.code}`, ticketCpt);
            // do the LEGACY logic...

            // never charge for training
            if (locationName.toLowerCase().includes('training') || ticket.patientName.toLowerCase().includes('training') || locationName.toLowerCase().includes('test')) {
                return 0;
            }
            if (isClinic || providerType === 'PA' || locationName.toLowerCase().includes('training') || ticket.patientName.toLowerCase().includes('training')) {
                if (locationName !== 'Rawlins')
                    return units;
                else if (ticketCpt.cpt.code.startsWith('99'))
                    return ticketCpt.cpt.primaryUnits;
            }
            else if (ticketCpt.sequence === 1) {
                units = ticketCpt.cpt.primaryUnits;
            }
            else {
                units = ticketCpt.cpt.secondaryUnits;
            }
            unitRateExpections.map((exception) => {
                if (exception.location === locationName && exception.code === ticketCpt.cpt.id) {
                    units *= exception.rate;
                }
                return false;
            });
        }

        // console.log(`get UNITS = ${units} => ${locationName} | cpt = ${ticketCpt.cpt.code}`);
        return Number(units);
    }

    function getTier(ticket, location) {
        if (!location) { return 'Legacy'; }
        if (location.tiers.length === 0) { return 'Legacy'; }

        let ticketDate = new Date(`${ticket.date}`);

        // is ticket in active tier? (end date == null)
        let activeTier = location.tiers.find(x => !x.endDate);
        if (!activeTier) { return 'Legacy'; }

        let startDate = new Date(`${activeTier.startDate}`);
        if (ticketDate > startDate) { return activeTier; }

        // get the tier the ticket is in based on start/end dates
        activeTier = location.tiers.filter(x => x.endDate)
            .find(x =>
                ticketDate < new Date(`${x.endDate}`)
                && ticketDate > new Date(`${x.startDate}`));

        if (activeTier) { return activeTier; }

        // throw `Cannot find tier for ticket with date: ${ticketDate}`;
        return 'Legacy';
    }

    return {
        // getInstance: getInstance,
        refreshData: refreshData,
        getUnits: getUnits,
        applyUnitCap: applyUnitCap
    };
})();
