import React, { useState } from 'react';
// import Img from "gatsby-image";
// import { useStaticQuery, graphql } from "gatsby";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
// import { gql } from "apollo-boost"
// import { GraphQLClient } from 'graphql-request'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';


// import * as Excel from "exceljs";
// import { read, utils, writeFileXLSX } from 'xlsx';
// import { read, utils, writeFileXLSX } from 'xlsx-style';

import { Renderer } from 'xlsx-renderer';
import { saveAs } from "file-saver";

// import { similarity } from 'string-similarity';

import ReportDataProvider from './report-data-provider';
import * as DataUtils from './report-data-utils';
import { KareoStatus } from '../charge-sheet/charge-sheet-data-provider';
// import { getUnits } from '../../utils/unitUtils';
// import { Workbook } from 'exceljs';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        marginRight: 0
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
        flexGrow: 1,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    flexContainer: {
        display: 'flex'
    },
    flexItem: {
        width: '100%',
        flexGrow: 1,
        margin: 8,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

// const client = new GraphQLClient('http://localhost:4000', {
//     headers: {
//       authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJja2I5dXd2M2ZzNXBhMDk2ODRqYjFvOG1lIiwiaWF0IjoxNTkyMzQxODY3fQ.r13nZ63HjDZgYkt8m9w1hJYWxSrUBLlGopMW0S8AF2Y',
//     },
//   });

const dateFormatter = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC'
})

async function writeToTemplate(viewModel) {

    let response = await fetch('https://public-hpm-portal-files.s3.us-east-2.amazonaws.com/TEMPLATE_Call-Back-Log.xlsx');
    // let response = await fetch('/TEMPLATE_HPM-Itemized-Bills.xlsx');
    let buffer = await response.arrayBuffer();
    const renderer = new Renderer();
    const report = await renderer.renderFromArrayBuffer(buffer, viewModel);

    // get month and day
    let startDate = new Date(viewModel.metadata.startDate);

    let sheet = report.getWorksheet(1);
    sheet.name = `${DataUtils.monthNames[startDate.getMonth()]} ${DataUtils.getOrdinalSuffix(startDate.getDate())}`;

    const output = await report.xlsx.writeBuffer();
    await saveAs(new Blob([output]), `HPM-Call-Log-Report_${(new Date()).toLocaleDateString('en-US')}.xlsx`);
}

export default function CallLogReportDialog() {
    const [open, setOpen] = React.useState(false);
    const [isBuilding, setIsBuilding] = React.useState(false);
    const [locations, setLocations] = React.useState([]);

    const provider = ReportDataProvider();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();

    const [facilityOption, setFacilityOption] = useState(null);
    const handleChange1 = (event) => {
        setFacilityOption(event.target.value);
    };

    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    async function buildReport() {
        setIsBuilding(true);

        console.log(`Building Report...`);

        let finalData = {};

        let reportData
        try {
            reportData = await buildReportData();
        } catch (e) {
            setIsBuilding(false);
            throw e;
        }

        finalData.rows = reportData.kareoData;

        finalData.metadata = {
            createdDate: `${new Date()}`,
            createdBy: `HPM Charge User`,
            location: `${facilityOption}`,
            month: `${DataUtils.monthNames[selectedStartDate.getMonth()]}, ${selectedStartDate.getFullYear()}`,
            startDate: `${selectedStartDate.toLocaleDateString('en-US')}`,
            endDate: `${selectedStartDate.toLocaleDateString('en-US')}`,
        };

        console.log(`reportData = `, finalData);

        console.log(`Writing report data to file...`);
        try {
            await writeToTemplate(finalData);
        } catch (e) {
            setIsBuilding(false);
            throw e;
        }

        console.log(`Done!`);

        setIsBuilding(false);
        handleClose();
    }

    async function buildReportData() {

        selectedStartDate.setHours(0, 0, 0, 1);

        var endDate = new Date(selectedStartDate);
        endDate.setHours(11, 59, 59, 0);

        let kareoData;
        let kareoPatientData;
        let appointmentDataMap;

        // get charge ticket data
        kareoData = await provider.getKareoAppointmentData(selectedStartDate, endDate);
        kareoPatientData = await provider.getKareoPatientData();

        // console.log('HPM Charge data = ', ticketData);
        console.log('kareoPatientData = ', kareoPatientData);
        appointmentDataMap = getKareoMap(kareoData, kareoPatientData);
        console.log('HPM Kareo data map = ', appointmentDataMap);

        return {
            kareoData: appointmentDataMap
        }
    }

    function getStatus(status) {
        if (status === 'Check-out' || status === KareoStatus.CHECK_OUT || status === KareoStatus.CHECKEDOUT) {
            return 'checkout';
        } else if (status === 'Cancelled' || status === KareoStatus.CANCELLED) {
            return 'cancelled';
        } else if (status === 'No-show' || status === KareoStatus.NOSHOW) {
            return 'noShow';
        } else if (status === 'Rescheduled' || status === KareoStatus.RESCHEDULED) {
            return 'rescheduled';
        } else {
            return null;
        }
    }

    function getKareoMap(data, patientData, filter) {
        let dataMap = [];

        let statusMap = {};
        for (let i = 0; i < data.length; i++) {

            let resourceNameParts = data[i].resourceName.trim().split(' ');
            let resourceName = `${resourceNameParts[0]}${resourceNameParts[resourceNameParts.length - 1]}`;
            // let resourceName = `${data[i].resourceName.replaceAll(' ', '')}`;

            let patientNameParts = data[i].patientName.replace(/&QUOT;/g, '').trim().split(' ');
            let patientNameKey = `${patientNameParts[0]}${patientNameParts[patientNameParts.length - 1]}`;

            // let key = `${data[i].patientName}${data[i].mrn}${data[i].resourceName}`.replace(/\s/g, '').trim().toLowerCase();
            // let key = `${data[i].patientName}${getFormattedDate(data[i].date)}${resourceName}`.replace(/\s/g, '').trim().toLowerCase();
            let key = `${patientNameKey}${dateFormatter.format(new Date(data[i].date)).replace(/\//g, '')}${resourceName}`.replace(/\s/g, '').trim().toLowerCase();

            if (filter && !filter(data[i])) {
                continue;
            }

            // data[i].date = `${dateFormatter.format(new Date(data[i].date))}`; // `${data[i].date.toLocaleDateString('en-US')}`;
            data[i].date = data[i].dateKey
            data[i].patientName = data[i].patientName.replace(/&QUOT;/g, '');

            // console.log(`data mrn = ${data[i].mrn}`);
            let patientInfo = patientData.filter(x => `${x.mrn}` === `${data[i].mrn}`);
            // console.log(`patientInfo = `, patientInfo);
            if (patientInfo.length > 0) {
                data[i].phoneNumber = patientInfo[0].phoneNumber;
            }

            // group by status
            if (!statusMap[data[i].appointmentStatus]) { statusMap[data[i].appointmentStatus] = []; }
            statusMap[data[i].appointmentStatus].push(data[i]);
        }

        let maxLength = 0;
        for (let stat of Object.keys(statusMap)) {
            if (statusMap[stat].length > maxLength) {
                maxLength = statusMap[stat].length;
            }
        }

        // let statOptions = [
        //     "Check-out",
        //     "Rescheduled",
        //     "Scheduled",
        //     "Cancelled",
        //     "No-show",
        //     "Unspecified",
        //     "Check-in",
        //     "Confirmed"];

        let statOptions = Object.keys(statusMap);
        for (let row = 0; row < maxLength; row++) {
            let rowItem = {};
            for (let stat of statOptions) {
                if (statusMap[stat][row]) {
                    if (getStatus(stat)) {
                        rowItem[getStatus(stat)] = statusMap[stat][row];
                    }
                }
            }

            dataMap.push(rowItem);
        }

        console.log(`DATA MAP = `, dataMap);

        return dataMap;
    }

    if (isBuilding) {
        return (
            <div>
                <Card className={classes.root}>
                    <CardActionArea onClick={handleClickOpen}>
                        {/* <Img fixed={discrepancyReportImage.placeholderImage.childImageSharp.fixed} /> */}
                        <img src={require('../../images/Call-Log-Report.png').default} />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Call Log Report
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Call log for a specific day, broken down by status.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    {/* <CardActions>
                        <Button size="small" color="primary" onClick={handleClickOpen}>
                            Build Report
                        </Button>
                    </CardActions> */}
                </Card>


                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Building Call Log Report</DialogTitle>
                    <DialogContent>
                        <div className={classes.flexCenter}>
                            <CircularProgress />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

    return (
        <div>
            <Card className={classes.root}>
                <CardActionArea onClick={handleClickOpen}>
                     {/* <Img fixed={discrepancyReportImage.placeholderImage.childImageSharp.fixed} /> */}
                     <img src={require('../../images/Call-Log-Report.png').default} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Call Log Report
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Call log for a specific day, broken down by status.
                        </Typography>
                    </CardContent>
                </CardActionArea>
                {/* <CardActions>
                    <Button size="small" color="primary" onClick={handleClickOpen}>
                        Build Report
                    </Button>
                </CardActions> */}
            </Card>


            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create Call Log Report</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The call log report shows call logs for a specific day grouped by status.
                    </DialogContentText>

                    <div className={classes.flexContainer}>
                        <div className={classes.flexItem}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Start Date"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => buildReport()} color="primary" variant="contained">
                        Build Report
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
