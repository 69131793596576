
export const TierUtils = (() => {
    function getTier(location, date) {
        if (!location) { return null; }
        if (location.tiers.length === 0) { return null; }
        if (!date) { throw `Cannot find tier without date`; }

        let theDate = new Date(`${date}`);

        // is ticket in active tier? (end date == null)
        let activeTier = location.tiers.find(x => !x.endDate);

        let startDate = new Date(`${activeTier.startDate}`);
        if (theDate > startDate) { return activeTier; }

        // get the tier the date is in based on start/end dates
        activeTier = location.tiers.filter(x => x.endDate)
            .find(x =>
                theDate < new Date(`${x.endDate}`)
                && theDate > new Date(`${x.startDate}`));

        if (activeTier) { return activeTier; }


        return null;
    }

    return {
        getTier: getTier
    };
})();
