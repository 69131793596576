import { sameMonth, daysInMonth } from '../components/reports/report-utils';

export function getActiveFees(location, feeName, startDate, endDate) {

  let totalFees = {
      location: location,
      amount: 0,
      proRatedAmount: 0,
      total: 0,
      fees: []
  };

  // let sdate = new Date(`${startDate}`);
  // let edate = new Date(`${endDate}`);

  let fees = location.facilityfees.filter(x => x.feeName === feeName);
  for (let fee of fees) {
      fee.proRatedAmount = 0;
      // apply effective dates for fees (have to account for timezone...)
      let feeStartDate = new Date(`${fee.effectiveDate}`);
      // feeStartDate = new Date(feeStartDate.getTime() - feeStartDate.getTimezoneOffset() * 60 * 1000);
      // feeStartDate.setSeconds(sdate.getSeconds() + 1);
      let feeEndDate = fee.endDate ? new Date(`${fee.endDate}`) : null;
      // if (feeEndDate) {
      //     feeEndDate = new Date(feeEndDate.getTime() - feeEndDate.getTimezoneOffset() * 60 * 1000);
      //     feeEndDate.setSeconds(edate.getSeconds() + 1);
      // }

      // case 1: fee outside our timespan
      if (fee.endDate) { // if fee has end date
          if (feeEndDate < startDate) { // if the fee ended before our timespan starts (case 1)
              continue; // skip this fee
          }
      }
      if (feeStartDate > endDate) { // fee starts after our end date
          continue; // skip this fee
      }

      // console.log(`${location.practiceName} :: startDate = ${startDate} | endDate = ${endDate}`);
      // console.log(`${location.practiceName} :: ${fee.feeName} (${fee.id}) feeStartDate = ${feeStartDate} | feeEndDate = ${feeEndDate}`);

      // case 2: fee starts inside our timespan
      if (feeStartDate >= startDate) { // if the fee started after our start date
          // console.log(`${location.practiceName} :: startDate = ${startDate} | endDate = ${endDate}`);
          // console.log(`${location.practiceName} :: ${fee.feeName} (${fee.id}) feeStartDate = ${feeStartDate} | feeEndDate = ${feeEndDate}`);
          if (sameMonth(feeStartDate, startDate)) { // if fee started in the same month as our timespan start
              // console.log(`${location.practiceName} :: ${fee.feeName} (${fee.id}) feeStartDate = ${feeStartDate} | feeEndDate = ${feeEndDate}`);
              // calculate pro-rated amount
              // console.log(`${location.practiceName} :: ${fee.feeName} EffectiveDate = ${feeEffectiveDate}`);
              let numDaysInMonth = daysInMonth(startDate.getMonth() + 1, startDate.getFullYear());
              let remainingDays = numDaysInMonth - feeStartDate.getDate();
              fee.proRatedAmount = parseFloat(`${fee.amount}`) * (remainingDays / numDaysInMonth);
              // console.log(`${location.practiceName} :: ${fee.feeName} (${fee.id}) numDaysInMonth = ${numDaysInMonth} | remainingDays = ${remainingDays}`);
              console.log(`${location.practiceName} => ${fee.feeName} (${fee.id}) proRatedAmount = ${fee.proRatedAmount} (from ${fee.amount})`);
          }
      }

      // case 3: fee ends inside our timespan (but didn't start in our timespan)
      if (fee.endDate && !fee.proRatedAmount) { // if fee has end date, and proration not already calculated (from case 2)
          if (feeEndDate <= endDate) { // if the fee ended before our end date
              if (sameMonth(feeEndDate, endDate)) { // if fee ended in the same month as our timespan end
                  // console.log(`${location.practiceName} :: ${fee.feeName} (${fee.id}) feeStartDate = ${feeStartDate} | feeEndDate = ${feeEndDate}`);
                  // calculate pro-rated amount
                  // console.log(`${location.practiceName} :: ${fee.feeName} EffectiveDate = ${feeEffectiveDate}`);
                  let numDaysInMonth = daysInMonth(endDate.getMonth() + 1, endDate.getFullYear());
                  let remainingDays = numDaysInMonth - feeEndDate.getDate();
                  fee.proRatedAmount = parseFloat(`${fee.amount}`) * ((numDaysInMonth - remainingDays) / numDaysInMonth);
                  // console.log(`${location.practiceName} :: ${fee.feeName} (${fee.id}) numDaysInMonth = ${numDaysInMonth} | remainingDays = ${remainingDays} | endDate = ${feeEndDate.getDate()}`);
                  console.log(`${location.practiceName} => ${fee.feeName} (${fee.id}) proRatedAmount = ${fee.proRatedAmount} (from ${fee.amount})`);
              }
          }
      }

      if (feeStartDate > startDate && !fee.proRatedAmount) {
          continue; // skip this fee (fee amount = 0)
      }

      // case 4: fee encompases our timespan (implied)
      if (fee.proRatedAmount) {
          totalFees.proRatedAmount += fee.proRatedAmount;
      } else {
          totalFees.amount += fee.amount;
      }
      totalFees.fees.push(fee);
  }

  totalFees.total = totalFees.proRatedAmount + totalFees.amount;

  console.log(`Total ${feeName} fees for location ${location.practiceName}: `, totalFees);
  return totalFees;
}