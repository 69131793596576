import React from 'react';
import SEO from "../seo";
import CallLogReportDialog from './call-log-report-dialog';

import DiscrepancyReportDialog from "./discrepancy-report-dialog";
import ItemizedBillReportDialog from "./itemized-bill-report-dialog";
import ContributionMarginReportDialog from "./contribution-margin-report-dialog";
import ChargeTicketsReportDialog from './charge-tickets-report-dialog';
import CaseLogReportDialog from './case-log-report-dialog';
import KareoAppointmentsReportDialog from './kareo-appointments-report-dialog.js';
import KareoPatientsReportDialog from './kareo-patients-report-dialog';
import ComparisonReportDialog from './comparison-report-dialog.js';
import ReferringProviderReportDialog from './referring-provider-report-dialog.js';
import PermissionsProvider, { HPM_PERMISSIONS } from '../../providers/permissions-provider.js';
import { getUser } from '../../services/auth.js';
import ComplexityReportDialog from './complexity-report-dialog.js';


const Reports = () => {

    const user = getUser();

    return (
        <>
            <SEO title="Reports" />
            <h1>Reports</h1>
            <div style={{display: 'flex', flexWrap: 'wrap', gap: '16px'}}>
                {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.REPORTS_CHARGE_TICKETS) &&
                    <ChargeTicketsReportDialog></ChargeTicketsReportDialog>
                }
                 {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.REPORTS_DISCREPANCY) &&
                    <DiscrepancyReportDialog></DiscrepancyReportDialog>
                }
                {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.REPORTS_ITEMIZED_BILL) &&
                    <ItemizedBillReportDialog></ItemizedBillReportDialog>
                }
                {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.REPORTS_CALL_LOG) &&
                    <CallLogReportDialog></CallLogReportDialog>
                }
                {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.REPORTS_CMR) &&
                    <ContributionMarginReportDialog></ContributionMarginReportDialog>
                }
                {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.REPORTS_CASE_LOG) &&
                    <CaseLogReportDialog></CaseLogReportDialog>
                }
                {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.REPORTS_APPTS) &&
                    <KareoAppointmentsReportDialog></KareoAppointmentsReportDialog>
                }
                {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.REPORTS_PATIENTS) &&
                    <KareoPatientsReportDialog></KareoPatientsReportDialog>
                }
                {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.REPORTS_COMPARISON) &&
                    <ComparisonReportDialog></ComparisonReportDialog>
                }
                {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.REPORTS_REFERRED_PROVIDERS) &&
                    <ReferringProviderReportDialog></ReferringProviderReportDialog>
                }
                {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.REPORTS_ORDER_COMPLEXITY_PROVIDERS) &&
                    <ComplexityReportDialog></ComplexityReportDialog>
                }
                

            </div>

            {/* <br/>
            <div style={{display: 'flex'}}>

            </div>
            <br/>
            <div style={{display: 'flex'}}>

            </div> */}

        </>
    )
}

export default Reports
