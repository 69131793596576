import { LocationsUnitsProvider } from '../../utils/unitUtils';
import { useQuery } from 'react-apollo';
import { GET_USERS } from '../queries/queries';
import { getManyTickets } from './report-data-utils';

import React, { useState, useEffect } from "react"

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';


import { saveAs } from "file-saver";

import { monthFormatter, dateFormatter } from './report-utils';
import { getUser } from '../../services/auth';
import PermissionsProvider, { HPM_PERMISSIONS } from '../../providers/permissions-provider';


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        marginRight: 0
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
        flexGrow: 1,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    flexContainer: {
        display: 'flex'
    },
    flexItem: {
        width: '100%',
        flexGrow: 1,
        margin: 8,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));


function writeToTemplate(provider, rows) {
    let csvContent = rows.map(e => e.join(",")).join("\n");

    csvContent = csvContent.replace(/""/g, '');

    // console.log(`csvContent = `, csvContent);

    let csvData = new Blob([csvContent], { type: 'text/csv' });
    let csvUrl = URL.createObjectURL(csvData);
    let link = document.createElement("a");
    link.setAttribute("href", csvUrl);
    link.setAttribute("download", `HPM-Case-Log_${provider}_${(new Date()).toLocaleDateString('en-US')}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
}

export default function CaseLogReportDialog() {
    const [open, setOpen] = React.useState(false);
    const [isBuilding, setIsBuilding] = React.useState(false);
    const [trainingChecked, setTrainingChecked] = React.useState(true);
    const [selectedProvider, setSelectedProvider] = React.useState(false);

    const user = getUser();

    const users = useQuery(GET_USERS);
    const [providers, setProviders] = useState([]);

    const handleTrainingChange = (event) => {
        setTrainingChecked(event.target.checked);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleProviderChange = (event) => {
        setSelectedProvider(event.target.value);
    };

    const classes = useStyles();

    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    useEffect(() => {        
        if (!PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.REPORTS_CASE_LOG_PROVIDERS)) {
            setSelectedProvider(user);
        } else {
            getUsers();
        }
    }, []);

    async function getUsers() {
        try {
            let allUsers = await users.refetch();
            console.log(`all users = `, allUsers);
            let userList = allUsers.data.users.users
                .filter(user => user.roles[0].id !== '1') // && user.roles[0].id !== '4')
                .sort((a, b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0));
            // .map(user => {
            //     return { value: `${user.firstName} ${user.lastName}`, label: `${user.firstName} ${user.lastName}` };
            // });
            // userList = userList.filter(x => x.value !== null);
            setProviders(userList);
        } catch (error) {
            console.log(error);
        }
    }

    async function buildReport() {
        setIsBuilding(true);

        await LocationsUnitsProvider.refreshData();

        console.log(`Building Report...`);

        selectedStartDate.setHours(0, 0, 0, 1);
        selectedEndDate.setHours(23, 59, 59, 0);

        // adjust query time to UTC offset, ticket dates are all UTC @ midnight w.r.t. TZ where they were created
        var offsetTime = new Date(selectedStartDate.getTime() - selectedStartDate.getTimezoneOffset() * 60 * 1000);

        console.log(`START DATE = ${selectedStartDate}`);
        console.log(`EFEECTIVE START DATE = ${offsetTime}`);
        console.log(`END DATE = ${selectedEndDate}`);

        let reportData
        try {
            let chargeTickets = await getManyTickets(offsetTime, selectedEndDate);
            reportData = await buildReportData(chargeTickets);
            // console.log(`reportData = `, reportData);
        } catch (e) {
            setIsBuilding(false);
            throw e;
        }

        if (reportData.length) {
            console.log(`Writing report data to file...`);
            try {
                writeToTemplate(`${selectedProvider.firstName}-${selectedProvider.lastName}`, reportData);
            } catch (e) {
                setIsBuilding(false);
                throw e;
            }

            console.log(`Done!`);

            setIsBuilding(false);
            handleClose();
        } else {
            setIsBuilding(false);
        }
    }

    function buildReportData(tickets) {

        console.log(`Building report for (${tickets.length}) charge tickets...`);

        console.log(`all tickets = `, tickets);
        let chargeTickets = tickets.filter(x => `${x.user.id}` === `${selectedProvider.id}`);
        console.log(`used tickets for userId ${selectedProvider.id} = `, chargeTickets);

        if (!chargeTickets.length) { alert('No cases to report'); return []; }

        if (!trainingChecked) {
            // console.log(`charge tickets (${chargeTickets.length}) =`, chargeTickets);
            chargeTickets = chargeTickets.filter(x => !x.location.toLowerCase().includes('training') && !x.patientName.toLowerCase().includes('training') && !x.location.toLowerCase().includes('test'));
            // console.log(`charge tickets without training data (${chargeTickets.length}) =`, chargeTickets);
        }

        let csv = [];
        csv.push([
            'HPM ID', 'Procedure Date', 'Provider',
            'CPT 1', 'CPT 2', 'CPT 3', 'CPT 4', 'CPT 5', 'CPT 6', 'CPT 7', 'CPT 8',
            'CPT Description 1', 'CPT Description 2', 'CPT Description 3', 'CPT Description 4', 'CPT Description 5', 'CPT Description 6', 'CPT Description 7', 'CPT Description 8',
        ]);

        if (trainingChecked) {
            csv[0].push('Trainer');
            csv[0].push('Student Type');
        }

        chargeTickets.map((chargeTicket => {
            let row = []
            let codes = new Array(8).fill('');
            let cptDescriptions = new Array(8).fill('');
            const isClinic = JSON.stringify(chargeTicket.chargeticketcpt).includes("\"code\":\"99");

            chargeTicket.chargeticketcpt.map((chargeTicketCpt) => {

                if (!isClinic) {
                    codes[chargeTicketCpt.sequence - 1] = chargeTicketCpt.cpt.code;
                    cptDescriptions[chargeTicketCpt.sequence - 1] = chargeTicketCpt.cpt.description;
                }

                else if (chargeTicketCpt.cpt.code.startsWith("99")) {
                    // clinic visits should only have one "MAIN" charge code
                    codes[0] = chargeTicketCpt.cpt.code;
                    cptDescriptions[0] = chargeTicketCpt.cpt.description;
                }

                return true;
            });

            row.push(chargeTicket.id);
            row.push(dateFormatter.format(new Date(chargeTicket.date)));
            row.push(chargeTicket.user.firstName + " " + chargeTicket.user.lastName);
            row.push(codes.join(','));
            row.push(cptDescriptions.join(','));

            if (trainingChecked) {
                row.push(`${chargeTicket.trainer ? chargeTicket.trainer : ''}`);
                row.push(`${chargeTicket.studentType ? chargeTicket.studentType : ''}`);
            }

            csv.push(row);

            return true;
        }));

        return csv;
    }


    if (isBuilding) {
        return (
            <div>
                <Card className={classes.root}>
                    <CardActionArea onClick={handleClickOpen}>
                        <img src={require('../../images/Case-Log-Report.png').default} />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Case Log
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Provides the cases for a provider over some date range.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>


                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Building Case Log Report</DialogTitle>
                    <DialogContent>
                        <div className={classes.flexCenter}>
                            <CircularProgress />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

    return (
        <div>
            <Card className={classes.root}>
                <CardActionArea onClick={handleClickOpen}>
                    <img src={require('../../images/Case-Log-Report.png').default} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Case Log
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Provides the cases for a provider over some date range.
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create Case Log Report</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The Case Log report shows all of the cases for a provider over a specified time.
                    </DialogContentText>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={trainingChecked}
                            onChange={handleTrainingChange}
                        />}
                            label="Include Training Tickets?" />

                        {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.REPORTS_CASE_LOG_PROVIDERS) &&
                            <FormControl required error={!selectedProvider}>
                                <InputLabel id="demo-simple-select-label">Provider</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedProvider}
                                    onChange={handleProviderChange}
                                >
                                    {
                                        providers.map(provider => <MenuItem key={provider.id} value={provider}>{provider.firstName} {provider.lastName}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        }

                    </FormGroup>

                    <div className={classes.flexContainer}>
                        <div className={classes.flexItem}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Start Date"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={classes.flexItem}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.flexItem}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="End Date"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => buildReport()}
                        disabled={!selectedProvider}
                        color="primary" variant="contained">
                        Build Report
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
