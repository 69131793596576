import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    if (!isLoggedIn() && location.pathname !== `/app/login`) {
        const params = new URLSearchParams(window.location.search);
        const override = params.get('override');
        navigate(override ? `/app/login?override=${override}` : `/app/login`);
        return null
    }

    return <Component {...rest} />
}

export default PrivateRoute
