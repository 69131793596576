const notificationSettings = [
    {
        id: 1,
        group: 'RFA Cervical/Thoracic Session',
        baseCpts: [
            '64620',
            '64633',
            '64634'
        ],
        description: `Any procedure encounter with these base CPT codes with/without modifiers counts as a session for cervical/thoracic (e.g., one 64620 and one 64633 within 12 months would use up that patient’s RFAs in the cervical/thoracic region until at least 12 months from the initial session). Note: one procedure visit may contain multiple CPT codes - should only count as 1 session`,
        cadence: 365, // 1 year
        count: 2,
        rolesToNotify: [
            {
                role: 'Clinic',
                messages: [
                    {
                        countThresh: 1,
                        message: 'Reminder: this will be the second Cervical/Thoracic RFA session for this patient in 12 months.'
                    },
                    {
                        countThresh: 2,
                        message: 'Warning: another Cervical/Thoracic RFA session should not be scheduled until',
                        appendDate: true
                    }
                ]
            },
            {
                role: 'Procedure',
                messages: [
                    {
                        countThresh: 1,
                        message: 'Reminder: this patient will meet their max number of Cervical/Thoracic RFA sessions this year.'
                    },
                    {
                        countThresh: 2,
                        message: 'ERROR: too many Cervical/Thoracic RFA sessions in a 12 month span. Please contact HIM department at HPM.'
                    }
                ]
            },
            {
                role: 'Nursing',
                messages: [
                    {
                        countThresh: 1,
                        message: 'Reminder: this will be the second Cervical/Thoracic RFA session for this patient in 12 months.'
                    },
                    {
                        countThresh: 2,
                        message: 'Warning: this will be the third Cervical/Thoracic RFA session for this patient in 12 months.'
                    }
                ]
            }
        ],
        notificationMessage: 'Warning: this will be the third Cervical/Thoracic RFA session for this patient in 12 months.'
    },
    {
        id: 2,
        group: 'RFA Lumbar/Sacral Session',
        baseCpts: [
            '64625',
            '64635',
            '64636'
        ],
        description: `Any procedure encounter with these base CPT codes with/without modifiers counts as a session for lumbar/sacral (e.g., one 64625 and one 64635 within 12 months would use up that patient’s RFAs in the cervical/thoracic region until at least 12 months from the initial session). Note: one procedure visit may contain multiple CPT codes - should only count as 1 session`,
        cadence: 365, // 1 year
        count: 2,
        rolesToNotify: [
            {
                role: 'Clinic',
                messages: [
                    {
                        countThresh: 1,
                        message: 'Reminder: this will be the second Lumbar/Sacral RFA session for this patient in 12 months.'
                    },
                    {
                        countThresh: 2,
                        message: 'Warning: another Lumbar/Sacral RFA session should not be scheduled until',
                        appendDate: true
                    }
                ]
            },
            {
                role: 'Procedure',
                messages: [
                    {
                        countThresh: 1,
                        message: 'Reminder: this patient will not have any more Lumbar/Sacral RFA sessions left after this session.'
                    },
                    {
                        countThresh: 2,
                        message: 'ERROR: too many Lumbar/Sacral RFA sessions in a 12 month span. Please contact HIM department at HPM.'
                    }
                ]
            },
            {
                role: 'Nursing',
                messages: [
                    {
                        countThresh: 1,
                        message: 'Reminder: this will be the second Lumbar/Sacral RFA session for this patient in 12 months.'
                    },
                    {
                        countThresh: 2,
                        message: 'Warning: this will be the third Lumbar/Sacral RFA session for this patient in 12 months.'
                    }
                ]
            }
        ],
        notificationMessage: 'Warning: this will be the third Lumbar/Sacral RFA session for this patient in 12 months.'
    },
    {
        id: 3,
        group: 'Epidural Injection Session',
        baseCpts: [
            '62321',
            '62323',
            '64479',
            '64480',
            '64483',
            '64484'
        ],
        description: `Any procedure encounter with these base CPT codes with/without modifiers counts as a session for epidural injection (e.g., any combination of four within 12 months would use up that patient’s RFAs in that spinal region until at least 12 months from the initial session). Note: one procedure visit may contain multiple CPT codes - should only count as 1 session`,
        cadence: 365, // 1 year
        count: 4,
        rolesToNotify: [
            {
                role: 'Clinic',
                messages: [
                    {
                        countThresh: 1,
                        message: 'Reminder: this will be the second Epidural Injection session for this patient in 12 months.'
                    },
                    {
                        countThresh: 2,
                        message: 'Reminder: this will be the third Epidural Injection session for this patient in 12 months.'
                    },
                    {
                        countThresh: 3,
                        message: 'Reminder: this will be the fourth Epidural Injection session for this patient in 12 months.'
                    },
                    {
                        countThresh: 4,
                        message: 'Warning: another Epidural Injection session should not be scheduled until',
                        appendDate: true
                    }
                ]
            },
            {
                role: 'Procedure',
                messages: [
                    {
                        countThresh: 1,
                        message: 'Reminder: this will be the second Epidural Injection session for this patient in 12 months.'
                    },
                    {
                        countThresh: 2,
                        message: 'Reminder: this will be the third Epidural Injection session for this patient in 12 months.'
                    },
                    {
                        countThresh: 3,
                        message: 'Reminder: this patient will not have any more Epidural Injection sessions left after this session.'
                    },
                    {
                        countThresh: 4,
                        message: 'ERROR: too many Epidural Injection sessions in a 12 month span. Please contact HIM department at HPM.'
                    }
                ]
            },
            {
                role: 'Nursing',
                messages: [
                    {
                        countThresh: 1,
                        message: 'Reminder: this will be the second Epidural Injection session for this patient in 12 months.'
                    },
                    {
                        countThresh: 2,
                        message: 'Reminder: this will be the third Epidural Injection session for this patient in 12 months.'
                    },
                    {
                        countThresh: 3,
                        message: 'Reminder: this will be the fourth Epidural Injection session for this patient in 12 months.'
                    },
                    {
                        countThresh: 4,
                        message: 'Reminder: this will be the fifth Epidural Injection session for this patient in 12 months.'
                    }
                ]
            }
        ],
        notificationMessage: 'Warning: this will be the fifth Epidural Injection session for this patient in 12 months.'
    },
];

export const checkForNotifications = (tickets, role) => {
    // get all possible warnings PER GROUP based on CPTs in previous tickets for a patient

    let groupNotifications = {};

    notificationSettings.forEach(group => {

        groupNotifications[group.group] = {
            name: group.group,
            baseCpts: group.baseCpts,
            matchingCodes: [],
            tickets: [],
            patientName: 'n/a',
            nextDate: null,
            message: group.notificationMessage,
            cadence: group.cadence,
            count: group.count,
            groupData: group,
            total: 0
        };

        for (let ticket of tickets) { // for each ticket
            let firstCpt = ticket.chargeticketcpt.find(tk => tk.sequence === 1);
            if (firstCpt && firstCpt.cpt && !firstCpt.cpt.code.startsWith('99')) { // if ticket is from clinic, skip it (only procedures count against RFA sessions)

                for (let x of ticket.chargeticketcpt) { // for each ctCpt
                    for (let cptCode of group.baseCpts) { // for each cpt in the group
                        if (x.cpt.code.startsWith(cptCode)) {
                            console.log(`Found Match! ${x.cpt.code} => ${cptCode}`);
                            groupNotifications[group.group].matchingCodes.push(x.cpt.code);

                            let existingTicket = groupNotifications[group.group].tickets.find(t => t.ticket.id === ticket.id);
                            // console.log(`existingTicket => `, existingTicket);
                            if (existingTicket) {
                                existingTicket.codes.push(x.cpt.code);
                            } else {
                                groupNotifications[group.group].tickets.push({
                                    codes: [x.cpt.code],
                                    date: new Date(`${ticket.date}`),
                                    ticket: ticket
                                });
                            }
                        }
                    }
                }
            }
        }

        // select the oldest date as the starting point
        if (groupNotifications[group.group].tickets.length) {
            let allDates = groupNotifications[group.group].tickets.map(x => new Date(`${x.ticket.date}`));
            allDates.sort((a, b) => (+b.date - +a.date)); // sort dates with oldest first
            console.log(`Dates = `, allDates);
            let nextDate = new Date(allDates[0].setDate(allDates[0].getDate() + group.cadence + 1));

            // handle leap year
            const leapYearDate = new Date(nextDate.getFullYear(), 1, 29);
            if (leapYearDate.getMonth() === 1) {
                console.log(`Handling leap year...`);
                let tempDate = new Date(`${nextDate}`);
                nextDate = new Date(tempDate.setDate(tempDate.getDate() + 1));
            }

            groupNotifications[group.group].nextDate = nextDate;
        }
    });

    // console.log(`Group Notifications = `, groupNotifications);
    // return groupNotifications;

    let results = [];
    for (let notification of Object.values(groupNotifications)) {
        let roleData = notification.groupData.rolesToNotify.find(x => x.role === role);
        console.log(`ROLE DATA = `, roleData);

        notification.total = notification.tickets.length;

        // choose which messaging to show based on count
        roleData.messages.forEach(msg => {
            if (notification.total === msg.countThresh) {
                console.log(`  using warning message: `, msg);
                notification.message = msg.message;

                if (msg && msg.appendDate) {
                    notification.message += ` ${notification.nextDate.toLocaleDateString("en-US")}`;
                }
            }
        });

        results.push(notification);
    }

    console.log(`Group Notification results = `, results);

    return Object.values(results);
}
