import ReportDataProvider from './report-data-provider';

import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';

import { dateFormatter, getDay } from './report-utils';
import { ChargeClient } from '../../providers/charge-client';
import { groupBy } from '../../utils/arrayUtils';
import { ComparisonUnitsProvider } from '../../utils/comparisonUnitsProvider';
import { LocationsUnitsProvider } from '../../utils/unitUtils';

import { useQuery } from 'react-apollo';
import { GET_USERS } from '../queries/queries';


const comparisonType = [
    'Facilities',
    'Providers',
];

const tiers = [
    'Default',
    'Tier1',
    'Tier2',
    'Tier3',
    'Tier4',
    'Tier5'
];

const chargeCaps = [
    10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    100
];

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        marginRight: 0
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
        flexGrow: 1,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    flexContainer: {
        display: 'flex'
    },
    flexItem: {
        width: '100%',
        flexGrow: 1,
        margin: 8,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

function writeToTemplate(rows, type) {
    let csvContent = rows.map(e => e.join(",")).join("\n");

    csvContent = csvContent.replace(/""/g, '');

    let csvData = new Blob([csvContent], { type: 'text/csv' });
    let csvUrl = URL.createObjectURL(csvData);
    let link = document.createElement("a");
    link.setAttribute("href", csvUrl);
    link.setAttribute("download", `HPM-${type}-Comparison-Report_${(new Date()).toLocaleDateString('en-US')}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
}

// const chargeClient = new ChargeClient();

export default function ComparisonReportDialog() {
    const [open, setOpen] = React.useState(false);
    const [isBuilding, setIsBuilding] = React.useState(false);

    // const provider = ReportDataProvider();

    const usersQuery = useQuery(GET_USERS);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();

    const [comparisonTypeOption, setComparisonTypeOption] = useState(comparisonType[0]);
    const handleComparisonTypeSelected = (event) => {
        setComparisonTypeOption(event.target.value);
    };

    const [tierOption, setTierOption] = useState(tiers[0]);
    const handleTierSelected = (event) => {
        setTierOption(event.target.value);
    };

    // const [ignoreChargeCapChecked, setIgnoreChargeCapChecked] = useState(true);
    // const handleIgnoreChargeCapChange = (event) => {
    //     setIgnoreChargeCapChecked(event.target.checked);
    // };

    const [selectedChargeCap, setSelectedChargeCap] = useState(40);
    const handleSelectedChargeCapChange = (event) => {
        setSelectedChargeCap(parseInt(`${event.target.value}`));
    };

    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    const [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers();
    }, []);

    async function getUsers() {
        try {
            let allUsers = await usersQuery.refetch();
            console.log(`all users = `, allUsers);
            let userList = allUsers.data.users.users
                .filter(user => user.roles[0].id !== '1') // && user.roles[0].id !== '4')
                .sort((a, b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0));
            // .map(user => {
            //     return { value: `${user.firstName} ${user.lastName}`, label: `${user.firstName} ${user.lastName}` };
            // });
            // userList = userList.filter(x => x.value !== null);
            setUsers(userList);
        } catch (error) {
            console.error(error);
        }
    }

    async function buildReport() {
        setIsBuilding(true);
        console.log(`Building Report...`);

        await LocationsUnitsProvider.refreshData();

        selectedStartDate.setHours(0, 0, 0, 1);
        selectedEndDate.setHours(11, 59, 59, 0);

        // adjust query time to UTC offset, ticket dates are all UTC @ midnight w.r.t. TZ where they were created
        var offsetStartTime = new Date(selectedStartDate.getTime() - selectedStartDate.getTimezoneOffset() * 60 * 1000);
        // var offsetEndTime = new Date(selectedEndDate.getTime() + selectedEndDate.getTimezoneOffset() * 60 * 1000);

        console.log(`START DATE = ${selectedStartDate}`);
        console.log(`EFEECTIVE START DATE = ${offsetStartTime}`);
        console.log(`END DATE = ${selectedEndDate}`);

        let chargeClient = new ChargeClient();

        try {

            let filter = {
                take: 1000000,
                startDate: offsetStartTime,
                endDate: selectedEndDate
            };

            // get charge tickets for date range
            const tickets = await chargeClient.getTickets(filter);

            // compute values for data based on type
            const items = await buildData(comparisonTypeOption, tickets, selectedStartDate, selectedEndDate);

            // build the CSV data
            let reportData = buildReportData(items);

            writeToTemplate(reportData, comparisonTypeOption);
            console.log(`Done!`);
            setIsBuilding(false);
            handleClose();

        } catch (error) {
            setIsBuilding(false);
            handleClose();
            throw error;
        }
    }

    async function buildData(outputType, tickets, startDate, endDate) {

        let result = [];

        // ComparisonUnitsProvider
        await ComparisonUnitsProvider.refreshData();

        const fitleredTickets = tickets.filter(x =>
            !x.location.toLowerCase().includes('test')
            && !x.location.toLowerCase().includes('training'));

        // compute the total number of days between the start and end dates
        const totalDays = Math.round((new Date(`${endDate}`) - new Date(`${startDate}`)) / (1000 * 60 * 60 * 24));

        // group tickets by facility (ticket.location) OR by provider (ticket.userId)
        const group = outputType === 'Facilities' ? groupBy(fitleredTickets, 'location') : groupBy(tickets, 'userId');


        const ticketsGroups = Object.entries(group).map(([key, data]) => {
            const user = users.find(x => `${x.id}` === `${key}`);
            // if (user) { console.log(`user found => `, user.firstName); }
            return {
                name: outputType === 'Facilities' ? key : user ? `${user.firstName} ${user.lastName}` : key,
                tickets: data
            };
        });
        // console.log(`ticketsGroups = `, ticketsGroups);

        for (let ticketGroup of ticketsGroups) {

            if (`${ticketGroup.name}` === '31' || `${ticketGroup.name}` === '2') { continue; }

            let ticketGroupResult = {
                name: ticketGroup.name,
                startDate: new Date(`${startDate}`),
                endDate: new Date(`${endDate}`),
                absoluteTotalDays: totalDays,

                clinicPatientsPerDay: 0,
                clinicUnitsPerDay: 0,
                clinicUnitsPerPatient: 0,

                procedurePatientsPerDay: 0,
                procedureUnitsPerDay: 0,
                procedureUnitsPerPatient: 0,

                // patientsPerDay: 0,
                // unitsPerDay: 0,
                // unitsPerPatient: 0,

                totalClinicUnits: 0,
                totalProcedureUnits: 0,
                totalUnits: 0,

                totalClinicPatients: 0,
                totalProcedurePatients: 0,
                totalPatients: 0,

                totalClinicDays: 0,
                totalProcedureDays: 0,
                totalDays: 0,
            }

            // split tickets into clinic and procedures...
            // CLINIC
            computeClinicData(ticketGroupResult, ticketGroup, outputType);

            // PROCEDURE
            computeProcedureData(ticketGroupResult, ticketGroup, outputType);

            // TOTALS
            computeTotalsData(ticketGroupResult, ticketGroup);

            // result.push({
            //     name: ticketGroup.name,
            //     patientsPerDay: patientsPerDay,
            //     unitsPerDay: unitsPerDay,
            //     unitsPerPatient: unitsPerPatient,
            // });

            console.log(`ticketGroupResult = `, ticketGroupResult);

            for (let key of Object.keys(ticketGroupResult)) {
                if (key !== 'name' && isNaN(ticketGroupResult[`${key}`])) { ticketGroupResult[`${key}`] = 0; }
            }

            result.push(ticketGroupResult);
        }

        // result = result.sort(function (a, b) {
        //     return a.name.localeCompare(b.name, undefined, {
        //         numeric: true,
        //         sensitivity: 'base'
        //     });
        // });

        return result;
    }

    function computeClinicData(ticketGroupResult, ticketGroup, groupType) {

        let clinicTickets = ticketGroup.tickets.filter(x =>
            JSON.stringify(x.chargeticketcpt).includes("\"code\":\"99")
            && !x.patientName.toLowerCase().includes('training'));

        // compute the total patient count
        ticketGroupResult.totalClinicPatients = clinicTickets.length;
        console.log(`clinicTickets = `, clinicTickets);

        // get unique dates (total days)
        let dateKeys;
        if (groupType === comparisonType[1]) { // 'Providers') {
            dateKeys = clinicTickets.map(x => getDay(new Date(`${x.date}`), '-'));
            console.log(`date keys: `, dateKeys);
            dateKeys = dateKeys.filter((value, index, array) => array.indexOf(value) === index);
            console.log(`${ticketGroupResult.name} unique date keys: `, dateKeys);
            ticketGroupResult.totalClinicDays = dateKeys.length;
        } else {
            dateKeys = clinicTickets.map(x => `${x.user.firstName}_${x.user.lastName}_` + getDay(new Date(`${x.date}`), '-'));
            console.log(`date keys: `, dateKeys);
            dateKeys = dateKeys.filter((value, index, array) => array.indexOf(value) === index);
            console.log(`${ticketGroupResult.name} unique date keys: `, dateKeys);
            ticketGroupResult.totalClinicDays = dateKeys.length;
        }


        // compute the total unit count for clinic tickets
        let totalUnits = 0;
        for (let chargeTicket of clinicTickets) {
            const isClinic = JSON.stringify(chargeTicket.chargeticketcpt).includes("\"code\":\"99");
            const providerType = `${chargeTicket.user.role[0].id}` === '3' ? "CRNA" : "PA";
            let ticketTotal = 0;
            chargeTicket.chargeticketcpt.map((chargeTicketCpt) => {
                const cptUnits = ComparisonUnitsProvider.getUnits(
                    chargeTicket,
                    chargeTicketCpt,
                    providerType,
                    chargeTicket.location,
                    isClinic,
                    tierOption,
                    selectedChargeCap
                );

                ticketTotal += cptUnits;

                if (chargeTicket.location === 'Ellsworth') {
                    console.log(`[Ellsworth] :: CLINIC cptUnits = ${cptUnits} | totalUnits = ${totalUnits}`);
                }
            });

            // apply custom unit cap
            if (ticketTotal > selectedChargeCap) {
                ticketTotal = selectedChargeCap;
            }

            totalUnits += ticketTotal;

        }

        ticketGroupResult.totalClinicUnits = totalUnits;

        ticketGroupResult.clinicPatientsPerDay = ticketGroupResult.totalClinicPatients / ticketGroupResult.totalClinicDays;
        ticketGroupResult.clinicUnitsPerDay = ticketGroupResult.totalClinicUnits / ticketGroupResult.totalClinicDays;
        ticketGroupResult.clinicUnitsPerPatient = ticketGroupResult.totalClinicUnits / ticketGroupResult.totalClinicPatients;
    }

    function computeProcedureData(ticketGroupResult, ticketGroup, groupType) {

        let procedureTickets = ticketGroup.tickets.filter(x =>
            !JSON.stringify(x.chargeticketcpt).includes("\"code\":\"99")
            && !x.patientName.toLowerCase().includes('training')
            // && !(x.userId === 18 || x.userId === 40)
        );

        // need to split out the calcs for days to exclude Daren Badura (he does Clinic and Procedures on same days)
        const procedureTicketDays = procedureTickets.filter(x =>
            !(`${x.userId}` === '18' || `${x.userId}` === '40')
        );

        // compute the total patient count
        ticketGroupResult.totalProcedurePatients = procedureTickets.length;
        console.log(`${ticketGroupResult.name} procedureTickets = `, procedureTickets);

        // get unique dates (total days)
        let dateKeys;
        if (groupType === comparisonType[1]) { // 'Providers') {
            dateKeys = procedureTicketDays.map(x => getDay(new Date(`${x.date}`), '-'));
            console.log(`date keys: `, dateKeys);
            dateKeys = dateKeys.filter((value, index, array) => array.indexOf(value) === index);
            console.log(`${ticketGroupResult.name} unique date keys: `, dateKeys);
            ticketGroupResult.totalProcedureDays = dateKeys.length;
        } else {
            dateKeys = procedureTicketDays.map(x => `${x.user.firstName}_${x.user.lastName}_` + getDay(new Date(`${x.date}`), '-'));
            console.log(`date keys: `, dateKeys);
            dateKeys = dateKeys.filter((value, index, array) => array.indexOf(value) === index);
            console.log(`${ticketGroupResult.name} unique date keys: `, dateKeys);
            ticketGroupResult.totalProcedureDays = dateKeys.length;
        }

        // compute the total unit count for clinic tickets
        let totalUnits = 0;
        for (let chargeTicket of procedureTickets) {
            const isClinic = JSON.stringify(chargeTicket.chargeticketcpt).includes("\"code\":\"99");
            const providerType = `${chargeTicket.user.role[0].id}` === '3' ? "CRNA" : "PA";
            let ticketTotal = 0;
            chargeTicket.chargeticketcpt.map((chargeTicketCpt) => {
                const cptUnits = ComparisonUnitsProvider.getUnits(
                    chargeTicket,
                    chargeTicketCpt,
                    providerType,
                    chargeTicket.location,
                    isClinic,
                    tierOption,
                    selectedChargeCap
                );

                // const cptUnits = LocationsUnitsProvider.getUnits(
                //     chargeTicket,
                //     chargeTicketCpt,
                //     providerType,
                //     chargeTicket.location,
                //     isClinic
                // );

                ticketTotal += cptUnits;

                if (chargeTicket.location === 'Ellsworth') {
                    console.log(`[Ellsworth] :: PROCEDURE cptUnits = ${cptUnits} | totalUnits = ${totalUnits}`);
                }
            });

            // apply custom unit cap
            if (ticketTotal > selectedChargeCap) {
                ticketTotal = selectedChargeCap;
            }

            totalUnits += ticketTotal;
        }



        ticketGroupResult.totalProcedureUnits = totalUnits;

        ticketGroupResult.procedurePatientsPerDay = ticketGroupResult.totalProcedurePatients / ticketGroupResult.totalProcedureDays;
        ticketGroupResult.procedureUnitsPerDay = ticketGroupResult.totalProcedureUnits / ticketGroupResult.totalProcedureDays;
        ticketGroupResult.procedureUnitsPerPatient = ticketGroupResult.totalProcedureUnits / ticketGroupResult.totalProcedurePatients;

    }

    function computeTotalsData(ticketGroupResult, ticketGroup) {
        // totals
        ticketGroupResult.totalDays = ticketGroupResult.totalClinicDays + ticketGroupResult.totalProcedureDays;
        ticketGroupResult.totalPatients = ticketGroupResult.totalClinicPatients + ticketGroupResult.totalProcedurePatients;
        ticketGroupResult.totalUnits = ticketGroupResult.totalClinicUnits + ticketGroupResult.totalProcedureUnits;

        // // averages
        // ticketGroupResult.patientsPerDay = ticketGroupResult.totalPatients / ticketGroupResult.totalDays;
        // ticketGroupResult.unitsPerDay = ticketGroupResult.totalUnits / ticketGroupResult.totalDays;
        // ticketGroupResult.unitsPerPatient = ticketGroupResult.totalUnits / ticketGroupResult.totalPatients;
    }

    function buildReportData(data) {

        console.log(`Building report for (${data.length}) ${comparisonTypeOption}...`);

        const csv = [];

        // set up header row
        csv.push([
            comparisonTypeOption === 'Facilities' ? 'Facility' : "Provider",

            "Clinic Patients per Day",
            "Clinic Units per Day",
            "Clinic Units per Patient",

            "Procedure Patients per Day",
            "Procedure Units per Day",
            "Procedure Units per Patient",

            // "Patients per Day",
            // "Units per Day",
            // "Units per Patient",

            "Total Clinic Units",
            "Total Proc Units",
            "Total Units",

            "Total Clinic Patients",
            "Total Procedure Patients",
            "Total Patients",

            "Total Clinic Days",
            "Total Procedure Days",
            "Total Days",
        ]);

        data = data.sort(function (a, b) {
            return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base'
            });
        });

        data.forEach((item) => {
            const row = [];

            row.push(`"${item.name}"`);

            row.push(Math.round(item.clinicPatientsPerDay * 10) / 10);
            row.push(Math.round(item.clinicUnitsPerDay * 10) / 10);
            row.push(Math.round(item.clinicUnitsPerPatient * 10) / 10);
            row.push(Math.round(item.procedurePatientsPerDay * 10) / 10);
            row.push(Math.round(item.procedureUnitsPerDay * 10) / 10);
            row.push(Math.round(item.procedureUnitsPerPatient * 10) / 10);
            // row.push(Math.round(item.patientsPerDay * 10) / 10);
            // row.push(Math.round(item.unitsPerDay * 10) / 10);
            // row.push(Math.round(item.unitsPerPatient * 10) / 10);
            row.push(Math.round(item.totalClinicUnits * 10) / 10);
            row.push(Math.round(item.totalProcedureUnits * 10) / 10);
            row.push(Math.round(item.totalUnits * 10) / 10);
            row.push(item.totalClinicPatients);
            row.push(item.totalProcedurePatients);
            row.push(item.totalPatients);
            row.push(item.totalClinicDays);
            row.push(item.totalProcedureDays);
            row.push(item.totalDays);

            // row.push(Math.round(item.patientsPerDay * 10) / 10);
            // row.push(Math.round(item.unitsPerDay * 10) / 10);
            // row.push(Math.round(item.unitsPerPatient * 10) / 10);

            // for (let r of row) {
            //     if (isNaN(r)) { r = 0; }
            // }

            csv.push(row);
        });

        console.log(`CSV OUT = `, csv);

        return csv;
    }

    if (isBuilding) {
        return (
            <div>
                <Card className={classes.root}>
                    <CardActionArea onClick={handleClickOpen}>
                        <img src={require('../../images/Comparison-Report.png').default} />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Comparison Report
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Compare metrics on facilities and providers.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>


                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Building Comparison Report</DialogTitle>
                    <DialogContent>
                        <div className={classes.flexCenter}>
                            <CircularProgress />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

    return (
        <div>
            <Card className={classes.root}>
                <CardActionArea onClick={handleClickOpen}>
                    <img src={require('../../images/Comparison-Report.png').default} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Comparison Report
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Compare metrics on facilities and providers.
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create Comparison Report</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The Comparison report shows metrics for facilities or providers over a specified time.
                    </DialogContentText>
                    <div className={classes.flexContainer}>
                        <FormControl className={classes.formControl}>
                            <InputLabel>Comparison Type</InputLabel>
                            <Select value={comparisonTypeOption} onChange={handleComparisonTypeSelected}
                            >
                                {
                                    comparisonType.map((compType, i) => <MenuItem value={compType}>{compType}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.flexContainer}>
                        <FormControl className={classes.formControl}>
                            <InputLabel>CPT Tier</InputLabel>
                            <Select value={tierOption} onChange={handleTierSelected}
                            >
                                {
                                    tiers.map((tier, i) => <MenuItem value={tier}>{tier}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.flexContainer}>
                        <FormControl className={classes.formControl}>
                            <InputLabel>Charge Cap</InputLabel>
                            <Select value={selectedChargeCap} onChange={handleSelectedChargeCapChange}
                            >
                                {
                                    chargeCaps.map((chargeCap, i) => <MenuItem value={chargeCap}>{chargeCap}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </div>
                    {/* <div className={classes.flexContainer}>
                        <div className={classes.flexItem}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox
                                    checked={ignoreChargeCapChecked}
                                    onChange={handleIgnoreChargeCapChange}
                                />}
                                    label="Ignore Charge Cap?" />
                            </FormGroup>
                        </div>
                    </div> */}
                    <div className={classes.flexContainer}>
                        <div className={classes.flexItem}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Start Date"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={classes.flexItem}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.flexItem}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="End Date"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => buildReport()} color="primary" variant="contained">
                        Build Report
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
