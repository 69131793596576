import React, { Component } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const ContractInput = React.forwardRef((props, ref) => (
	<input aria-label="contract" className={"truncate text-center inline-block rounded-full px-2 py-1 text-sm w-full text-gray-700" + (props.value ? " bg-green-200" : " bg-red-200")} onChange={(e) => e.preventDefault()} onClick={props.onClick} value={props.value ? "Signed" : "Not Signed"} />
))

const StartDateInput = React.forwardRef((props, ref) => (
	<input aria-label="startDate" className="truncate text-center inline-block rounded-full px-2 py-1 text-sm w-full text-gray-700" onChange={(e) => e.preventDefault()} onClick={props.onClick} value={props.value ? props.value : ''} />
))

export default class Card extends Component {

	handleChange = (e) => {
		e.preventDefault()
		let value = e.target.value
		let updatedFacility = this.props.facility
		delete updatedFacility["__typename"]
		updatedFacility[e.target.name] = (value === "true" ? true : (value === "false" ? false : value))
		this.props.updateFacility({ variables: { facility: updatedFacility } })
		if (e.target.name !== "unitRate" && e.target.name !== "notes") {
			e.target.blur()
		}
	}

	updateContract = (date) => {
		let updatedFacility = this.props.facility
		updatedFacility["contract"] = date
		delete updatedFacility["__typename"]
		this.props.updateFacility({ variables: { facility: updatedFacility } })
	}

	updateStartDate = (date) => {
		let updatedFacility = this.props.facility
		updatedFacility["startDate"] = date
		delete updatedFacility["__typename"]
		this.props.updateFacility({ variables: { facility: updatedFacility } })
	}

	render = () => {
		return (
			<Draggable draggableId={this.props.facility.id} index={this.props.facility.sequence}>
				{provided => (
					<div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className="flex bg-white border-solid border-t border-gray-200 mt-4 max-w-sm rounded overflow-hidden w-full lg:max-w-full lg:flex shadow">
						<div className="grid grid-cols-11 gap-4 px-4 py-4">
							<button onClick={() => this.props.setSelectedFacility(this.props.facility)}>
								{this.props.facility.sequence}. {this.props.facility.name}
							</button>
							<div>
								<select style={{ textAlignLast: "center" }} name="status" value={this.props.facility.status} onBlur={null} onChange={(e) => this.handleChange(e)} className={"truncate text-center inline-block rounded-full px-2 py-1 text-sm text-gray-700" + (this.props.facility.status === "Completed" ? " bg-green-200" : (this.props.facility.status === "Paused" ? " bg-yellow-200" : " bg-blue-200"))}>
									<option key="1" value="Researching">Researching</option>
									<option key="2" value="Contacted">Contacted</option>
									<option key="3" value="Paused">Paused</option>
									<option key="4" value="Negotiating">Negotiating</option>
									<option key="5" value="Onboarding">Onboarding</option>
									<option key="6" value="Completed">Completed</option>
								</select>
							</div>
							<div>
								<select style={{ textAlignLast: "center" }} name="nda" value={this.props.facility.nda} onBlur={null} onChange={(e) => this.handleChange(e)} className={"truncate text-center inline-block rounded-full px-2 py-1 text-sm text-gray-700" + (this.props.facility.nda ? " bg-green-200" : " bg-red-200")}>
									<option key="1" value="true">Signed</option>
									<option key="2" value="false">Not Signed</option>
								</select>
							</div>
							<div>
								<select style={{ textAlignLast: "center" }} name="proforma" value={this.props.facility.proforma} onBlur={null} onChange={(e) => this.handleChange(e)} className={"truncate text-center inline-block rounded-full px-2 py-1 text-sm text-gray-700" + (this.props.facility.proforma === "In_Progress" ? " bg-yellow-200" : (this.props.facility.proforma === "Completed" ? " bg-green-200" : " bg-red-200"))}>{this.props.facility.proforma.replace("_", " ")}
									<option key="1" value="Not_Started">Not Started</option>
									<option key="2" value="In_Progress">In Progress</option>
									<option key="3" value="Completed">Completed</option>
								</select>
							</div>
							<div>
								<select style={{ textAlignLast: "center" }} name="cdm" value={this.props.facility.cdm} onBlur={null} onChange={(e) => this.handleChange(e)} className={"truncate text-center inline-block rounded-full px-2 py-1 text-sm text-gray-700" + (this.props.facility.cdm === "In_Review" ? " bg-yellow-200" : (this.props.facility.cdm === "Completed" ? " bg-green-200" : " bg-red-200"))}>{this.props.facility.cdm.replace("_", " ")}
									<option key="1" value="In_Development">In Development</option>
									<option key="2" value="In_Review">In Review</option>
									<option key="3" value="Completed">Completed</option>
								</select>
							</div>
							<div>
								<select style={{ textAlignLast: "center" }} name="contractCfo" value={this.props.facility.contractCfo} onBlur={null} onChange={(e) => this.handleChange(e)} className={"truncate text-center inline-block rounded-full px-2 py-1 text-sm text-gray-700" + (this.props.facility.contractCfo ? " bg-green-200" : " bg-red-200")}>
									<option key="1" value="true">Sent</option>
									<option key="2" value="false">Not Sent</option>
								</select>
							</div>
							<div>
								<select style={{ textAlignLast: "center" }} name="contractCeo" value={this.props.facility.contractCeo} onBlur={null} onChange={(e) => this.handleChange(e)} className={"truncate text-center inline-block rounded-full px-2 py-1 text-sm text-gray-700" + (this.props.facility.contractCeo ? " bg-green-200" : " bg-red-200")}>
									<option key="1" value="true">Sent</option>
									<option key="2" value="false">Not Sent</option>
								</select>
							</div>
							<DatePicker
								name="contract"
								selected={this.props.facility.contract ? new Date(this.props.facility.contract) : null}
								onChange={(date) => this.updateContract(date)}
								customInput={<ContractInput />}
								isClearable
								popperModifiers={{
									offset: {
										enabled: true,
										offset: "5px, 10px"
									},
									preventOverflow: {
										enabled: true,
										escapeWithReference: false,
										boundariesElement: "viewport"
									}
								}}
							/>
							<input name="unitRate" aria-label="unitRate" onChange={(e) => this.handleChange(e)} className={"truncate text-center inline-block rounded-full px-2 py-1 text-sm text-gray-700"} value={this.props.facility.unitRate ? this.props.facility.unitRate : ''} />
							<DatePicker
								name="startDate"
								selected={this.props.facility.startDate ? new Date(this.props.facility.startDate) : null}
								onChange={(date) => this.updateStartDate(date)}
								customInput={<StartDateInput />}
								isClearable
								popperModifiers={{
									offset: {
										enabled: true,
										offset: "5px, 10px"
									},
									preventOverflow: {
										enabled: true,
										escapeWithReference: false,
										boundariesElement: "viewport"
									}
								}}
							/>
							<input name="notes" aria-label="notes" onChange={(e) => this.handleChange(e)} className={"truncate text-center inline-block rounded-full px-2 py-1 text-sm text-gray-700"} value={this.props.facility.notes ? this.props.facility.notes : ''} />
						</div>
					</div>
				)}
			</Draggable>
		)
	}
}
