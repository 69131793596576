import ReportDataProvider from './report-data-provider';

import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';

import { dateFormatter } from './report-utils';
import { ChargeClient } from '../../providers/charge-client';
import { getDateKey } from '../../utils/dateUtils';

const chargeClient  = new ChargeClient();

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        marginRight: 0
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
        flexGrow: 1,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    flexContainer: {
        display: 'flex'
    },
    flexItem: {
        width: '100%',
        flexGrow: 1,
        margin: 8,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

function writeToTemplate(rows) {
    let csvContent = rows.map(e => e.join(",")).join("\n");

    csvContent = csvContent.replace(/""/g, '');

    let csvData = new Blob([csvContent], { type: 'text/csv' });
    let csvUrl = URL.createObjectURL(csvData);
    let link = document.createElement("a");
    link.setAttribute("href", csvUrl);
    link.setAttribute("download", `HPM-Kareo-Appointments-Report_${(new Date()).toLocaleDateString('en-US')}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
}


export default function KareoAppointmentsReportDialog() {
    const [open, setOpen] = React.useState(false);
    const [isBuilding, setIsBuilding] = React.useState(false);
    const [locations, setLocations] = React.useState([]);

    const provider = ReportDataProvider();
    // const { loading, error, data } = provider.getKareoLocationsData('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();

    const [facilityOption, setFacilityOption] = useState(null);
    const handleLocationSelected = (event) => {
        setFacilityOption(event.target.value);
    };

    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    useEffect(() => {
        _initAsync();
    }, []);

    async function _initAsync() {
        let locations =  await chargeClient.findLocations(null);
        locations = locations.filter(x => 
            !x.practiceName.includes('proj-')
            && !x.practiceName.includes('TEST')
            && !x.practiceName.includes('DEFAULT')
        );
        locations.sort((a,b) => a.practiceName.localeCompare(b.practiceName));
        console.log('Kareo-Appointments-Report:: Locations Set = ', locations);
        setLocations(locations);
    }

    function parseDateRange(startDate, endDate) {
        startDate = new Date(startDate);
        endDate = new Date(endDate);

        if (endDate < startDate) {
            return [[startDate, endDate]];
        };

        const twoMonthIncrements = [];
        let currentStartDate = startDate;

        while (currentStartDate < endDate) {
            const currentEndDate = new Date(currentStartDate);
            currentEndDate.setDate(currentEndDate.getDate() + 59);

            if (currentEndDate > endDate) {
                twoMonthIncrements.push([new Date(currentStartDate), new Date(endDate)]);
                break;
            }

            currentEndDate.setHours(11, 59, 59, 0);

            twoMonthIncrements.push([new Date(currentStartDate), new Date(currentEndDate)]);
            currentStartDate.setDate(currentStartDate.getDate() + 60);
        };
        return twoMonthIncrements;
    };

    async function buildReport() {
        setIsBuilding(true);
        console.log(`Building Report...`);

        selectedStartDate.setHours(0, 0, 0, 1);
        selectedEndDate.setHours(11, 59, 59, 0);        

        try {

            let filter = {
                startDate: selectedStartDate,
                endDate: selectedEndDate
            };
            if (facilityOption) {
                filter.locationId = facilityOption
            }
            let appointments =  await chargeClient.getAppointments(filter);

            // Add time stamp with adjusted time zone
            appointments = appointments.map(x => {
                const fixedDate =  x.date.toISOString().replace('Z', '-08:00');
                return {
                    ...x,
                    // dateKey: getDateKey(new Date(Date.parse(fixedDate))),
                    dateKey: getDateKey(new Date(`${x.date}`)),                    
                    fixedDate: new Date(Date.parse(fixedDate))
                };
            });

            if (appointments && appointments.length) { appointments = appointments.sort((a,b) => +(new Date(a.date) - +(new Date(b.date)))); }
            let data = buildReportData(appointments);
            writeToTemplate(data);
            console.log(`Done!`);
            setIsBuilding(false);
            handleClose();


            // const dateIncrements = parseDateRange(selectedStartDate, selectedEndDate);
            // console.log("Date Range Increments: ", dateIncrements);

            // const APIFuncs = dateIncrements.map(([startDate, endDate]) => {
            //     return () => provider.getKareoAppointmentData(startDate, endDate, facilityOption);
            // })

            // Promise.all(APIFuncs.map(APIfunc => APIfunc()))
            //     .then(results => {
            //         console.log("Resolved API Promises: ", results);
            //         const flattenedResults = results.flat();
            //         return buildReportData(flattenedResults);
            //     })
            //     .then(data => {
            //         console.log(`Writing report data to file...`);

            //         writeToTemplate(data);

            //         console.log(`Done!`);

            //         setIsBuilding(false);
            //         handleClose();
            //     })
            //     .catch(error => {
            //         console.log(error);
            //         console.log("Error in Kareo Appointments Calls");
            //         setIsBuilding(false);
            //         handleClose();
            //     });

        } catch (error) {
            setIsBuilding(false);
            handleClose();
            throw error;
        }
    }

    function buildReportData(appointments) {

        console.log(`Building report for (${appointments.length}) Kareo Appointments...`);

        const csv = [];

        csv.push([
            "Date", "Created Date", "Patient Name", "MRN", "Location", "Practice ID", "Provider", "Appointment Reason", "Appointment Status", "Last Modified Date"
        ]);

        appointments.forEach((appointment) => {
            const row = [];            
            // row.push(dateFormatter.format(new Date(appointment.date)));
            // row.push(dateFormatter.format(new Date(appointment.fixedDate)));
            // row.push(dateFormatter.format(new Date(appointment.createdDate)));

            row.push(appointment.date.toLocaleDateString());
            row.push(new Date(`${appointment.createdDate}`).toLocaleDateString());
            row.push(`"${appointment.patientName}"`);
            row.push(appointment.mrn);
            row.push(`"${appointment.location}"`);
            row.push(appointment.practiceId);
            row.push(`"${appointment.resourceName}"`);
            row.push(`"${appointment.appointmentReason}"`);
            row.push(`"${appointment.appointmentStatus}"`);
            // row.push(dateFormatter.format(new Date(appointment.lastModifiedDate)));
            row.push(new Date(`${appointment.lastModifiedDate}`).toLocaleDateString());

            csv.push(row);
        })

        return csv;
    }

    if (isBuilding) {
        return (
            <div>
                <Card className={classes.root}>
                    <CardActionArea onClick={handleClickOpen}>
                        <img src={require('../../images/Kareo-Appointments.png').default} />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Kareo Appointments
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Provides Kareo appointments over some date range.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>


                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Building Kareo Appointments Report</DialogTitle>
                    <DialogContent>
                        <div className={classes.flexCenter}>
                            <CircularProgress />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

    return (
        <div>
            <Card className={classes.root}>
                <CardActionArea onClick={handleClickOpen}>
                    <img src={require('../../images/Kareo-Appointments.png').default} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Kareo Appointments
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Provides Kareo appointments over some date range.
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create Kareo Appointments Report</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The Kareo Appointments report shows all of the Kareo appointments over a specified time.
                    </DialogContentText>
                    <div className={classes.flexContainer}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="dataSource1">Location</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={facilityOption}
                                onChange={handleLocationSelected}
                            >

                                {
                                    locations.map((location, i) => <MenuItem value={location.id}>{location.practiceName}</MenuItem>)
                                }


                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.flexContainer}>
                        <div className={classes.flexItem}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Start Date"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={classes.flexItem}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.flexItem}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="End Date"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => buildReport()} color="primary" variant="contained">
                        Build Report
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
