import React, { useState, useEffect } from 'react';
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { gql } from "apollo-boost"
import { GraphQLClient } from 'graphql-request'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import { useQuery } from 'react-apollo'

import { ChargeClient } from '../../providers/charge-client';
import PermissionsProvider from "../../providers/permissions-provider";

// import * as Excel from "exceljs";
// import { read, utils, writeFileXLSX } from 'xlsx';
// import { read, utils, writeFileXLSX } from 'xlsx-style';

import { Renderer } from 'xlsx-renderer';
import { saveAs } from "file-saver";

import { similarity } from 'string-similarity';
import { getDateKey } from '../../utils/dateUtils';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        marginRight: 0
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
        flexGrow: 1,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    flexContainer: {
        display: 'flex'
    },
    flexItem: {
        width: '100%',
        flexGrow: 1,
        margin: 8,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

// const client = new GraphQLClient('http://localhost:4000', {
//     headers: {
//       authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJja2I5dXd2M2ZzNXBhMDk2ODRqYjFvOG1lIiwiaWF0IjoxNTkyMzQxODY3fQ.r13nZ63HjDZgYkt8m9w1hJYWxSrUBLlGopMW0S8AF2Y',
//     },
//   });

const client = new GraphQLClient('https://api.hpmcharge.com/', {
    headers: {
        authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJja2I5dXd2M2ZzNXBhMDk2ODRqYjFvOG1lIiwiaWF0IjoxNTkyMzQxODY3fQ.r13nZ63HjDZgYkt8m9w1hJYWxSrUBLlGopMW0S8AF2Y',
    },
});

let lastMonth = new Date()
lastMonth.setMonth(lastMonth.getMonth() - 1)
let thisMonth = new Date()

const dateFormatter = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC'
})

const monthFormatter = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: 'numeric',
    timeZone: 'UTC'
})

const longMonth = new Intl.DateTimeFormat('en-us', {
    month: 'short'
}).format(thisMonth)

const longLastMonth = new Intl.DateTimeFormat('en-us', {
    month: 'short'
}).format(lastMonth)
thisMonth = monthFormatter.format(thisMonth)
lastMonth = monthFormatter.format(lastMonth)

const GET_CHARGE_TICKETS = gql`
    query chargeTickets ($filter: String!, $skip: Int!, $take: Int!) {
        chargeTickets (filter: $filter, orderBy: {date: asc}, skip: $skip, take: $take) {
            count
            chargeTickets {
                id
                kareoApptId
                user {
                    id
                    firstName
                    lastName
                    kareoId
                    roles {
                        id
                    }
                }
                date
                createdDate
                modifiedDate
                location
                serviceLocationName
                patientName
                mrn
                insurance
                procedures
                chargeTicketCpts {
                    cpt {
                        id
                        code
                        primaryUnits
                        secondaryUnits
                        class
                    }
                    sequence
                }
            }
        }
    }
`;

const GET_USERS = gql`
    query users {
        users {
            users {
                id
                firstName
                lastName
                email
                kareoId
				isActive
                roles {
                    id
					name
					# rolePermissions {
					# 	roleId
					# 	permissionId
					# 	permission {
					# 		id
					# 		code
					# 		description
					# 	}
					# }
                }
            }
        }
    }
`

// kareo
const key = "KAREO_KEY";
const password = "KAREO_PASSWORD";
const username = "KAREO_USERNAME";
// // const appointments = useQuery(GET_APPOINTMENTS, { variables: { xml: getXml(date), url: url, headers: JSON.stringify(apptsHeaders) } })

// const key = process.env.KAREO_KEY
// const password = process.env.KAREO_PASSWORD
// const username = process.env.KAREO_USERNAME

// const GET_APPOINTMENTS = gql`
//   query appointments ($xml: String!, $url: String!, $headers: String!) {
//     appointments (xml: $xml, url: $url, headers: $headers) {
//       appointments
//     }
//   }
// `;

const chargeClient = new ChargeClient();

async function getHpmChargeData(startDate, endDate, provider) {
    let filter = {
        AND:
            [
                {
                    date: {
                        lt: `${endDate.toISOString()}`
                    }
                },
                {
                    date: {
                        gte: `${startDate.toISOString().split('T')[0]}T00:00:00.001Z`
                    }
                }
            ]
    };
    if (provider && provider != 'ALL') {
        filter.userId = parseInt(provider.id);
    };

    console.log(`Start = ${startDate.toISOString()}, End = ${endDate.toISOString()}, Provider = ${provider}`);

    const data = await client.request(GET_CHARGE_TICKETS, {
        filter: JSON.stringify(filter),
        skip: 0,
        take: 10000
    }
    );

    return data.chargeTickets.chargeTickets;
}

async function getKareoData(startDate, endDate, provider) {
    const filter = {
        startDate: `${startDate.toISOString().split('T')[0]}T00:00:00.001Z`,
        endDate: `${endDate.toISOString().split('T')[0]}T23:59:59.999Z`,
    };
    if (provider && provider !== 'ALL') {
        filter.userIds = [provider.id];
    };

    const chargeSheets = await chargeClient.getAppointments(filter);

    // Add time stamp with adjusted time zone
    const appointments = chargeSheets.map(x => {
        const fixedDate = x.date.toISOString().replace('Z', '-08:00');
        return {
            ...x,
            // dateKey: getDateKey(new Date(Date.parse(fixedDate))),
            dateKey: getDateKey(new Date(x.date.toISOString())),
            fixedDate: new Date(Date.parse(fixedDate))
        };
    });

    console.log(`Discrepancy Report Kareo Appointments: `, appointments)
    return appointments;

    // OLD KAREO RETRIEVAL AND PARSING PROCESS
    // let chargeSheets = [];
    // let xml = getXml(startDate, endDate);

    // const url = 'https://webservice.kareo.com/services/soap/2.1/KareoServices.svc';
    // const apptsHeaders = {
    //     'Content-Type': 'text/xml;charset=UTF-8',
    //     SOAPAction: 'http://www.kareo.com/api/schemas/KareoServices/GetAppointments',
    // };

    // const data = await client.request(GET_APPOINTMENTS, {
    //     xml: xml,
    //     url: url,
    //     headers: JSON.stringify(apptsHeaders)
    // }
    // );

    // let parser = new DOMParser();
    // let appts = parser.parseFromString(data.appointments.appointments, "text/xml");
    // let pts = appts.getElementsByTagName("AppointmentData");

    // for (let i = 0; i < pts.length; i++) {
    //     let patient = pts[i];

    //     // console.log(patient.childNodes);

    //     let startDate = patient.getElementsByTagName("StartDate").item(0).firstChild.nodeValue;
    //     let location = patient.getElementsByTagName("PracticeName").item(0).firstChild.nodeValue;
    //     let serviceLocationName = patient.getElementsByTagName("ServiceLocationName").item(0).firstChild.nodeValue;
    //     let resourceName1 = patient.getElementsByTagName("ResourceName1").item(0).firstChild.nodeValue;

    //     let mrn;
    //     try {
    //         mrn = patient.getElementsByTagName("PatientID").item(0).firstChild.nodeValue;
    //     } catch {
    //         console.log(`ERROR: NO MRN => ${resourceName1} | ${serviceLocationName} =>`, patient);
    //         continue;
    //     }

    //     let name = patient.getElementsByTagName("PatientFullName").item(0).firstChild.nodeValue;
    //     let appointmentStatus = patient.getElementsByTagName("ConfirmationStatus").item(0).firstChild.nodeValue;
    //     let appointmentReason1 = patient.getElementsByTagName("AppointmentReason1").item(0).firstChild.nodeValue;
    //     let createdDate = patient.getElementsByTagName("CreatedDate").item(0).firstChild.nodeValue;
    //     let lastModifiedDate = patient.getElementsByTagName("LastModifiedDate").item(0).firstChild.nodeValue;

    //     let patientCaseID;
    //     try {
    //         patientCaseID = patient.getElementsByTagName("PatientCaseID").item(0).firstChild.nodeValue;
    //     } catch { }

    //     let practiceId = patient.getElementsByTagName("PracticeID").item(0).firstChild.nodeValue;
    //     let practiceName = patient.getElementsByTagName("PracticeName").item(0).firstChild.nodeValue;

    //     // console.log(`location = ${location} | serviceLocationName = ${serviceLocationName}`);
    //     let chargeSheet =
    //     {
    //         "date": new Date(startDate), // `${new Date(startDate).toLocaleDateString('en-US')}`,
    //         "location": location,
    //         "serviceLocationName": serviceLocationName,
    //         "resourceName": resourceName1,
    //         "mrn": mrn,
    //         "patientName": name,
    //         "appointmentStatus": appointmentStatus,
    //         "appointmentReason": appointmentReason1,
    //         "patientCaseID": patientCaseID,
    //         "practiceId": practiceId,
    //         "practiceName": practiceName,
    //         "createdDate": createdDate,
    //         "lastModifiedDate": lastModifiedDate
    //     };
    //     chargeSheets.push(chargeSheet);
    // }
}

async function writeToTemplate(viewModel) {
    // let response = await fetch('https://public-hpm-portal-files.s3.us-east-2.amazonaws.com/TEMPLATE_HPM-Charge+Ticket-Kareo-Reconciliation.xlsx');
    let response = await fetch('https://public-hpm-portal-files.s3.us-east-2.amazonaws.com/TEMPLATE_HPM-Charge+Ticket-Kareo-ReconciliationDEV.xlsx');
    let buffer = await response.arrayBuffer();
    const renderer = new Renderer();
    const report = await renderer.renderFromArrayBuffer(buffer, viewModel);

    const output = await report.xlsx.writeBuffer();
    await saveAs(new Blob([output]), `HPM_Discrepency-Report_${(new Date()).toLocaleDateString('en-US')}.xlsx`);
}

// function getXml(startDate, endDate) {
//     return `
//   <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:sch="http://www.kareo.com/api/schemas/">
//   <soapenv:Header/>
//   <soapenv:Body>
//       <sch:GetAppointments>
//         <sch:request>
//             <sch:RequestHeader>
//                 <sch:CustomerKey>${key}</sch:CustomerKey>
//                 <sch:Password>${password}</sch:Password>
//                 <sch:User>${username}</sch:User>
//             </sch:RequestHeader>
//             <sch:Fields />
//             <sch:Filter>
//             <sch:EndDate>${endDate.toISOString()}</sch:EndDate>
//             <sch:StartDate>${startDate.toISOString()}</sch:StartDate>
//             </sch:Filter>
//         </sch:request>
//       </sch:GetAppointments>
//   </soapenv:Body>
//   </soapenv:Envelope>`
// }

export default function DiscrepancyReportDialog() {
    const [open, setOpen] = React.useState(false);
    const [isBuilding, setIsBuilding] = React.useState(false);
    const [providerOptions, setProviderOptions] = React.useState([]);
    const [provider, setProvider] = React.useState('ALL');

    const users = useQuery(GET_USERS);

    useEffect(() => {
        getProviders();
    }, [])

    async function getProviders() {
        try {
            const allUsers = await users.refetch();
            if (!allUsers.data) { return; }
            let providerList = allUsers.data.users.users
                .filter(user => !PermissionsProvider.hasRole('Training') && !PermissionsProvider.hasRole('Corporate') && user.isActive)
                .sort((a, b) => {
                    var textA = a.firstName.toUpperCase();
                    var textB = b.firstName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })

            console.log('Providers: ', providerList);
            providerList = providerList.filter(x => x.value !== null);
            setProviderOptions(providerList);
        } catch (error) {
            console.log(error);
            return;
        }
    }

    const onProviderChange = (event) => {
        console.log('Provider Selected: ', event.target.value);
        setProvider(event.target.value);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();

    const [units, setUnits] = useState(null)
    const [csv, setCSV] = useState(null)

    const [reportSource1, setReportSource1] = useState('HPM Charge');
    const handleChange1 = (event) => {
        setReportSource1(event.target.value);
    };

    const [reportSource2, setReportSource2] = useState('Kareo');
    const handleChange2 = (event) => {
        setReportSource2(event.target.value);
    };

    const today = new Date();

    const [selectedStartDate, setSelectedStartDate] = useState(new Date(today.getFullYear(), today.getMonth()));
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const [selectedEndDate, setSelectedEndDate] = useState(new Date(today.getFullYear(), today.getMonth() + 1, 0));
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    // const discrepancyReportImage = useStaticQuery(graphql`
    //     query {
    //     placeholderImage: file(relativePath: { eq: "Discrepancy-Report.png" }) {
    //         childImageSharp {
    //             fixed(width: 320, height: 240) {
    //                 ...GatsbyImageSharpFixed
    //             }
    //         }
    //     }
    //     }
    // `);

    async function buildReport() {
        setIsBuilding(true);

        console.log(`Building Report...`);
        let reportData
        try {
            reportData = await buildReportData();
        } catch (e) {
            setIsBuilding(false);
            throw e;
        }

        reportData.metadata = {
            createdDate: `${new Date()}`,
            createdBy: `HPM Charge User`,
            dataSource1: `HPM Charge`,
            dataSource2: `Kareo`,
            startDate: `${selectedStartDate.toLocaleDateString('en-US')}`,
            endDate: `${selectedEndDate.toLocaleDateString('en-US')}`
        };

        console.log(`reportData = `, reportData);

        console.log(`Writing report data to file...`);
        try {
            await writeToTemplate(reportData);
        } catch (e) {
            setIsBuilding(false);
            throw e;
        }

        console.log(`Done!`);

        setIsBuilding(false);
        handleClose();
    }

    async function buildReportData() {

        selectedStartDate.setHours(0, 0, 0, 1);
        selectedEndDate.setHours(23, 59, 59, 0);

        // adjust query time to UTC offset, ticket dates are all UTC @ midnight w.r.t. TZ where they were created
        var offsetStartTime = new Date(selectedStartDate.getTime() - selectedStartDate.getTimezoneOffset() * 60 * 1000);
        // var offsetEndTime = new Date(selectedEndDate.getTime() + selectedEndDate.getTimezoneOffset() * 60 * 1000);

        console.log(`START DATE = ${selectedStartDate}`);
        console.log(`EFEECTIVE START DATE = ${offsetStartTime}`);
        console.log(`END DATE = ${selectedEndDate}`);
        // console.log(`EFEECTIVE END DATE = ${offsetEndTime}`);

        // var startDate = new Date(selectedStartDate.toUTCString().substr(0, 25));
        // startDate.setHours(0, 0, 0, 1);

        // console.log('reportSource1 = ', reportSource1);
        // console.log('reportSource2 = ', reportSource2);
        // console.log('selectedStartDate = ', selectedStartDate);
        // console.log('selectedEndDate = ', selectedEndDate);

        if (reportSource1 === reportSource2) {
            alert('Report Sources must be different');
            return;
        }

        // TODO: set up data in generic way so other sources could be used

        let ticketData;
        let ticketDataMap;
        let appointmentData;
        let appointmentDataMap;

        // get charge ticket data
        ticketData = await getHpmChargeData(offsetStartTime, selectedEndDate, provider);
        ticketData = ticketData.filter(ticket => ticket.patientName !== 'HPM Training');
        // console.log('HPM Charge data = ', ticketData);
        ticketDataMap = getHpmChargeMap(ticketData);
        // console.log('HPM Charge data map = ', ticketDataMap);

        // get Kareo data
        appointmentData = await getKareoData(offsetStartTime, selectedEndDate, provider);
        // console.log('Kareo data = ', appointmentData);
        appointmentDataMap = getKareoMap(appointmentData);
        console.log('Kareo data map = ', appointmentDataMap);

        let finishedAppointments = appointmentDataMap.filter(x => 
            x.data.appointmentStatus.trim().toLowerCase() === "check-out"
            || x.data.appointmentStatus.trim().toLowerCase() === "checkedout");
        let unfinishedAppointments = appointmentDataMap.filter(x => 
            x.data.appointmentStatus.trim().toLowerCase() !== "check-out"
            && x.data.appointmentStatus.trim().toLowerCase() !== "checkedout");

        // get all Appointments with "check-out" but without Charge Tickets
        let missingTickets = exclude(finishedAppointments, ticketDataMap);
        // console.log('missingTickets', missingTickets);

        const ticketsWithKareoAppt = ticketData.filter(x => !!x.kareoApptId).map(x => `${x.kareoApptId}`.trim());
        missingTickets = missingTickets.filter(x => !ticketsWithKareoAppt.includes(x.data.kareoId.trim()));
        // console.log('missingTickets (filtered)', missingTickets);

        // get all Appointments WITHOUT "check-out" and without Charge Tickets
        let missingTicketsNoCheckOut = exclude(unfinishedAppointments, ticketDataMap);

        let validTickets = ticketDataMap.filter(x => x.data.location.replace(/\s/g, '').toLowerCase() !== "hpmtraining");

        // get all Charge Tickets that have Appointments NOT in "check-out" state in Kareo
        let nonCheckoutTickets = include(validTickets, unfinishedAppointments);
        let nonCheckoutTicketsAppointments = include(unfinishedAppointments, nonCheckoutTickets);
        // console.log('nonCheckoutTickets', nonCheckoutTickets);
        // console.log('nonCheckoutTicketsAppointments', nonCheckoutTicketsAppointments);
        let ticketsWithoutCheckout = mergeData(nonCheckoutTickets, nonCheckoutTicketsAppointments);
        // console.log('ticketsWithoutCheckout', ticketsWithoutCheckout);

        // get all Charge Tickets without Appointments in Kareo
        let missingAppointments = exclude(validTickets, appointmentDataMap);

        // ignore tickets that have a kareoApptId (they obv has an associated appt)
        missingAppointments = missingAppointments.filter(x => !x.data.kareoApptId);
        // console.log('missingAppointments', missingAppointments);

        let similarTickets = getSimilarTickets(validTickets);
        // console.log('similarTickets', similarTickets);
        let matchingKareoAppts = include(appointmentDataMap, similarTickets);

        let dupeEntries = mergeData(similarTickets, matchingKareoAppts);

        return {
            tickets: ticketDataMap,
            appointments: appointmentDataMap,
            missingTickets: missingTickets,
            ticketsWithoutCheckout: ticketsWithoutCheckout,
            missingAppointments: missingAppointments,
            duplicateTickets: dupeEntries,
            missingTicketsNoCheckOut: missingTicketsNoCheckOut
        }
    }

    function getHpmChargeMap(data, filter) {
        let dataMap = [];
        for (let i = 0; i < data.length; i++) {

            let resourceNameParts = data[i].user.kareoId ? data[i].user.kareoId.trim().split(' ') : `UNKNOWN KAREO ID (${data[i].user.firstName} ${data[i].user.lastName})`;
            let resourceName = `${resourceNameParts[0]}${resourceNameParts[resourceNameParts.length - 1]}`;
            // let resourceName;
            // if (data[i].user.kareoId) {
            //     resourceName = `${data[i].user.kareoId.replaceAll(' ', '')}`;
            // } else {
            //     resourceName = `${data[i].user.firstName}${data[i].user.lastName}`;
            // }

            let patientNameParts = data[i].patientName.replace(/&QUOT;/g, '').trim().split(' ');
            let patientNameKey = `${patientNameParts[0]}${patientNameParts[patientNameParts.length - 1]}`;

            // let key = `${data[i].patientName}${data[i].mrn}${data[i].user.kareoId}`.replace(/\s/g, '').trim().toLowerCase();
            data[i].date = new Date(data[i].date);
            // let key = `${data[i].patientName}${getFormattedDate(data[i].date)}${resourceName}`.replace(/\s/g, '').trim().toLowerCase();
            // let key = `${patientNameKey}${dateFormatter.format(new Date(data[i].date)).replace(/\//g, '')}${resourceName}`.replace(/\s/g, '').trim().toLowerCase();
            let key = `${patientNameKey}${new Date(data[i].date).toLocaleDateString().replace(/\//g, '')}${resourceName}`.replace(/\s/g, '').trim().toLowerCase();

            if (filter && !filter(data[i])) {
                continue;
            }

            // data[i].date = `${dateFormatter.format(new Date(data[i].date))}`; // `${data[i].date.toLocaleDateString('en-US')}`;
            data[i].date = `${data[i].date.toLocaleDateString()}`;
            data[i].patientName = data[i].patientName.replace(/&QUOT;/g, '');

            data[i].cpt1 = data[i].chargeTicketCpts && data[i].chargeTicketCpts.length > 0 ? data[i].chargeTicketCpts[0].cpt.code : null;
            data[i].cpt2 = data[i].chargeTicketCpts && data[i].chargeTicketCpts.length > 1 ? data[i].chargeTicketCpts[1].cpt.code : null;
            data[i].cpt3 = data[i].chargeTicketCpts && data[i].chargeTicketCpts.length > 2 ? data[i].chargeTicketCpts[2].cpt.code : null;

            dataMap.push({
                key: key,
                data: data[i]
            });

        }
        return dataMap;
    }

    function getKareoMap(data, filter) {
        let dataMap = [];
        for (let i = 0; i < data.length; i++) {

            let resourceNameParts = data[i].resourceName.trim().split(' ');
            let resourceName = `${resourceNameParts[0]}${resourceNameParts[resourceNameParts.length - 1]}`;
            // let resourceName = `${data[i].resourceName.replaceAll(' ', '')}`;

            let patientNameParts = data[i].patientName.replace(/&QUOT;/g, '').trim().split(' ');
            let patientNameKey = `${patientNameParts[0]}${patientNameParts[patientNameParts.length - 1]}`;

            data[i].date = new Date(data[i].date);
            // let key = `${data[i].patientName}${data[i].mrn}${data[i].resourceName}`.replace(/\s/g, '').trim().toLowerCase();
            // let key = `${data[i].patientName}${getFormattedDate(data[i].date)}${resourceName}`.replace(/\s/g, '').trim().toLowerCase();
            // let key = `${patientNameKey}${dateFormatter.format(new Date(data[i].date)).replace(/\//g, '')}${resourceName}`.replace(/\s/g, '').trim().toLowerCase();
            let key = `${patientNameKey}${new Date(data[i].date).toLocaleDateString().replace(/\//g, '')}${resourceName}`.replace(/\s/g, '').trim().toLowerCase();

            if (filter && !filter(data[i])) {
                continue;
            }

            // data[i].date = `${dateFormatter.format(new Date(data[i].date))}`; // `${data[i].date.toLocaleDateString('en-US')}`;
            data[i].date = data[i].dateKey;
            data[i].patientName = data[i].patientName.replace(/&QUOT;/g, '');

            dataMap.push({
                key: key,
                data: data[i]
            });

        }
        return dataMap;
    }

    function getSimilarTickets(tickets) {

        let similarList = [];

        for (let i = 0; i < tickets.length; i++) {
            for (let j = 0; j < tickets.length; j++) {
                if (i !== j) {

                    if (tickets[i].id !== tickets[j].id) {

                        if (tickets[i].patientName && tickets[j].patientName) {
                            if (similarity.compareTwoStrings(tickets[i].patientName, tickets[j].patientName) > 0.1) {
                                similarList.push({
                                    item1: tickets[i],
                                    item2: tickets[j]
                                });
                            }
                        } else {
                            console.log(`ERROR: patient name is null => ${JSON.stringify(tickets[i])} | ${JSON.stringify(tickets[j])}`);
                        }

                    }
                }
            }
        }

        return similarList;
    }

    function exclude(arr1, arr2) { // arr1 items that are NOT in arr2
        return arr1.filter(o1 => !arr2.map(o2 => o2.key).includes(o1.key));
    }

    function include(arr1, arr2) { // arr1 items that are in arr2
        return arr1.filter(o1 => arr2.map(o2 => o2.key).includes(o1.key));
    }

    function mergeData(tickets, appointments) { // merge arrays by key
        let mergedData = [];

        for (let i = 0; i < tickets.length; i++) {
            for (let j = 0; j < appointments.length; j++) {
                if (tickets[i].key == appointments[j].key) {
                    mergedData.push({
                        key: tickets[i].key,
                        ticket: tickets[i].data,
                        appointment: appointments[j].data
                    })
                }
            }
        }

        return mergedData;
    }

    function getFormattedDate(date) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');

        return month + day + year;
    }

    if (isBuilding) {
        return (
            <div>
                <Card className={classes.root}>
                    <CardActionArea onClick={handleClickOpen}>
                        {/* <Img fixed={discrepancyReportImage.placeholderImage.childImageSharp.fixed} /> */}
                        <img src={require('../../images/Discrepancy-Report.png').default} />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Discrepancy Report
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Discrepancies between HPM Charge Tickets and Kareo Appointments.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    {/* <CardActions>
                        <Button size="small" color="primary" onClick={handleClickOpen}>
                            Build Report
                        </Button>
                    </CardActions> */}
                </Card>


                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Building Discrepancy Report</DialogTitle>
                    <DialogContent>
                        <div className={classes.flexCenter}>
                            <CircularProgress />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

    return (
        <div>
            <Card className={classes.root}>
                <CardActionArea onClick={handleClickOpen}>
                     {/* <Img fixed={discrepancyReportImage.placeholderImage.childImageSharp.fixed} /> */}
                     <img src={require('../../images/Discrepancy-Report.png').default} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Discrepancy Report
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Discrepancies between HPM Charge Tickets and Kareo Appointments.
                        </Typography>
                    </CardContent>
                </CardActionArea>
                {/* <CardActions>
                    <Button size="small" color="primary" onClick={handleClickOpen}>
                        Build Report
                    </Button>
                </CardActions> */}
            </Card>


            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create Discrepancy Report</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The discrepancy report shows discrepancies between HPM Charge Tickets and Kareo Appointments.
                    </DialogContentText>
                    {/* <div className={classes.flexContainer}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="dataSource1">Source 1</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={reportSource1}
                                onChange={handleChange1}
                            >
                                <MenuItem value={0}>HPM Charge</MenuItem>
                                <MenuItem value={1}>Kareo</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel id="dataSource2">Source 2</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={reportSource2}
                                onChange={handleChange2}
                            >
                                <MenuItem value={0}>HPM Charge</MenuItem>
                                <MenuItem value={1}>Kareo</MenuItem>
                            </Select>
                        </FormControl>
                    </div> */}
                    <div className={classes.flexContainer}>
                        <div className={classes.flexItem}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Start Date"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={classes.flexItem}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.flexItem}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="End Date"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <FormControl className={classes.flexItem}>
                        <InputLabel id="provider">Provider</InputLabel>
                        <Select
                            labelId="provider"
                            id="provider"
                            value={provider}
                            onChange={onProviderChange}
                        >
                            <MenuItem value='ALL'>All Providers</MenuItem>
                            {
                                providerOptions.map(provider => <MenuItem key={provider.id} value={provider}>{provider.firstName} {provider.lastName}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => buildReport()} color="primary" variant="contained">
                        Build Report
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
