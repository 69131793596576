import React, { useEffect, useState } from "react";
import { ChargeClient } from "../../providers/charge-client";
import { Chip, TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";

const chargeClient = new ChargeClient();

const IcdSelector = ({ value, onChange }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        _getCodes();
    }, []);

    async function _getCodes() {
        let icdCodes = await chargeClient.getIcdCodes();
        if (icdCodes && icdCodes.length) {
            setOptions(icdCodes);
        }
    }

    function handleChange(evt) {
        console.log(`evt = `, evt);
        if (onChange) { onChange(evt); }
    }

    return (
        <div className="w-full max-w-md max-w-sm">
            <Autocomplete
                size="small"
                // className="w-full shadow appearance-none border rounded"
                className="w-full shadow"
                multiple
                limitTags={3}
                options={options}
                getOptionLabel={(option) => `${option.code} - ${option.description}`}
                value={value}
                defaultValue={value}
                onChange={(event, newValue) => handleChange(newValue)}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="ICD-10 Codes" />
                )}
            />
        </div>
    );
}

export default IcdSelector;