import ReportDataProvider from './report-data-provider';

import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';

import { dateFormatter } from './report-utils';
import { tokenProvider } from '../../providers/tokenProvider';
import { api } from '../../env/connection';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        marginRight: 0
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
        flexGrow: 1,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    flexContainer: {
        display: 'flex'
    },
    flexItem: {
        width: '100%',
        flexGrow: 1,
        margin: 8,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

function writeToTemplate(rows) {
    let csvContent = rows.map(e => e.join(",")).join("\n");

    csvContent = csvContent.replace(/""/g, '');

    let csvData = new Blob([csvContent], { type: 'text/csv' });
    let csvUrl = URL.createObjectURL(csvData);
    let link = document.createElement("a");
    link.setAttribute("href", csvUrl);
    link.setAttribute("download", `HPM-Kareo-Patients-Report_${(new Date()).toLocaleDateString('en-US')}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
}


export default function KareoPatientsReportDialog() {
    const [open, setOpen] = React.useState(false);
    const [isBuilding, setIsBuilding] = React.useState(false);
    const [locations, setLocations] = React.useState([]);

    const provider = ReportDataProvider();
    const { loading, error, data } = provider.getKareoLocationsData('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();

    const [facilityOption, setFacilityOption] = useState(null);
    const handleLocationSelected = (event) => {
        setFacilityOption(event.target.value);
    };

    // const setupLocations = async () => {
    //     if (!locations.length) {
    //         let response = await provider.getKareoLocationsData();
    //         console.log(`resp = `, response);
    //         setLocations(response.sort());
    //     }
    // }

    useEffect(() => {
        // setupLocations();
        if (data && data.length !== 0) {
            console.log('Kareo-Patients-Report:: Locations Set');
            setLocations(data)
        }
    }, [data]);

    async function buildReport() {
        setIsBuilding(true);
        console.log(`Building Report...`);

        try {
            // const dateIncrements = parseDateRange(selectedStartDate, selectedEndDate);
            // console.log("Date Range Increments: ", dateIncrements);

            // const APIFuncs = dateIncrements.map(([startDate, endDate]) => {
            //     return () => provider.getKareoAppointmentData(startDate, endDate, facilityOption);
            // })

            // Promise.all(APIFuncs.map(APIfunc => APIfunc()))
            //     .then(results => {
            //         console.log("Resolved API Promises: ", results);
            //         const flattenedResults = results.flat();
            //         return buildReportData(flattenedResults);
            //     })
            //     .then(data => {
            //         console.log(`Writing report data to file...`);

            //         writeToTemplate(data);

            //         console.log(`Done!`);

            //         setIsBuilding(false);
            //         handleClose();
            //     })
            //     .catch(error => {
            //         console.log(error);
            //         console.log("Error in Kareo Patients Calls");
            //         setIsBuilding(false);
            //         handleClose();
            //     })

            console.log(`getting patients for facility: `, facilityOption);
            let body = null;
            if (facilityOption && facilityOption !== 'ALL') {
                body = { location: facilityOption }
            }

            const rawResponse = await fetch(`${api}/api/patients/find`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenProvider.token}`
                },
                body: body ? JSON.stringify(body) : null
            });

            if (rawResponse.ok) {
                const response = await rawResponse.json();
                console.log(`Kareo Patients response = `, response);

                let kareoPatients = response.data.patients;
                let data = buildReportData(kareoPatients);

                console.log(`Writing report data to file...`);
                writeToTemplate(data);
                console.log(`Done!`);

                setIsBuilding(false);
                handleClose();

            } else {
                console.log(`ERROR fetching patients: `, rawResponse);
                setIsBuilding(false);
                throw 'Error getting patient data';
            }

        } catch (error) {
            setIsBuilding(false);
            handleClose();
            throw error;
        }
    }

    function buildReportData(patients) {

        if (!patients.length) {
            console.log(`NO PATIENTS!`);
            return;
        }

        console.log(`Building report for (${patients.length}) Kareo Patients...`);

        let pt = patients[0];
        let fields = Object.keys(pt);

        const csv = [];
        csv.push(fields);

        for (const patient of patients) {
            const row = [];
            for (const field of fields) {

                if (patient[field] === null || patient[field] === undefined) {
                    row.push("");
                    continue;
                }

                let val = `"${patient[field]}"`; // patient[field];
                if (field.toLowerCase().includes('date')) {
                    val = dateFormatter.format(new Date(`${val}`));
                }

                row.push(val);
            }
            csv.push(row);
        }

        return csv;


        // csv.push([
        //     'ID',
        //     'CreatedDate',
        //     'LastModifiedDate',
        //     'PracticeName',
        //     'PatientFullName',
        //     'FirstName',
        //     'MiddleName',
        //     'LastName',
        //     'DOB',
        //     'Age',
        //     'Gender',
        //     'MedicalRecordNumber',
        //     'ReferralSource',
        //     'AddressLine1',
        //     'AddressLine2',
        //     'City',
        //     'State',
        //     'Country',
        //     'ZipCode',
        //     'HomePhone',
        //     'HomePhoneExt',
        //     'WorkPhone',
        //     'WorkPhoneExt',
        //     'MobilePhone',
        //     'MobilePhoneExt',
        //     'EmailAddress',
        //     'PracticeId',
        //     'ReferringProviderFullName',
        //     'ReferringProviderId',
        // ]);

        // patients.forEach((patient) => {
        //     const row = [];

        //     row.push(dateFormatter.format(new Date(patient.date)))
        //     row.push(dateFormatter.format(new Date(patient.createdDate)))
        //     row.push(`"${patient.patientName}"`)
        //     row.push(patient.mrn)
        //     row.push(`"${patient.location}"`)
        //     row.push(patient.practiceId)
        //     row.push(`"${patient.resourceName}"`)
        //     row.push(`"${patient.appointmentReason}"`)
        //     row.push(`"${patient.appointmentStatus}"`)
        //     row.push(dateFormatter.format(new Date(patient.lastModifiedDate)))

        //     csv.push(row)
        // })

        // return csv
    }

    if (isBuilding) {
        return (
            <div>
                <Card className={classes.root}>
                    <CardActionArea onClick={handleClickOpen}>
                        <img src={require('../../images/Kareo-Patients.png').default} />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Kareo Patients
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Provides Kareo patients over some date range.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>


                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Building Kareo Patients Report</DialogTitle>
                    <DialogContent>
                        <div className={classes.flexCenter}>
                            <CircularProgress />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

    return (
        <div>
            <Card className={classes.root}>
                <CardActionArea onClick={handleClickOpen}>
                    <img src={require('../../images/Kareo-Patients.png').default} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Kareo Patients
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Provides Kareo patients over some date range.
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create Kareo Patients Report</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The Kareo Patients report shows Kareo patients form a facility or all patients.
                    </DialogContentText>
                    <div className={classes.flexContainer}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="dataSource1">Location</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={facilityOption}
                                onChange={handleLocationSelected}
                            >
                                <MenuItem value="ALL">ALL</MenuItem>
                                {
                                    locations.map((location, i) => <MenuItem value={location}>{location}</MenuItem>)
                                }


                            </Select>
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => buildReport()} color="primary" variant="contained">
                        Build Report
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
