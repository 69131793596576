import React, { useState } from 'react'
import SEO from "./seo"
import { gql } from "apollo-boost"
import { useQuery } from 'react-apollo'
import { CSVLink } from "react-csv"
import { getUnits, LocationsUnitsProvider } from '../utils/unitUtils';
import { request, GraphQLClient } from 'graphql-request';
import { tokenProvider } from '../providers/tokenProvider';

// const client = new GraphQLClient('http://localhost:4000', {
//     headers: {
//       authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJja2I5dXd2M2ZzNXBhMDk2ODRqYjFvOG1lIiwiaWF0IjoxNTkyMzQxODY3fQ.r13nZ63HjDZgYkt8m9w1hJYWxSrUBLlGopMW0S8AF2Y',
//     },
//   });

// const client = new GraphQLClient('https://api.hpmcharge.com/', {
//     headers: {
//       authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJja2I5dXd2M2ZzNXBhMDk2ODRqYjFvOG1lIiwiaWF0IjoxNTkyMzQxODY3fQ.r13nZ63HjDZgYkt8m9w1hJYWxSrUBLlGopMW0S8AF2Y',
//     },
//   });

const client = new GraphQLClient('https://api.hpmcharge.com/', {
    headers: {
        authorization: `Bearer ${tokenProvider.token}`,
    },
  });

var lastMonth = new Date()
lastMonth.setMonth(lastMonth.getMonth() - 1)
var thisMonth = new Date()

const dateFormatter = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC'
})

const monthFormatter = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: 'numeric',
    timeZone: 'UTC'
})

const longMonth = new Intl.DateTimeFormat('en-us', {
    month: 'short'
}).format(thisMonth)

const longLastMonth = new Intl.DateTimeFormat('en-us', {
    month: 'short'
}).format(lastMonth)
thisMonth = monthFormatter.format(thisMonth)
lastMonth = monthFormatter.format(lastMonth)

const GET_CHARGE_TICKETS = gql`
    query chargeTickets ($skip: Int!, $take: Int!) { 
        chargeTickets (orderBy: {date: asc}, skip: $skip, take: $take) {
            count
            chargeTickets {
                id
                user {
                    id
                    firstName
                    lastName
                    roles {
                        id
                    }
                }
                date
                createdDate
                modifiedDate
                location
                serviceLocationName
                patientName
                mrn
                insurance
                procedures
                chargeTicketCpts {
                    cpt {
                        id
                        code
                        primaryUnits
                        secondaryUnits
                        class
                    }
                    sequence
                }
            }
        }
    }
`

const initialize = async (chargeTickets, setCSV, setUnits) => {
    // const locationUnitsProvider = LocationsUnitsProvider.getInstance();
    await LocationsUnitsProvider.refreshData();

    let csv = []
    let byMonth = {}
    let totalUnits = 0
    csv.push(['HPM ID', 'Procedure Date', 'Provider', 'Location', 'Service Location Name', 'Patient Name', 'MRN', 'Insurance', 'Procedures',
    'CPT 1', 'CPT 2', 'CPT 3', 'CPT 4', 'CPT 5', 'CPT 6', 'CPT 7', 'CPT 8',
    'CPT Units 1', 'CPT Units 2', 'CPT Units 3', 'CPT Units 4', 'CPT Units 5', 'CPT Units 6', 'CPT Units 7', 'CPT Units 8',
    'Total Units', 'Excess Units',
    'Created Date', 'Modified Date',
    'Blind Injection Performed', 'Physical Therapy Ordered', 'Labs Ordered', 'Procedure Ordered', 'Imaging Ordered', // checkboxes
    'Ord CPT 1', 'Ord CPT 2', 'Ord CPT 3', 'Ord CPT 4', 'Ord CPT 5', 'Ord CPT 6', 'Ord CPT 7', 'Ord CPT 8', 'Ord CPT 9', 'Ord CPT 10', // additional orders during clinic visit
    'Ord CPT 11', 'Ord CPT 12', 'Ord CPT 13', 'Ord CPT 14', 'Ord CPT 15', // additional orders during clinic visit
    'Type', 'Days',
    'Trainer', 'Student Type',
    'Imaging Equipment'
    ]); 

    chargeTickets.map((chargeTicket => {
        let row = []
        let codes = new Array(8).fill('');        
        let csvUnits = new Array(8).fill('');
        let additionalCodes = new Array(15).fill('');
        const isClinic = JSON.stringify(chargeTicket.chargeTicketCpts).includes("\"code\":\"99")
        const providerType = chargeTicket.user.roles[0].id === "3" ? "CRNA" : "PA"
        let month = monthFormatter.format(new Date(chargeTicket.date))
        let units = 0
        let excessUnits = 0;
        let imageOrd, procOrd, ptOrd, labOrd, blindInjOrd = false;

        chargeTicket.chargeTicketCpts.map((chargeTicketCpt) => {
            const cptUnits = LocationsUnitsProvider.getUnits(chargeTicket, chargeTicketCpt, providerType, chargeTicket.location, isClinic);
            units += cptUnits;

            if (!isClinic) {
                codes[chargeTicketCpt.sequence - 1] = chargeTicketCpt.cpt.code
                csvUnits[chargeTicketCpt.sequence - 1] = cptUnits
            }

            else if (chargeTicketCpt.cpt.code.startsWith("99")) {
                // clinic visits should only have one "MAIN" charge code
                codes[0] = chargeTicketCpt.cpt.code;
                csvUnits[0] = cptUnits;
            }
            
            if (isClinic) {
                // additional codes for clinic visits
                if (chargeTicketCpt.sequence > 1) {
                    additionalCodes[chargeTicketCpt.sequence - 2] = chargeTicketCpt.cpt.code;
                }

                if (chargeTicketCpt.cpt.id === "161") blindInjOrd = true;
                if (chargeTicketCpt.cpt.id === "150") ptOrd = true;
                if (chargeTicketCpt.cpt.id === "149") labOrd = true;
                if (chargeTicketCpt.cpt.class === "CRNA PAIN MANAGEMENT PROCEDURES") procOrd = true;
                if (chargeTicketCpt.cpt.class === "MED IMAGING") imageOrd = true;
            }

            return false
        })

        if (units > 0) {
            // if (units > 40) {
            //     excessUnits = units - 40
            //     units = 40
            // }
            units = LocationsUnitsProvider.applyUnitCap(units, chargeTicket.location);

            byMonth[month] ? byMonth[month] += units : byMonth[month] = units
            totalUnits += units
        }

        row.push(chargeTicket.id)
        row.push(dateFormatter.format(new Date(chargeTicket.date)))
        row.push(chargeTicket.user.firstName + " " + chargeTicket.user.lastName)
        row.push(chargeTicket.location)
        row.push(chargeTicket.serviceLocationName)
        row.push(chargeTicket.patientName)
        row.push(chargeTicket.mrn)
        row.push(chargeTicket.insurance)
        row.push(chargeTicket.procedures === "null" ? null : chargeTicket.procedures)
        row.push(codes.join('","'))
        row.push(csvUnits.join('","'))
        row.push(units)
        row.push(excessUnits);
        row.push(dateFormatter.format(new Date(chargeTicket.createdDate)));
        row.push(chargeTicket.createdDate === chargeTicket.modifiedDate ? '' : dateFormatter.format(new Date(chargeTicket.modifiedDate)));

        // checkboxes
        row.push(blindInjOrd ? 1 : '');        
        row.push(ptOrd ? 1 : '');
        row.push(labOrd ? 1 : '');
        row.push(procOrd ? 1 : '');
        row.push(imageOrd ? 1 : '');

        // additional rows for clinic visits that also have other CPTs ordered:
        row.push(additionalCodes.join('","'));

        row.push(isClinic ? 'Clinic' : 'Procedure');
        row.push(`${chargeTicket.user.firstName}  ${chargeTicket.user.lastName} ${dateFormatter.format(new Date(chargeTicket.date))}`);

        row.push(`${chargeTicket.trainer ? chargeTicket.trainer : ''}`);
        row.push(`${chargeTicket.studentType ? chargeTicket.studentType : ''}`);
        row.push(`${chargeTicket.imagingEquipment ? chargeTicket.imagingEquipment : ''}`);

        csv.push(row)
        return true
    }))

    setCSV(csv)
    setUnits({ total: totalUnits, byMonth: byMonth })
}

const fetchTickets = async (count, setCSV, setUnits) => {

    let chargeTickets = [];
    let currentCount = 0;

    let chunkSize = 10000;
    let chunkCount = Math.ceil(count / chunkSize);

    console.log(`Getting Charge Tickets in chunks (${count} => ${chunkCount} chunks)`);
    for (let i = 0; i < chunkCount; i++) {
        console.log(`   Fetching charge ${chunkCount} tickets: ${i} => skip=${chunkSize * i} take=${chunkSize}...`);

        const data = await client.request(GET_CHARGE_TICKETS, { skip: chunkSize * i, take: chunkSize });

        let results = data.chargeTickets.chargeTickets;
        if (results.length) {
            console.log(`   Adding results (${results.length})...`);
            chargeTickets = chargeTickets.concat(results);
        }
    }

    console.log(` Init analytics!`);
    initialize(chargeTickets, setCSV, setUnits);
}

const Analytics = () => {
    const [units, setUnits] = useState(null)
    const [csv, setCSV] = useState(null)
    // useQuery(GET_CHARGE_TICKETS, { onCompleted: (data) => initialize(data.chargeTickets, setCSV, setUnits) });

    // const locationUnitsProvider = LocationsUnitsProvider.getInstance();
    LocationsUnitsProvider.refreshData();

    let count = 0;
    useQuery(GET_CHARGE_TICKETS, { 
        variables: { skip: 0, take: 1 },
        onCompleted: (data) => {
            if (data.chargeTickets.count) {
                fetchTickets(data.chargeTickets.count, setCSV, setUnits);
            }            
        }
    });


    //const filter = "{ \"date\": { \"gte\": \"2021-01-01T00:00:00.000Z\" } }"
    //useQuery(GET_CHARGE_TICKETS, { variables: { filter: filter }, onCompleted: (data) => getUnitTotals(data.chargeTickets, setUnits) })

    if (units) {
        return (
            <div className="mb-20">
                <SEO title="Analytics" />
                <label className="text-3xl font-black">Billing Unit Totals {' '}</label>
                < CSVLink
                    data={csv}
                    filename={"Charge_Ticket_Report_" + dateFormatter.format(new Date()) + ".csv"}
                    className="button mr-0"
                    target="_blank" > (Download CSV)
                </CSVLink >
                <div className="mt-10 mb-4 border-r border-b border-l border-gray-400 lg:rounded-l lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4">
                    <div className="mt-4 flex flex-wrap">
                        <h1 className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 font-black">Total: {units.total}</h1>
                        <h1 className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 font-black">{longMonth}: {units.byMonth[thisMonth]}</h1>
                        <h1 className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 font-black">{longLastMonth}: {units.byMonth[lastMonth]}</h1>
                    </div>
                </div>
                <div className="mt-10 mb-10" style={{ height: "1000px" }}>
                    <h2 className="font-black">KPIs:</h2>
                    <iframe width="90%" height="90%" title="KPIs" src="https://app.powerbi.com/reportEmbed?reportId=d7bc1eb1-d8db-4e6c-8eb6-0c57a9d74911&autoAuth=true&ctid=2a611e20-55a0-44a3-9746-dd6747df2fa5&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtYi1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9" frameborder="0" allowFullScreen="true"></iframe>
                </div>
                <div className="m-10">&nbsp;</div>
            </div>
        )
    }

    return (
        <>
            <SEO title="Analytics" />
            <h1>Analytics Dashboard</h1>
            <h2>Loading...</h2>
        </>
    )
}

export default Analytics

/*  < CSVLink
data = { csv }
filename = { "Charge_Ticket_Report_" + dateFormatter.format(new Date()) + ".csv" }
className = "button mr-0"
target = "_blank" > (Download CSV)</CSVLink >*/