import React, { useState } from "react";

const clinicProcedureCpts = [
  '11901',
  '20526',
  '20550',
  '20551',
  '20552',
  '20553',
  '20600',
  '20604',
  '20605',
  '20606',
  '20610',
  '20611',
];

const ClinicProceduresCptSearch = ({ cpts, selected, onChange }) => {
  const [filteredList, setFilteredList] = useState([]);
  const [isMultiSelect, setIsMultiSelect] = useState(false);

  // const clinicProcCpts = cpts && cpts.length ? cpts.filter(x => !!x.cpttiers.find(t => t.tierId === 6)) : [];
  const clinicProcCpts = cpts && cpts.length ? cpts.filter(x => clinicProcedureCpts.includes(x.code.split('-')[0])) : [];

  function handleSearch(e) {
    setIsMultiSelect(true);
    var newList = [];
    const filter = e.target.value.toLowerCase();

    if (e.target.value !== "") {
      newList = clinicProcCpts.filter(item => {
        const desc = item.description.toLowerCase();
        const cpt = item.code.toLowerCase();
        if ((desc.includes(filter) || cpt.includes(filter))) { // && item.type === location.chargeType) {
          return true;
        }
        return false;
      })
    } else {
      newList = [];
    }

    setFilteredList(newList);
  }

  function handleDone(e) {
    e.preventDefault();
    setFilteredList([]);
    setIsMultiSelect(false);
    const newItem = document.getElementById("addClinicProcInput");
    const form = document.getElementById("addClinicProcItemForm");
    newItem.classList.remove("is-danger");
    newItem.value = null;
    form.reset();
  }

  async function addItem(e, item) {
    e.preventDefault();
    const newSelected = [...selected, item];
    onChange(newSelected);
  }

  function removeItem(e, item) {
    e.preventDefault();
    const newSelected = selected.filter(x => x.id !== item.id);
    onChange(newSelected);
  }

  return (
    <form className="mt-4" id="addClinicProcItemForm">
      <div>Clinic Procedure CPTs:</div>
      <input aria-label="search" type="text" className="w-full shadow appearance-none border rounded max-w-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="addClinicProcInput" onChange={(event) => handleSearch(event)} placeholder="Search..." />
      <ol className="list-decimal mt-4">
        {selected.map((item, index) => (
          <li key={index}>
            {item.description + " (" + item.code + ")"} &nbsp;
            <button
              className="bg-red-500 text-white font-bold px-2 rounded-full"
              onClick={(event) => removeItem(event, item)}
            >x</button>
          </li>
        ))}
      </ol>
      <div className="mt-4">
        {isMultiSelect ? <button className="button rounded py-2 px-4 text-white bg-red-500" onClick={(event) => handleDone(event)}>Done</button> : ''}
      </div>
      <div className="mt-4" style={{ "display": filteredList.length === 0 ? "none" : "" }}>
        {filteredList.map((item, index) => (
          <button key={index} href="javascript.void(0)" className="py-2 px-4 border border-green-500 rounded" onClick={(event) => addItem(event, item)}>{item.description + " (" + item.code + ")"}</button>
        ))}
      </div>

    </form>
  )
};

export default ClinicProceduresCptSearch;