import { api } from '../../env/connection';
import { tokenProvider } from '../../providers/tokenProvider';

import { LocationsUnitsProvider } from '../../utils/unitUtils';

import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Select from '@material-ui/core/Select';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { GraphQLClient } from 'graphql-request'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';


import { Renderer } from 'xlsx-renderer';
import { saveAs } from "file-saver";

import { getMonths, getDay, sameMonth, daysInMonth } from './report-utils';
import * as DataUtils from './report-data-utils';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        marginRight: 0
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
        flexGrow: 1,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    flexContainer: {
        display: 'flex'
    },
    flexItem: {
        width: '100%',
        flexGrow: 1,
        margin: 8,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

async function getAnalyticsData(startDate, endDate) {

    try {
        const rawResponse = await fetch(`${api}/api/analytics/analytics_data`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokenProvider.token}`
            },
            body: JSON.stringify({ startDate: startDate.toISOString(), endDate: endDate.toISOString() })
        });
        const response = await rawResponse.json();
        // console.log(`getAnalyticsData response = `, response);

        return response.data;

    } catch (e) {
        console.log(`ERROR: `, e);
        return null;
    }
}


async function writeToTemplate(viewModel, selectedOption) {
    // let response = await fetch('https://public-hpm-portal-files.s3.us-east-2.amazonaws.com/TEMPLATE_HPM-Contribution-Margin_v2.xlsx');
    const urlToUse = selectedOption === "locations" ?
        'https://public-hpm-portal-files.s3.us-east-2.amazonaws.com/TEMPLATE_HPM-Contribution-Margin_v2.xlsx'
        :
        'https://public-hpm-portal-files.s3.us-east-2.amazonaws.com/TEMPLATE_HPM-Contribution-Margin_Providers.xlsx';

    let response = await fetch(urlToUse);

    let buffer = await response.arrayBuffer();
    const renderer = new Renderer();
    const report = await renderer.renderFromArrayBuffer(buffer, viewModel);

    let usedMonths = Object.keys(viewModel.monthGroups);

    // remove unused sheets
    let unusedMonths = DataUtils.monthNames.filter(month => !usedMonths.includes(month));
    for (const monthKey of unusedMonths) {
        // remove unused sheets
        report.removeWorksheet(monthKey);
    }

    for (const monthKey of usedMonths) {
        let monthGroup = viewModel.monthGroups[monthKey];
        // set the year for used sheets
        let sheet = report.getWorksheet(monthKey);
        sheet.name = `${monthGroup.name} ${monthGroup.year}`;
    }

    const output = await report.xlsx.writeBuffer();
    await saveAs(new Blob([output]), `HPM-Contribution-Margin-Report_${(new Date()).toLocaleDateString('en-US')}.xlsx`);
}

function writeToCSV(content) {
    let csvData = new Blob([content], { type: 'text/csv' });
    let csvUrl = URL.createObjectURL(csvData);
    let link = document.createElement("a");
    link.setAttribute("href", csvUrl);
    link.setAttribute("download", `HPM-Contribution-Margin-Report_${(new Date()).toLocaleDateString('en-US')}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
}

export default function ContributionMarginReportDialog() {
    const [open, setOpen] = React.useState(false);
    const [isBuilding, setIsBuilding] = React.useState(false);
    const [CSVSelected, setCSVSelected] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState("locations");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCSVClick = (event) => {
        setCSVSelected(event.target.checked);
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const classes = useStyles();

    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    async function buildReport() {
        setIsBuilding(true);

        console.log(`Building Report...`);

        selectedStartDate.setHours(0, 0, 0, 1);
        selectedEndDate.setHours(23, 59, 59, 0);

        // adjust query time to UTC offset, ticket dates are all UTC @ midnight w.r.t. TZ where they were created
        var offsetStartTime = new Date(selectedStartDate.getTime() - selectedStartDate.getTimezoneOffset() * 60 * 1000);
        const offsetEndTime = new Date(selectedEndDate.getTime() - selectedEndDate.getTimezoneOffset() * 60 * 1000);
        // var offsetEndTime = new Date(selectedEndDate.getTime() + selectedEndDate.getTimezoneOffset() * 60 * 1000);

        console.log(`START DATE = ${selectedStartDate}`);
        console.log(`EFEECTIVE START DATE = ${offsetStartTime}`);
        console.log(`END DATE = ${selectedEndDate}`);
        console.log(`EFFECTIVE END DATE = ${offsetEndTime}`)
        // console.log(`EFEECTIVE END DATE = ${offsetEndTime}`);

        // group dates into months
        let months = getMonths(offsetStartTime, offsetEndTime);
        // console.log(`selected months to group by = `, months);

        if (CSVSelected) {
            console.log(`Building Report...`)
            let csvData;
            try {
                let rawData = await getAnalyticsData(offsetStartTime, offsetEndTime);
                csvData = await buildReportData(months, rawData);
            } catch (e) {
                setIsBuilding(false);
                throw e;
            }

            console.log(`Writing report data to file...`);
            try {
                await writeToCSV(csvData);
            } catch (e) {
                setIsBuilding(false);
                throw e;
            }

            console.log(`Done!`);
        } else if (months.length > 12 && CSVSelected === false) {
            alert('Cannot select more than 12 months of data. Please adjust your time frame and try again.');
            setIsBuilding(false);
            return;
        } else {
            console.log(`Building Report...`);
            let reportData
            try {
                let rawData = await getAnalyticsData(offsetStartTime, offsetEndTime);
                reportData = await buildReportData(months, rawData);
                // console.log(`reportData = `, reportData);
            } catch (e) {
                setIsBuilding(false);
                throw e;
            }

            // reportData.metadata = {
            //     createdDate: `${new Date()}`,
            //     createdBy: `HPM Charge User`,
            //     dataSource1: `HPM Charge`,
            //     dataSource2: `Kareo`,
            //     startDate: `${selectedStartDate.toLocaleDateString('en-US')}`,
            //     endDate: `${selectedEndDate.toLocaleDateString('en-US')}`
            // };

            let usedMonths = Object.keys(reportData.monthGroups);
            for (const monthKey of usedMonths) {
                reportData[monthKey] = reportData.monthGroups[monthKey];
            }

            // console.log(`reportData (UPDATED) = `, reportData);

            console.log(`Writing report data to file...`);
            try {
                await writeToTemplate(reportData, selectedOption);
            } catch (e) {
                setIsBuilding(false);
                throw e;
            }

            console.log(`Done!`);
        }

        setIsBuilding(false);
        handleClose();
    }


    function getFeeData2(location, feeName, startDate, endDate) {
        let fee = location.facilityfees.find(x => x.feeName === feeName);

        if (fee) {
            // apply effective dates for fees
            let feeEffectiveDate = new Date(`${fee.effectiveDate}`);

            if (fee && (feeEffectiveDate > startDate) && sameMonth(feeEffectiveDate, startDate)) {
                // console.log(`${location.practiceName} :: ${fee.feeName} EffectiveDate = ${feeEffectiveDate}`);
                // calculate pro-rated amount
                let numDaysInMonth = daysInMonth(startDate.getMonth() + 1, startDate.getFullYear());
                let remainingDays = numDaysInMonth - feeEffectiveDate.getDate();
                fee.proRatedAmount = parseFloat(`${fee.amount}`) * (remainingDays / numDaysInMonth);
                console.log(`${location.practiceName} => ${fee.feeName} proRatedAmount = ${fee.proRatedAmount} (from ${fee.amount})`);
            }
            if (fee && (feeEffectiveDate > startDate) && !fee.proRatedAmount) {
                fee = null;
            }
        }

        return fee;
    }

    async function buildReportData(months, rawData) {

        await LocationsUnitsProvider.refreshData();

        const data = [];
        let monthGroups = {}; // Map<month,contribution>
        months.forEach(month => {

            let monthVal = parseInt(month.split('/')[0]);
            let monthName = `${DataUtils.monthNames[monthVal - 1]}`;
            let date = new Date(month);
            let lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            let isStartDateThisMonth = sameMonth(date, selectedStartDate);
            let isEndDateThisMonth = sameMonth(lastDayOfMonth, selectedEndDate);

            monthGroups[monthName] = {
                name: monthName,
                year: `${(new Date(month)).getFullYear()}`,
                number: monthVal,
                date: month,
                contributions: [],
                lastDayOfMonth: lastDayOfMonth.getDate(),
                startDate: isStartDateThisMonth ? getDay(selectedStartDate, '/') : getDay(date, '/'),
                endDate: isEndDateThisMonth ? getDay(selectedEndDate, '/') : getDay(lastDayOfMonth, '/')
            };

            let startDate = new Date(`${date}`);
            // startDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60 * 1000);
            // startDate.setSeconds(date.getSeconds() + 1); // date.setSeconds(1); // selectedStartDate
            let endDate = lastDayOfMonth; // selectedEndDate
            endDate.setHours(23, 59, 59, 0);

            console.log(`TICKETS START DATE = ${startDate}`);
            console.log(`TICKETS END DATE = ${endDate}`);

            // Locations or providers
            const dataSet = rawData[selectedOption];
            const contributionKey = selectedOption === "locations" ? "location" : "provider";

            // if (selectedOption === 'locations') {
                // map all of Daren Badura's tickets to a single user
                const darenUserTicket = rawData.chargeTickets.find(x => x.userId === 40);
                if (darenUserTicket) {
                    for (let tk of rawData.chargeTickets) {
                        if (`${tk.userId}` === '18') {
                            tk.userId = 40;
                            tk.user = darenUserTicket.user;
                        }
                    }
                }
            // }

            for (let i = 0; i < dataSet.length; i++) {

                const entity = dataSet[i];
                const contributionValue = selectedOption === "locations" ? `${entity.practiceName}` : `${entity.firstName} ${entity.lastName}`;

                if (selectedOption === 'users' && entity.id === 18)
                    { continue; }

                // Skip training and test related records
                if (entity.practiceName && (entity.practiceName === `DEFAULT` || entity.practiceName.toLowerCase().includes('test') || entity.practiceName.toLowerCase().includes('training'))) { continue; }

                if (entity.firstName && (entity.firstName.toLowerCase().includes('training') || entity.lastName.toLowerCase().includes('training') || entity.firstName.toLowerCase().includes('test') || entity.lastName.toLowerCase().includes('test'))) { continue; }

                const contribution = {
                    [contributionKey]: contributionValue,

                    // procedures
                    procedureUnits: 0,
                    procedureTotalPatients: 0,
                    procedureTotalDays: 0,
                    procedureDailyPatients: 0,

                    // clinic
                    clinicUnits: 0,
                    clinicalTotalPatients: 0,
                    clinicalTotalDays: 0,
                    clinicalDailyPatients: 0,

                    // revenue
                    revenueFromClinic: 0,
                    revenueFromProcedures: 0,
                    revenueFromManagement: 0,
                    revenueFromEquipment: 0,
                    revenueFromFlatRates: 0,
                    totalRevenue: 0,

                    // cost
                    directCostOfProcedures: 0,
                    directCostOfClinic: 0,
                    directCostOfEquipment: 0,
                    totalDirectCost: 0,

                    // contribution
                    contributionDifference: 0,
                    contributionPercent: 0
                };

                const facilityTickets = rawData.chargeTickets.filter(ticket => {
                    const matchingLocation = entity.practiceName ? `${ticket.location}`.toLowerCase().trim() === `${entity.practiceName}`.toLowerCase().trim() : false;
                    const matchingUser = entity.firstName ? `${ticket.user.id}` === `${entity.id}` : false;

                    const insideDateRange = new Date(`${ticket.date}`) > startDate && new Date(`${ticket.date}`) < endDate;
                    const notTraining = !ticket.patientName.toLowerCase().includes('training');

                    return (matchingLocation || matchingUser) && insideDateRange && notTraining
                });

                // Update contribution keys shared between location and provider templates
                DataUtils.buildSharedContributionData(facilityTickets, rawData, contribution, selectedOption, LocationsUnitsProvider);

                // Update contribution keys specific to locations
                if (selectedOption === "locations") {
                    DataUtils.buildLocationSpecificContributionData(entity, contribution, startDate, endDate);
                };

                monthGroups[monthName].contributions.push(contribution);
                data.push(contribution);
            }

            monthGroups[monthName].contributions.sort((a, b) => a[contributionKey].localeCompare(b[contributionKey]));
            data.sort((a, b) => a[contributionKey].localeCompare(b[contributionKey]));

        });

        if (CSVSelected) {
            const headers = Object.keys(data[0]);
            const csvRows = [];

            csvRows.push(headers.join(","));

            for (const row of data) {
                const values = headers.map(header => row[header]);
                csvRows.push(values.join(","));
            }

            const csvContent = csvRows.join("\n");

            return csvContent

        } else {
            console.log(`monthGroups = `, monthGroups);

            return {
                monthGroups: monthGroups
            }
        }
    }

    if (isBuilding) {
        return (
            <div>
                <Card className={classes.root}>
                    <CardActionArea onClick={handleClickOpen}>
                        <img src={require('../../images/Contribution-Margin-Report.png').default} />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Contribution Margins
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Calculates contribution margins by location.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>


                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Building Contribution Margin Report</DialogTitle>
                    <DialogContent>
                        <div className={classes.flexCenter}>
                            <CircularProgress />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

    return (
        <div>
            <Card className={classes.root}>
                <CardActionArea onClick={handleClickOpen}>
                    <img src={require('../../images/Contribution-Margin-Report.png').default} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Contribution Margins
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Calculates the contribution margins of each location over some date range.
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create Contribution Margin Report</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The Contribution Margin report shows the total contribution margin of locations or providers over a specified time.
                    </DialogContentText>
                    <FormGroup>
                        <FormControl>
                            <InputLabel id="select-label">Select Option</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                value={selectedOption}
                                onChange={handleOptionChange}
                            >
                                <MenuItem value="locations">Locations</MenuItem>
                                <MenuItem value="users">Providers</MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>
                    <div className={classes.flexContainer}>
                        <div className={classes.flexItem}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Start Date"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={classes.flexItem}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.flexItem}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="End Date"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <FormControlLabel control={<Checkbox
                        checked={CSVSelected}
                        onChange={handleCSVClick}
                    />}
                        label="CSV Export" />
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => buildReport()} color="primary" variant="contained">
                        Build Report
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
