import React, { useState, useEffect } from "react"

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

import { useQuery, useLazyQuery } from 'react-apollo';
import { gql } from "apollo-boost"
import { Renderer } from 'xlsx-renderer';
import { saveAs } from "file-saver";

import { dateFormatter } from './report-utils';
import ReportDataProvider from './report-data-provider';
import PermissionsProvider from "../../providers/permissions-provider";

const useStyles = makeStyles((theme) => ({
  root: {
      maxWidth: 345,
      marginRight: 0
  },
  media: {
      height: 140,
  },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      flexGrow: 1,
  },
  selectEmpty: {
      marginTop: theme.spacing(2),
  },
  flexContainer: {
      display: 'flex'
  },
  flexItem: {
      width: '100%',
      flexGrow: 1,
      margin: 8,
  },
  flexCenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
  },
}));

const GET_USERS = gql`
    query users {
        users {
            users {
                id
                firstName
                lastName
                email
                kareoId
				isActive
                roles {
                    id
					name
					# rolePermissions {
					# 	roleId
					# 	permissionId
					# 	permission {
					# 		id
					# 		code
					# 		description
					# 	}
					# }
                }
            }
        }
    }
`

export const GET_REFERRING_PROVIDERS = gql`
    query referringproviders(
        $startDate: DateTime!,
        $endDate: DateTime!,
        $userId: ID,
        $locationId: ID,
        $encounterType: String
    ) {
        referringproviders(
            startDate: $startDate,
            endDate: $endDate,
            userId: $userId,
            locationId: $locationId,
            encounterType: $encounterType
        ) {
            id
            name
            userId
            locationId
            encounterType
            createdDate
            user {
                id
                firstName
                lastName
            }
            location {
                id
                practiceName
            }
        }
    }
`;

const encounterOptions = ['Clinic', 'Procedure'];

async function writeToTemplate(viewModel) {
  let response = await fetch('https://public-hpm-portal-files.s3.us-east-2.amazonaws.com/TEMPLATE_HPM-Referring-ProvidersDEV.xlsx');
  let buffer = await response.arrayBuffer();
  const renderer = new Renderer();
  const report = await renderer.renderFromArrayBuffer(buffer, viewModel);
  const output = await report.xlsx.writeBuffer();
  await saveAs(new Blob([output]), `HPM-Referring_Provider_Report_${(new Date()).toLocaleDateString('en-US')}.xlsx`);
}

function writeToCSV(content) {
  let csvData = new Blob([content], { type: 'text/csv' });
  let csvUrl = URL.createObjectURL(csvData);
  let link = document.createElement("a");
  link.setAttribute("href", csvUrl);
  link.setAttribute("download", `HPM-Referring-Provider-Report_${(new Date()).toLocaleDateString('en-US')}.csv`);
  document.body.appendChild(link); // Required for FF

  link.click();
}

export default function ReferringProviderReportDialog() {
  const [open, setOpen] = React.useState(false);
  const [isBuilding, setIsBuilding] = React.useState(false);
  const [CSVSelected, setCSVSelected] = React.useState(false);

  const [locations, setLocations] = React.useState([]);
  const [location, setLocation] = React.useState('ALL');
  const [providers, setProviders] = React.useState([]);
  const [provider, setProvider] = React.useState('ALL');
  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());
  const [encounterType, setEncounterType] = React.useState('ALL');

  const classes = useStyles();
  const reportProvider = ReportDataProvider();

  const { locationLoading, locationError, locationData } = reportProvider.getKareoLocationsData('');

  const { loading: userDataLoading, error: userDataError, data: userData } = useQuery(GET_USERS);
  const [getReferringProviders, { loading, error, data }] = useLazyQuery(GET_REFERRING_PROVIDERS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data && data.referringproviders) {
        console.log("Returned referring providers: ", data.referringproviders);
        parseReferringProviderData(data.referringproviders);
      };
    }
  });

  useEffect(() => {
    if (locationData && locationData.length !== 0) {
      console.log('Referring-Providers-Report:: Locations Set');
      setLocations(locationData)
    }
  }, [locationData]);

  useEffect(() => {
      if (userData && userData.users) {
        let providerList = userData.users.users
            .filter(user => !PermissionsProvider.hasRole('Training') && !PermissionsProvider.hasRole('Corporate') && user.isActive)
            .sort((a, b) => {
                var textA = a.firstName.toUpperCase();
                var textB = b.firstName.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            })

        providerList = providerList.filter(x => x.value !== null);
        setProviders(providerList);
      }
  }, [userData])

	async function fetchReferringProviders() {
		try {
      const startDate = new Date(selectedStartDate);
      const endDate = new Date(selectedEndDate);

      startDate.setHours(0, 0, 0, 1);
      endDate.setHours(23, 59, 59, 0);

      const filter = {
        variables: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          userId: null,
          locationId: null,
          encounterType: null
        }
      };

      if (provider && provider != 'ALL') {
        filter.variables.userId = parseInt(provider);
      };

      if (location && location != 'ALL') {
        filter.variables.locationId = parseInt(location);
      };

      if (encounterType && encounterType != 'ALL') {
        filter.variables.encounterType = encounterType;
      };

			const result = await getReferringProviders(filter);
		} catch (error) {
			console.log("Error retrieving referring providers: ", error);
		}
	}

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCSVClick = (event) => {
    setCSVSelected(event.target.checked);
  };

  const handleLocationSelected = (event) => {
    setLocation(event.target.value);
  };

  const handleProviderSelected = (event) => {
    setProvider(event.target.value);
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const handleEncounterTypeSelected = (event) => {
    setEncounterType(event.target.value);
  };

  async function buildReport() {
    setIsBuilding(true);
    console.log(`Building Report...`);
    await fetchReferringProviders();
  };

function parseReferringProviderData(referringProviders) {
  const formattedProviders = [];
  for (let i = 0; i < referringProviders.length; i++) {
    const providerOptionSyntax = {
      facility: referringProviders[i].location?.practiceName,
      provider: `${referringProviders[i].user?.firstName ?? ''} ${referringProviders[i].user?.lastName ?? ''}`,
      type: referringProviders[i].encounterType,
      date: dateFormatter.format(new Date(referringProviders[i].createdDate)),
      referringProvider: referringProviders[i].name
    };

    formattedProviders.push(providerOptionSyntax);
  }

  console.log(`Writing report data to file...`);
  if (CSVSelected) {
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    const header = Object.keys(formattedProviders[0]).map(capitalize).join(',') + '\n';
    const rows = formattedProviders.map(obj =>
      Object.values(obj).map(value => {
        // Handle values with commas
        if (value && typeof value === 'string') {
          return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
      }).join(',')).join('\n');
    writeToCSV(header + rows);
  } else {
    writeToTemplate({ rows: formattedProviders });
  }
  console.log(`Done!`);

  setIsBuilding(false);
  handleClose();
};

  if (isBuilding) {
    return (
      <div>
        <Card className={classes.root}>
          <CardActionArea onClick={handleClickOpen}>
            <img src={require('../../images/Call-Log-Report.png').default} />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Referring Provider Report
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Provides referring providers associated with selected options.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>


        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Building Referring Provider Report</DialogTitle>
          <DialogContent>
            <div className={classes.flexCenter}>
              <CircularProgress />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  }

  return (
    <div>
      <Card className={classes.root}>
        <CardActionArea onClick={handleClickOpen}>
            <img src={require('../../images/Call-Log-Report.png').default} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Referring Provider Report
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Provides referring providers associated with selected options.
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Generate Referring Provider Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Report providers all referring providers associated with selected options.
          </DialogContentText>
          <div className={classes.flexContainer}>
            <FormControl className={classes.formControl}>
              <InputLabel id="location-label">Location</InputLabel>
              <Select
                labelId="location-selection-label"
                id="location-selection"
                value={location}
                onChange={handleLocationSelected}
              >
                <MenuItem value='ALL'>All Locations</MenuItem>
                {
                  locations.map((location, i) => <MenuItem value={location.id}>{location.practiceName}</MenuItem>)
                }
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="provider-label">Provider</InputLabel>
              <Select
                labelId="provider-selection-label"
                id="provider-selection"
                value={provider}
                onChange={handleProviderSelected}
              >
                <MenuItem value='ALL'>All Providers</MenuItem>
                {
                  providers.map((provider, i) => <MenuItem value={provider.id}>{`${provider.firstName} ${provider.lastName}`}</MenuItem>)
                }
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="encounter-type-label">Encounter Type</InputLabel>
              <Select
                labelId="encounter-type-selection-label"
                id="encounter-type-selection"
                value={encounterType}
                onChange={handleEncounterTypeSelected}
              >
                <MenuItem value='ALL'>All Encounters</MenuItem>
                {
                  encounterOptions.map((type, i) => <MenuItem value={type}>{type}</MenuItem>)
                }
              </Select>
            </FormControl>
          </div>
          <div className={classes.flexContainer}>
            <div className={classes.flexItem}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Start Date"
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
            <div className={classes.flexItem}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.flexItem}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="End Date"
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
            <FormControlLabel control={<Checkbox
                checked={CSVSelected}
                onChange={handleCSVClick}
            />}
                label="CSV Export" />
            <Button onClick={handleClose}>
                Cancel
            </Button>
            <Button onClick={() => buildReport()} color="primary" variant="contained">
                Generate Report
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};