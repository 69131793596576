import React, { Component } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import Card from '../components/card'

export default class FacilityList extends Component {

    OnDragEnd = async (result, updateFacility) => {
        const { destination, source, draggableId } = result

        if (!destination) {
            return
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return
        }

        let newFacilities = this.props.facilities

        await this.props.facilities.map(async (facility, index) => {
            if (facility.id === draggableId) {
                facility.sequence = destination.index
            }
            else if (facility.sequence <= destination.index && facility.sequence > source.index) {
                facility.sequence = facility.sequence - 1
            }
            else if (facility.sequence >= destination.index && facility.sequence < source.index) {
                facility.sequence = facility.sequence + 1
            }

            newFacilities[index] = facility
	    delete facility["__typename"]
            const result = await updateFacility({ variables: { facility: facility } })
            console.log(JSON.stringify(result))
            return
        })

        this.props.setFacilities(newFacilities)
        return
    }

    render = () => {
        if (this.props.facilities) {
            return (
                <div>
                    <div className="flex bg-white border-solid border-b border-black mt-4 max-w-sm overflow-hidden w-full lg:max-w-full lg:flex">
                        <div className="grid grid-cols-11 gap-8 px-4 py-4">
                            <div className="text-center font-bold text-lg">Name</div>
                            <div className="text-center font-bold text-lg">Status</div>
                            <div className="text-center font-bold text-lg">NDA</div>
                            <div className="text-center font-bold text-lg">Proforma</div>
                            <div className="text-center font-bold text-lg">CDM</div>
                            <div className="text-center font-bold text-lg">Contract Sent to Rob</div>
                            <div className="text-center font-bold text-lg">Contract Sent to CEO</div>			    
                            <div className="text-center font-bold text-lg">Contract</div>
                            <div className="text-center font-bold text-lg">Rate</div>
                            <div className="text-center font-bold text-lg">Start Date</div>
                            <div className="text-center font-bold text-lg">Notes</div>
                        </div>
                    </div>
                    <DragDropContext
                        onDragEnd={async result => await this.OnDragEnd(result, this.props.updateFacility)}
                    >
                        <Droppable droppableId="1">
                            {provided => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {
                                        this.props.facilities.sort(function (a, b) {
                                            return a.sequence - b.sequence
                                        }).map((facility) => {
                                            return (
                                                <div key={facility.id}>
                                                    <Card updateFacility={this.props.updateFacility} setSelectedFacility={this.props.setSelectedFacility} facility={facility} />
                                                </div>
                                            )
                                        })
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            )
        }
        return <h2>Loading...</h2>
    }
}
