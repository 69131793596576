import React, { Component } from 'react'
import { getUser } from "../services/auth"
import { StyleSheet, Document, Page, Text, View } from '@react-pdf/renderer'
import { getUnits, LocationsUnitsProvider } from '../utils/unitUtils';

var pdf

const styles = StyleSheet.create({
    page: { padding: 20, width: "auto" },
    view: { margin: 5, fontSize: 14 },
    text: { margin: 2 },
    name: { margin: 2, fontWeight: 600 },
    table: { marginTop: 25, display: "table", width: "auto", borderStyle: "none", borderWidth: 0, borderRightWidth: 0, borderBottomWidth: 0 },
    tableRow: { margin: "auto", flexDirection: "row" },
    tableCol: { width: "25%" },
    tableTotalCol: { marginTop: 5, width: "25%" },
    tableHeaderCell: { margin: "auto", marginTop: 5, marginBottom: 5, fontSize: 14, borderStyle: "solid", borderWidth: 0, borderBottomWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableCell: { margin: "auto", marginTop: 5, fontSize: 12 }
})

const dateFormatter = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC'
})

const sortCpts = (ticket) => {
    var cpts = []
    ticket.map(cpt => (
        cpts[cpt.sequence] = cpt
    ))
    return cpts
}

const TableHeader = (props) => {
    
    if (props.user.role === "1") {
        return (
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableHeaderCell}>Sequence</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableHeaderCell}>Description</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableHeaderCell}>CPT Code</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableHeaderCell}>Billing Units</Text>
                </View>
            </View>
        )
    }
    return (
        <View style={styles.tableRow}>
            <View style={styles.tableCol}>
                <Text style={styles.tableHeaderCell}>Sequence</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableHeaderCell}>Description</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableHeaderCell}>CPT Code</Text>
            </View>
        </View>
    )
}

const TotalRow = (props) => {
    if (props.user.role === "1") {
        return (
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                </View>
                <View style={styles.tableCol}>
                </View>
                <View style={styles.tableTotalCol}>
                    <Text style={styles.tableCell}>Total:</Text>
                </View>
                <View style={styles.tableTotalCol}>
                    {/* <Text style={styles.tableCell}>{(props.total > 40) ? (`40 (${40 - props.total})`) : props.total}</Text> */}
                    <Text style={styles.tableCell}>{props.total}</Text>
                </View>
            </View>
        )
    }
    return (
        <View></View>
    )
}

const TableRow = (props) => {
    if (props.user.role === "1") {
        return (
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{props.chargeTicketCpt.sequence}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{props.chargeTicketCpt.cpt.description}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{props.chargeTicketCpt.cpt.code}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{props.units}</Text>
                </View>
            </View>
        )
    }
    else {
    return (
        <View style={styles.tableRow}>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{props.chargeTicketCpt.sequence}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{props.chargeTicketCpt.cpt.description}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{props.chargeTicketCpt.cpt.code}</Text>
            </View>
        </View>
    )
}
}

const ChargeTicket = (props) => {
    let total = 0
    const user = getUser();

    // const locationUnitsProvider = LocationsUnitsProvider.getInstance();
    LocationsUnitsProvider.refreshData();
    const locationUnitsProvider = LocationsUnitsProvider;

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.view}>
                    <Text style={styles.name}>{props.ticket.patientName}</Text>
                    <Text style={styles.text}>Date: {dateFormatter.format(new Date(props.ticket.date))}</Text>
                    <Text style={styles.text}>Transaction ID: {props.ticket.id}</Text>
                    <Text style={styles.text}>Location: {props.ticket.location}</Text>
                    <Text style={styles.text}>Provider: {props.ticket.user.firstName} {props.ticket.user.lastName}</Text>
                    <Text style={styles.text}>MRN: {props.ticket.mrn}</Text>
                    <Text style={styles.text}>Insurance: {props.ticket.insurance}</Text>
                    <Text style={styles.text}>Procedures: {(props.ticket.procedures) === 'null' ? '' : props.ticket.procedures}</Text>
                </View>
                <View style={styles.view}></View>
                <View style={styles.table}>
                    <TableHeader user={user} />
                    {(JSON.stringify(props.ticket.chargeTicketCpts).includes("\"code\":\"99") ? (sortCpts(props.ticket.chargeTicketCpts).map((chargeTicketCpt) => {
                        
                        const isClinic = JSON.stringify(props.ticket.chargeTicketCpts).includes("\"code\":\"99")
                        const providerType = props.ticket.user.roles[0].id === "3" ? "CRNA" : "PA"
                        
                        // let units = (chargeTicketCpt.cpt.code.startsWith("99") ? locationUnitsProvider.getUnits(props.ticket, chargeTicketCpt, providerType, props.ticket.location, isClinic) : 0)
                        let units = locationUnitsProvider.getUnits(props.ticket, chargeTicketCpt, providerType, props.ticket.location, isClinic);
                        total += units;
                        return (chargeTicketCpt.cpt.code.startsWith("99") || chargeTicketCpt.cptType === 'ClinicProcedure' ? (
                            <TableRow user={user} units={units} chargeTicketCpt={chargeTicketCpt} />
                        ) : null
                        )
                    }))
                        : (sortCpts(props.ticket.chargeTicketCpts).map((chargeTicketCpt) => {

                            const isClinic = JSON.stringify(props.ticket.chargeTicketCpts).includes("\"code\":\"99")
                            const providerType = props.ticket.user.roles[0].id === "3" ? "CRNA" : "PA"

                            let units = locationUnitsProvider.getUnits(props.ticket, chargeTicketCpt, providerType, props.ticket.location, isClinic)
                            total += units
                            return (
                                <TableRow user={user} units={units} chargeTicketCpt={chargeTicketCpt} />
                            )
                        })))}
                    <TotalRow user={user} total={locationUnitsProvider.applyUnitCap(total, props.ticket.location)} />
                </View>
            </Page >
        </Document >
    )
}

const handleDownload = async (props) => {
    pdf.save()
}

export default class Ticket extends Component {

    componentDidMount = () => {
        if (this.props.download) {
            handleDownload(this.props)
        }
    }

    render = () => {
        return (
            <ChargeTicket ticket={this.props.ticket} />
        )
    }
}