import React, { Component } from 'react'

export default class FacilityModal extends Component {

    save = async () => {
        let newFacility = { name: this.state.name, state: this.state.state, sequence: this.props.facilities.length + 1 }
        await this.props.setFacilities(null)
        await this.props.setShow(false)
        await this.props.createFacility({ variables: { facility: newFacility } })
        await this.props.refetch()
            .then((data) => this.props.setFacilities(data.data.facilitys.facilities))
        return true
    }

    render = () => {
        if (this.props.show) {
            return (
                <div className="w-full max-w-xs">
                    <div style={{
                        position: "fixed",
                        zIndex: "1040",
                        top: "0",
                        bottom: "0",
                        left: "0",
                        right: "0",
                        backgroundColor: "#000",
                        opacity: "0.5"
                    }}></div>
                    <div style={{ zIndex: "1040", position: "absolute" }} className="bg-white max-w-sm rounded overflow-hidden shadow-lg px-8 pt-6 pb-8 mb-4">
                        <div className="px-4 py-4">
                            <div className="mb-4">
                                <p className="block text-gray-700 text-sm font-bold mb-2">
                                    Name
                                </p>
                                <input onChange={(e) => this.setState({ name: e.target.value })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" aria-label="Facility Name" id="name" type="text" placeholder="Facility Name" />
                            </div>
                            <div className="mb-4">
                                <p className="block text-gray-700 text-sm font-bold mb-2">
                                    State
                                </p>
                                <input onChange={(e) => this.setState({ state: e.target.value })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" aria-label="Facility State" id="state" type="text" placeholder="Facility State" />
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <button onClick={() => this.save()} className="bg-green-500 hover:bg-green-300 text-white font-bold py-2 px-4 m-2 rounded">Save</button>
                            <button onClick={() => this.props.setShow(false)} className="bg-red-500 hover:bg-red-300 text-white font-bold py-2 px-4 m-2 rounded">Cancel</button>
                        </div>
                    </div>
                </div>
            )
        }

        return null
    }
}