import { gql } from "apollo-boost";
import { GraphQLClient } from 'graphql-request';
import { api } from '../../env/connection';
import { tokenProvider } from '../../providers/tokenProvider';

export class KareoStatus {
    static UNKNOWN = 'Unknown';
    static SCHEDULED = 'Scheduled';
    static CHECKEDOUT = 'CheckedOut'; // 'Check-out';
    static CHECK_OUT = 'Check-out';
    static CANCELLED = 'Cancelled';
    static RESCHEDULED = 'Rescheduled';
    static NOSHOW = 'NoShow'; // 'No-show';
    static NO_SHOW = 'No-show';
}

export default function ChargeSheetDataProvider() {

    console.log(`[ChargeSheetDataProvider] :: INIT...`);
    // const client = new GraphQLClient(`${api}/`, {
    //     headers: {
    //         'Authorization': `Bearer ${tokenProvider.token}`
    //     },
    // });

    // kareo
    const key = "KAREO_KEY";
    const password = "KAREO_PASSWORD";
    const username = "KAREO_USERNAME";
    // // const appointments = useQuery(GET_APPOINTMENTS, { variables: { xml: getXml(date), url: url, headers: JSON.stringify(apptsHeaders) } })

    // const key = process.env.KAREO_KEY
    // const password = process.env.KAREO_PASSWORD
    // const username = process.env.KAREO_USERNAME

    const GET_CHARGE_TICKETS = gql`
    query chargeTickets ($filter: String!, $skip: Int!, $take: Int!) {
        chargeTickets (filter: $filter, orderBy: {date: asc}, skip: $skip, take: $take) {
            count
            chargeTickets {
                id
                kareoApptId
                user {
                    id
                    firstName
                    lastName
                    kareoId
                    roles {
                        id
                    }
                }
                date
                createdDate
                modifiedDate
                location
                serviceLocationName
                patientName
                mrn
                insurance
                procedures
                chargeTicketCpts {
                    cpt {
                        id
                        code
                        primaryUnits
                        secondaryUnits
                        class
                    }
                    sequence
                }
            }
        }
    }`;

    async function getTickets(providerId, startDate, endDate) {
        console.log(`[ChargeSheetDataProvider] :: tokenProvider.token = `, tokenProvider.token);
        const client = new GraphQLClient(`${api}/`, {
            headers: {
                'Authorization': `Bearer ${tokenProvider.token}`
            },
        });

        let start = new Date(`${startDate}`);
        let end = new Date(`${endDate}`);
        let filter = {
            AND:
                [
                    {
                        date: {
                            lt: `${end.toISOString()}`
                            // lt: `${end.toISOString().split('T')[0]}T23:59:59.001Z`
                        }
                    },
                    {
                        date: {
                            gte: `${start.toISOString().split('T')[0]}T00:00:00.001Z`
                        }
                    }
                ]
        };

        filter.userId = providerId;

        // console.log(`ProviderId = ${providerId}, Start = ${start.toISOString()}, End = ${end.toISOString()}`);
        console.log(`ProviderId = ${providerId}, Start = ${start.toISOString().split('T')[0]}T00:00:00.001Z, End = ${end.toISOString().split('T')[0]}T23:59:59.001Z`);

        let tickets = [];
        try {
            console.log(`[ChargeSheetDataProvider] :: client.headers = `, client.headers);
            const data = await client.request(GET_CHARGE_TICKETS, {
                filter: JSON.stringify(filter),
                skip: 0,
                take: 10000
            });
            if (data && data.chargeTickets && data.chargeTickets.chargeTickets) {
                tickets = data.chargeTickets.chargeTickets;
            }

        } catch (err) {
            console.error(`Error fetching Charge Tickets: `, err);
        }

        console.log(`[ChargeSheetDataProvider] :: getTickets( ${providerId}, ${startDate}, ${endDate}) => `, tickets);

        return tickets;
    }

    return {
        getTickets: getTickets
    };
}

