import { LocationsUnitsProvider } from '../../utils/unitUtils';
import { getManyTickets } from './report-data-utils';
import * as DataUtils from './report-data-utils';
import { Link } from "gatsby";

import React, { useState, useEffect } from 'react';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';


import { useQuery } from 'react-apollo';
import { GET_KAREO_LOCATIONS } from '../queries/queries';
import { Box, CircularProgress, Divider, IconButton, ListItemText, TextField, Tooltip } from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { merge } from '../../utils/arrayUtils';

const orderTypes = [
    'Blind Inj',
    'EMG',
    'Pro',
    'Imaging',
    'Labs',
    'PT',
];

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        marginRight: 0
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
        flexGrow: 1,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    flexContainer: {
        display: 'flex'
    },
    flexItem: {
        // width: '100%',
        // flexGrow: 1,
        margin: '0 8px',
        width: '200px',
        maxWidth: '200px',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    aboveAvgText: {
        color: '#1B8D3D', // '#3FB56C',
        background: '#E8F6ED'
    },
    belowAvgText: {
        color: '#CC0000',
        background: '#F9E0E0'
    },
}));

function writeToTemplate(groups) {

    if (!groups.length) { alert('No data to download.'); return; }

    let csvContent = '';
    if (groups[0].state) {
        for (let group of groups) {
            csvContent += `${group.state}`;
            for (let subGroup of group.subGroups) {
                csvContent += `,${subGroup.data.name},1 - top level,${subGroup.complexityCounts[0]}\n`;
                csvContent += `,,2 - mid level,${subGroup.complexityCounts[1]}\n`;
                csvContent += `,,3 - bottom level,${subGroup.complexityCounts[2]}\n`;
            }
        }
    }
    else {
        for (let subGroup of groups[0].subGroups) {
            csvContent += `${subGroup.data.name},1 - top level,${subGroup.complexityCounts[0]}\n`;
            csvContent += `,2 - mid level,${subGroup.complexityCounts[1]}\n`;
            csvContent += `,3 - bottom level,${subGroup.complexityCounts[2]}\n`;
        }
    }

    csvContent = csvContent.replace(/""/g, '');
    // console.log(`csvContent = `, csvContent);

    let csvData = new Blob([csvContent], { type: 'text/csv' });
    let csvUrl = URL.createObjectURL(csvData);
    let link = document.createElement("a");
    link.setAttribute("href", csvUrl);
    link.setAttribute("download", `HPM-Order-Complexity-Report_${(new Date()).toLocaleDateString('en-US')}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
}


export default function ComplexityReport() {
    const [isBuilding, setIsBuilding] = React.useState(false);
    const [isReady, setIsReady] = React.useState(false);
    const [trainingChecked, setTrainingChecked] = React.useState(false);
    const [data, setData] = React.useState([]);

    const kareoLocationsQuery = useQuery(GET_KAREO_LOCATIONS, { variables: { filter: "" } })
    const [kareoFacilityStatePairs, setKareoFacilityStatePairs] = useState({});

    const [orderedProcTypes, setOrderedProcTypes] = React.useState(orderTypes);
    const [allTypesChecked, setAllTypesChecked] = React.useState(true);
    const [groupBy, setGroupBy] = React.useState('Provider');

    const [globalAverages, setGlobalAverages] = React.useState([0, 0, 0]);
    const [globalStds, setGlobalStds] = React.useState([0, 0, 0]);
    const [totals, setTotals] = React.useState([0, 0, 0]);
    const [grandTotal, setGrandTotal] = React.useState(0);

    function handleChange(event) {
        const {
            target: { value },
        } = event;

        if (value.includes('All')) {
            handleAllChecked();
        } else {
            setAllTypesChecked(false);
            setOrderedProcTypes(value);
        }

        if (value.sort().join(',') === orderTypes.sort().join(',')) {
            setAllTypesChecked(true);
        }
    };

    function handleAllChecked() {
        let val = !allTypesChecked;
        setAllTypesChecked(val);

        if (val) {
            setOrderedProcTypes(orderTypes);
        } else {
            setOrderedProcTypes([]);
        }
    }

    useEffect(() => {
        getKareoLocationPairs();
    }, [])

    async function getKareoLocationPairs() {
        if (kareoLocationsQuery) {
            try {
                const response = await kareoLocationsQuery.refetch()
                const locationPairs = response.data.kareoLocations
                    .filter(x => x.id !== "0")
                    .reduce((pairs, current) => {
                        pairs[current.practiceName.trim()] = current.facilityState;
                        return pairs;
                    }, {});
                // console.log(`locationPairs = `, locationPairs);
                setKareoFacilityStatePairs(locationPairs);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const classes = useStyles();

    const [selectedStartDate, setSelectedStartDate] = useState(dayjs(new Date()));
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const [selectedEndDate, setSelectedEndDate] = useState(dayjs(new Date()));
    // const [selectedEndDate, setSelectedEndDate] = useState(dayjs(new Date()));
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    async function buildReport() {
        setIsBuilding(true);

        await LocationsUnitsProvider.refreshData();

        console.log(`Building Report...`);

        // selectedStartDate.setHours(0, 0, 0, 1);
        // selectedEndDate.setHours(23, 59, 59, 0);

        const startDate = selectedStartDate.toDate();
        startDate.setHours(0, 0, 0, 1);
        const endDate = selectedEndDate.toDate();
        endDate.setHours(23, 59, 59, 0);

        // adjust query time to UTC offset, ticket dates are all UTC @ midnight w.r.t. TZ where they were created
        var offsetTime = new Date(startDate.getTime() - endDate.getTimezoneOffset() * 60 * 1000);

        console.log(`START DATE = ${startDate}`);
        console.log(`EFEECTIVE START DATE = ${offsetTime}`);
        console.log(`END DATE = ${endDate}`);

        console.log(`kareoFacilityStatePairs = `, kareoFacilityStatePairs);

        let reportData
        try {
            let chargeTickets = await getManyTickets(offsetTime, endDate);
            for (let i = 0; i < chargeTickets.length; i++) {
                const currentFacility = chargeTickets[i].location.trim();
                chargeTickets[i]["state"] = kareoFacilityStatePairs[currentFacility];
            }

            reportData = await buildReportData(chargeTickets);
            // console.log(`reportData = `, reportData);
        } catch (e) {
            setIsBuilding(false);
            throw e;
        }

        console.log(`Done!`);

        setIsBuilding(false);
    }

    function buildReportData(tickets) {

        console.log(`Building report for (${tickets.length}) charge tickets...`);

        let chargeTickets = tickets;
        if (!trainingChecked) {
            // console.log(`charge tickets (${chargeTickets.length}) =`, chargeTickets);
            chargeTickets = tickets.filter(x =>
                !x.location.toLowerCase().includes('training')
                && !x.patientName.toLowerCase().includes('training')
                && !x.location.toLowerCase().includes('test')
                && !x.patientName.toLowerCase().includes('test')
            );
            // console.log(`charge tickets without training data (${chargeTickets.length}) =`, chargeTickets);
        }

        let clinicTickets = chargeTickets.filter(x => JSON.stringify(x.chargeticketcpt).includes("\"code\":\"99"));
        console.log(`Building report for (${clinicTickets.length}) CLINIC charge tickets...`);

        clinicTickets.forEach(ticket => {

            ticket.orders = {
                Labs: false,
                Imaging: false,
                BlindInj: false,
                PT: false,
                EMG: false,
                Pro: false
            };

            ticket.chargeticketcpt.forEach(chargeTicketCpt => {
                if (`${chargeTicketCpt.cpt.id}` === '149') ticket.orders.Labs = true; // ticket.labOrd = true;
                if (`${chargeTicketCpt.cpt.class}` === 'MED IMAGING') ticket.orders.Imaging = true; // ticket.imageOrd = true;
                if (`${chargeTicketCpt.cpt.id}` === '161') ticket.orders.BlindInj = true; // ticket.blindInjOrd = true;
                if (`${chargeTicketCpt.cpt.id}` === '150') ticket.orders.PT = true; // ticket.ptOrd = true;
                if (`${chargeTicketCpt.cpt.id}` === '300') ticket.orders.EMG = true; // ticket.emgOrd = true;
                if (`${chargeTicketCpt.cpt.class}` === 'CRNA PAIN MANAGEMENT PROCEDURES') ticket.orders.Pro = true; // ticket.procOrd = true;
            });

        });

        // let filteredTickets = clinicTickets;
        // if (!orderedProcTypes.includes('Labs')) { filteredTickets = filteredTickets.filter(t => !t.orders.Labs); }
        // if (!orderedProcTypes.includes('Imaging')) { filteredTickets = filteredTickets.filter(t => !t.orders.Imaging); }
        // if (!orderedProcTypes.includes('Blind Inj')) { filteredTickets = filteredTickets.filter(t => !t.orders.BlindInj); }
        // if (!orderedProcTypes.includes('PT')) { filteredTickets = filteredTickets.filter(t => !t.orders.PT); }
        // if (!orderedProcTypes.includes('EMG')) { filteredTickets = filteredTickets.filter(t => !t.orders.EMG); }
        // if (!orderedProcTypes.includes('Pro')) { filteredTickets = filteredTickets.filter(t => !t.orders.Pro); }

        let filteredTicketIds = [];
        if (orderedProcTypes.includes('Pro')) {
            filteredTicketIds = merge(filteredTicketIds, clinicTickets.filter(t => t.orders.Pro).map(t => t.id));
        }
        if (orderedProcTypes.includes('Labs')) {
            filteredTicketIds = merge(filteredTicketIds, clinicTickets.filter(t => t.orders.Labs).map(t => t.id));
        }
        if (orderedProcTypes.includes('Imaging')) {
            filteredTicketIds = merge(filteredTicketIds, clinicTickets.filter(t => t.orders.Imaging).map(t => t.id));
        }
        if (orderedProcTypes.includes('BlindInj')) {
           filteredTicketIds = merge(filteredTicketIds, clinicTickets.filter(t => t.orders.BlindInj).map(t => t.id));
        }
        if (orderedProcTypes.includes('PT')) {
            filteredTicketIds = merge(filteredTicketIds, clinicTickets.filter(t => t.orders.PT).map(t => t.id));
        }
        if (orderedProcTypes.includes('EMG')) {
            filteredTicketIds = merge(filteredTicketIds, clinicTickets.filter(t => t.orders.EMG).map(t => t.id));
        }


        let filteredTickets = clinicTickets.filter(t => filteredTicketIds.includes(t.id));

        let stateProviderGroups = [];
        let complexityValues = [[], [], []];
        let groupById = groupBy.includes('Provider') ? 'userId' : 'locationId';

        if (groupBy.startsWith('State')) {
            // group tickets by state
            const stateGroups = DataUtils.groupBy(filteredTickets, 'state');
            // console.log(`stateGroups = `, stateGroups);

            for (let state of Object.keys(stateGroups)) {

                let stateTickets = stateGroups[state];
                let subGroups = subGroupTickets(stateTickets, groupById);

                stateProviderGroups.push({
                    state: state,
                    subGroups: subGroups
                });
            }

            stateProviderGroups.sort((a,b) => a.state.localeCompare(b.state));
        }
        else {
            let subGroups = subGroupTickets(filteredTickets, groupById);

            stateProviderGroups.push({
                state: undefined,
                subGroups: subGroups
            });
        }

        // console.log(`stateProviderGroups = `, stateProviderGroups);
        setIsReady(true);
        setData(stateProviderGroups);


        // compute means, sigmas
        stateProviderGroups.forEach(group => {
            group.subGroups.forEach(subGroup => {
                if (subGroup.complexityCounts[0]) { complexityValues[0].push(subGroup.complexityCounts[0]); }
                if (subGroup.complexityCounts[1]) { complexityValues[1].push(subGroup.complexityCounts[1]); }
                if (subGroup.complexityCounts[2]) { complexityValues[2].push(subGroup.complexityCounts[2]); }
            });
        });

        console.log(`complexityValues = `, complexityValues);

        let total1 = 0;
        let total2 = 0;
        let total3 = 0;
        if (complexityValues[0].length) { total1 = complexityValues[0].reduce((total, next) => total + next, 0); }
        if (complexityValues[1].length) { total2 = complexityValues[1].reduce((total, next) => total + next, 0); }
        if (complexityValues[2].length) { total3 = complexityValues[2].reduce((total, next) => total + next, 0); }        

        setTotals([total1, total2, total3]);
        setGrandTotal(total1 + total2 + total3);

        let average1 = total1 / complexityValues[0].length;
        let average2 = total2 / complexityValues[1].length;
        let average3 = total3 / complexityValues[2].length;
        setGlobalAverages([average1, average2, average3]);
        // console.log(`globalAverages = `, globalAverages);

        let std1 = 0;
        let std2 = 0;
        let std3 = 0;
        if (complexityValues[0].length) { std1 = Math.sqrt(complexityValues[0].map(x => Math.pow(x - average1, 2)).reduce((a, b) => a + b) / complexityValues[0].length); }
        if (complexityValues[1].length) { std2 = Math.sqrt(complexityValues[1].map(x => Math.pow(x - average2, 2)).reduce((a, b) => a + b) / complexityValues[1].length); }
        if (complexityValues[2].length) { std3 = Math.sqrt(complexityValues[2].map(x => Math.pow(x - average3, 2)).reduce((a, b) => a + b) / complexityValues[2].length); }
        setGlobalStds([std1, std2, std3]);
        // console.log(`globalStds = `, globalStds);

    }

    function downloadData() {
        if (data && data.length) {
            console.log(`Writing report data to file...`);
            try {
                writeToTemplate(data);
            } catch (e) {
                setIsBuilding(false);
                throw e;
            }
        }
    }

    function subGroupTickets(tickets, groupingId) {
        // group tickets by provider
        const subGroups = DataUtils.groupBy(tickets, groupingId); // 'userId');
        console.log(`userGroups = `, subGroups);

        let groups = [];
        for (let subGroupId of Object.keys(subGroups)) {
            let groupTickets = subGroups[subGroupId];

            if (groupTickets.length) {
                let groupOwner = groupingId === 'userId' ? groupTickets[0].user : { locationId: groupTickets[0].locationId, practiceName: groupTickets[0].location };
                groupOwner.name = groupingId === 'userId' ? `${groupOwner.firstName} ${groupOwner.lastName}` : groupOwner.practiceName;
                groupOwner.name = groupOwner.name.replace(/,/g, '');

                let complexityCounts = [0, 0, 0];
                let complexityCountTickets = [[], [], []];
                for (let ticket of groupTickets) {
                    // let procCpts = ticket.chargeticketcpt.filter(x => !x.cpt.class.toLowerCase().startsWith('pain mgmt clin'));
                    // if (procCpts.length <= 0) { continue; }

                    // let procCpts = ticket.chargeticketcpt.filter(x => x.cpt.class.toLowerCase().startsWith('crna'));
                    // if (procCpts.length <= 0) { continue; }

                    // if (ticket.orders !== 'Pro') { continue; }
                    
                    let topComplexity = 100;
                    for (let ctCpt of ticket.chargeticketcpt) {
                        if (ctCpt.sequence === 1) { continue; } // don't include the clinic 99- code
                        if (!!ctCpt.cpt.clinicComplexity && ctCpt.cpt.clinicComplexity < topComplexity) {
                            topComplexity = ctCpt.cpt.clinicComplexity;
                        }
                    }
                    if (topComplexity !== 100) {
                        complexityCounts[topComplexity - 1]++;
                        complexityCountTickets[topComplexity - 1].push(ticket);
                    }
                    
                }

                console.log(`complexityCounts = `, {
                    groupOwner: groupOwner,
                    complexityCountTickets: complexityCountTickets
                });

                groups.push({
                    data: groupOwner,
                    tickets: groupTickets,
                    complexityCounts: complexityCounts
                });
            }

        }        

        groups.sort((a,b) => a.data.name.localeCompare(b.data.name));

        console.log(`sub groups = `, groups);

        return groups;
    }

    return (

        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>

                    <IconButton>
                        <Link to="..">
                            <ArrowBackIcon />
                        </Link>
                    </IconButton>

                    <Box sx={{ fontSize: '1.2rem' }}>Complexity Report</Box>
                </Box>
                <Box sx={{ padding: '0 8px' }}>
                    The complexity report shows the percentages of ordered procedures by clinic providers based on complexity levels for the selected filters.
                </Box>
            </Box>
            <Card>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', padding: 8 }}>
                    {/* <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={trainingChecked}
                            onChange={handleTrainingChange}
                        />}
                            label="Include Training Tickets?" />
                    </FormGroup> */}

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', padding: 8 }}>

                        <Box className={classes.flexItem}>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date"
                                    inputVariant="outlined"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                />
                            </LocalizationProvider>

                        </Box>

                        <Box className={classes.flexItem}>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date"
                                    inputVariant="outlined"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                />
                            </LocalizationProvider>

                        </Box>

                        <Box className={classes.flexItem}>
                            <TextField
                                select
                                label="Group By"
                                value={groupBy}
                                onChange={evt => setGroupBy(evt.target.value)}
                                variant="outlined"
                                sx={{ width: '100%' }}
                                fullWidth
                            >
                                <MenuItem key='Provider' value='Provider'>Provider</MenuItem>
                                <MenuItem key='StateProvider' value='StateProvider'>State, Provider</MenuItem>
                                <MenuItem key='Location' value='Location'>Location</MenuItem>
                                <MenuItem key='StateLocation' value='StateLocation'>State, Location</MenuItem>
                            </TextField>
                        </Box>

                        <Box className={classes.flexItem}>

                            <TextField
                                select
                                label="Ordered Procedure"
                                variant="outlined"
                                sx={{ width: '100%' }}
                                fullWidth
                                SelectProps={{
                                    multiple: true,
                                    value: orderedProcTypes,
                                    onChange: handleChange,
                                    renderValue: (selected) => selected.map((x) => x).join(', '),
                                    MenuProps: {
                                        variant: 'menu'
                                    },
                                }}
                            >
                                <MenuItem key='All' value='All' onClick={handleAllChecked}>
                                    <Checkbox
                                        checked={allTypesChecked}
                                        onChange={handleAllChecked}
                                    />
                                    <ListItemText primary='All Types' />
                                </MenuItem>
                                <Divider></Divider>
                                {orderTypes.map(type =>
                                    <MenuItem key={type} value={type}>
                                        <Checkbox
                                            checked={
                                                orderedProcTypes.findIndex((item) => item === type) >= 0
                                            }
                                        />
                                        <ListItemText primary={type} />
                                    </MenuItem>
                                )}
                            </TextField>

                        </Box>

                    </Box>

                    <Box sx={{ flex: '1 1 auto' }}></Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '8px' }}>
                        <Tooltip title='Download CSV'>
                            <IconButton disabled={!isReady} onClick={() => downloadData()}>
                                <GetAppIcon />
                            </IconButton>
                        </Tooltip>

                        <Button disabled={!orderedProcTypes || isBuilding} onClick={() => buildReport()} color="primary" variant="contained">
                            Build Report
                        </Button>
                    </Box>

                </Box>
            </Card >

            {isBuilding &&
                <Box sx={{ flex: '1 1 auto', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '500px' }}>
                    <CircularProgress />
                </Box>
            }

            {
                !isReady && !isBuilding &&
                <Box sx={{ flex: '1 1 auto', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '500px' }}>
                    <Box>Build report to get started</Box>
                </Box>
            }

            {
                isReady && !isBuilding &&

                <Box sx={{ display: 'flex', flexDirection: 'column', padding: '8px 0', width: 'auto' }}>

                    <Box sx={{ display: 'flex', padding: '8px 0', width: '550px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '8px', border: '1px solid #aaa', width: '100%' }}>
                            <Box sx={{ display: 'flex', fontWeight: 700 }}>
                                <Box sx={{ width: '100px' }}>
                                    Level
                                </Box>
                                <Box sx={{ width: '100px' }}>
                                    AVG
                                </Box>
                                <Box sx={{ width: '100px' }}>
                                    STD ({'σ'})
                                </Box>
                                <Box sx={{ width: 'auto' }}>
                                    Colors
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ width: '100px' }}>
                                    Level 1
                                </Box>
                                <Box sx={{ width: '100px' }}>
                                    {globalAverages[0].toFixed(1)}
                                </Box>
                                <Box sx={{ width: '100px' }}>
                                    {globalStds[0].toFixed(1)}
                                </Box>
                                <Box sx={{ display: 'flex', width: 'auto', gap: '2px' }}>
                                    <Box sx={{ width: '100px' }} className={classes.aboveAvgText}>
                                        {`> avg + 1σ`}
                                    </Box>
                                    <Box sx={{ width: '100px' }} className={classes.belowAvgText}>
                                        {`< avg - 2σ`}
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ width: '100px' }}>
                                    Level 2
                                </Box>
                                <Box sx={{ width: '100px' }}>
                                    {globalAverages[1].toFixed(1)}
                                </Box>
                                <Box sx={{ width: '100px' }}>
                                    {globalStds[1].toFixed(1)}
                                </Box>
                                <Box sx={{ display: 'flex', width: 'auto' }}>

                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ width: '100px' }}>
                                    Level 3
                                </Box>
                                <Box sx={{ width: '100px' }}>
                                    {globalAverages[2].toFixed(1)}
                                </Box>
                                <Box sx={{ width: '100px' }}>
                                    {globalStds[2].toFixed(1)}
                                </Box>
                                <Box sx={{ display: 'flex', width: 'auto', gap: '2px' }}>
                                    <Box sx={{ width: '100px' }} className={classes.aboveAvgText}>
                                        {`< avg + 1σ`}
                                    </Box>
                                    <Box sx={{ width: '100px' }} className={classes.belowAvgText}>
                                        {`> avg + 2σ`}
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', padding: '8px 0', width: '550px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '8px', border: '1px solid #aaa', justifyContent: 'space-between', width: '100%' }}>
                            <Box sx={{ display: 'flex', fontWeight: 700, justifyContent: 'space-between' }}>
                                <Box sx={{ width: '200px' }}>
                                    Complexity
                                </Box>
                                <Box sx={{ minWidth: '200px', textAlign: 'right' }}>
                                    Count
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box sx={{ width: '200px' }}>
                                    1 - top level
                                </Box>
                                <Box sx={{ minWidth: '200px', textAlign: 'right' }}>
                                    {totals[0]}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box sx={{ width: '200px' }}>
                                    2 - mid level
                                </Box>
                                <Box sx={{ minWidth: '200px', textAlign: 'right' }}>
                                    {totals[1]}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box sx={{ width: '200px' }}>
                                    3 - bottom level
                                </Box>
                                <Box sx={{ minWidth: '200px', textAlign: 'right' }}>
                                    {totals[2]}
                                </Box>
                            </Box>

                            <Box sx={{ height: '8px' }}></Box>

                            <Box sx={{ display: 'flex', fontWeight: 700, justifyContent: 'space-between' }}>
                                <Box sx={{ width: '200px' }}>
                                    Grand Total
                                </Box>
                                <Box sx={{ minWidth: '200px', textAlign: 'right' }}>
                                    {grandTotal}
                                </Box>
                            </Box>

                        </Box>
                    </Box>


                    <Box sx={{ height: '8px' }}></Box>

                    {data.map((group) => {
                        return (
                            <Box sx={{ display: 'flex', width: '550px' }} key={group.state}>

                                {!!group.state &&
                                    <Box sx={{ width: '100px', padding: '4px', border: '1px solid #aaa' }}>
                                        {group.state}
                                    </Box>
                                }

                                <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                                    {group.subGroups.map((subGroup) =>
                                        <Box key={subGroup.data.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'space-between', border: '1px solid #aaa', padding: '4px 8px' }}>
                                            <Box sx={{ width: '200px' }}>
                                                {subGroup.data.name}
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '180px', marginBottom: '4px' }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}
                                                    className={subGroup.complexityCounts[0] > globalAverages[0] + globalStds[0] ? classes.aboveAvgText
                                                        : subGroup.complexityCounts[0] < globalAverages[0] - (2 * globalStds[0]) ? classes.belowAvgText : ''}>
                                                    <Box>1 - top level</Box>
                                                    <Box sx={{ textAlign: 'right', fontWeight: 500 }}>
                                                        {subGroup.complexityCounts[0]}
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Box>2 - mid level</Box>
                                                    <Box sx={{ textAlign: 'right', fontWeight: 500 }}>
                                                        {subGroup.complexityCounts[1]}
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}
                                                    className={subGroup.complexityCounts[2] < globalAverages[2] + globalStds[2] ? classes.aboveAvgText
                                                        : subGroup.complexityCounts[2] > globalAverages[2] + (2 * globalStds[2]) ? classes.belowAvgText : ''}>
                                                    <Box>3 - btm level</Box>
                                                    <Box sx={{ textAlign: 'right', fontWeight: 500 }}>
                                                        {subGroup.complexityCounts[2]}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        )
                    })}

                </Box>

            }

        </Box >

    );
}
