import React, { useEffect } from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/private-route"
import Profile from "../components/profile"
import Login from "../components/login"
import Outreach from "../components/outreach"
import Analytics from "../components/analytics";
import ChargeSheet from "../components/charge-sheet";
import TicketView from "../components/ticket-view";
import Users from "../components/users";
import Reports from "../components/reports/reports";
import UpdateChargeTicketRequest from "../components/charge-sheet/update-request/update-charge-ticket-request";
import Dashboards from "../components/dashboards/dashboards";
import ScheduleDashboard from "../components/dashboards/schedule-dashboard";
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import { ChargeClient } from "../providers/charge-client";
import PermissionsProvider from "../providers/permissions-provider"
import HPM_CDM from "../components/hpm-cdm"
import ComplexityReport from "../components/reports/complexity-report"


const hpmTheme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#0171B9', // '#0270CE', // rgb(59, 130, 246) | rgb(0, 113, 206)
            // OR: #3F51B5 | rgb(63,81,181) | rgb(0, 113, 185)
            // OR: 0071ce
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            // light: '#0066ff',
            main: '#ed1c24', // '#F50157', // rgb(245,1,87)
            // OR: color: #ed1c24;
            // dark: will be calculated from palette.secondary.main,
            // contrastText: '#ffcc00',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    overrides: {
        MuiButton: {
            contained: {
                backgroundColor: '#eee',
                // '&:hover': {
                //     backgroundColor: CONSTANTS.LIGHT_BLUE,
                //     // Reset on touch devices, it doesn't add specificity
                //     '@media (hover: none)': {
                //         backgroundColor: CONSTANTS.CONTRAST_TEXT,
                //     },
                // }
            }
        }
    }
});

const chargeClient = new ChargeClient();

const App = () => {

    useEffect(() => {
        _getPermissions();
    }, []);

    async function _getPermissions() {
        const permissions = await chargeClient.getPermissions();
        console.log(`Permissions List = `, permissions);
        PermissionsProvider._setPermissionsList(permissions);
    }

    return (
        <ThemeProvider theme={hpmTheme}>
            <Layout>
                <Router>
                    <PrivateRoute path="/app/profile" component={Profile} />
                    {/* <PrivateRoute path="/app/outreach" component={Outreach} /> */}
                    <PrivateRoute path="/app/hpm-cdm" component={HPM_CDM} />
                    <PrivateRoute path="/app/analytics" component={Analytics} />
                    <PrivateRoute path="/app/reports" component={Reports} />
                    <PrivateRoute path="/app/reports/complexity" component={ComplexityReport} />
                    <PrivateRoute path="/app/dashboards" component={Dashboards} />
                    <PrivateRoute path="/app/schedule" component={ScheduleDashboard} />
                    {/* <PrivateRoute path="/app/finance" component={Finance} /> */}
                    <PrivateRoute path="/app/ticketview" component={TicketView} />
                    <PrivateRoute path="/app/users" component={Users} />
                    {/* <PrivateRoute path="/app/financeoutreach" component={FinanceOutreach} /> */}
                    <PrivateRoute path="/app/chargesheet" component={ChargeSheet} />
                    <PrivateRoute path="/app/create-charge" component={ChargeSheet} />
                    <PrivateRoute path="/app/ticket-update-request/:id" component={UpdateChargeTicketRequest} />
                    <Login path="/app/login" component={Login} />
                </Router>
            </Layout>
        </ThemeProvider>
    )
}

export default App
