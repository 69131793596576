export const dateFormatter = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC'
})

export const monthFormatter = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: 'numeric',
    timeZone: 'UTC'
})

export function getMonths(startDateInput, endDateInput) {
    let startDate = `${dateFormatter.format(new Date(startDateInput))}`;
    let endDate = `${dateFormatter.format(new Date(endDateInput))}`;
    let start = startDate.split('/');
    let end = endDate.split('/');
    let startYear = parseInt(start[start.length - 1]);
    let endYear = parseInt(end[end.length - 1]);
    let dates = [];

    // console.log(`start => ${start} => year = ${startYear}`);
    // console.log(`end => ${end} => year = ${endYear}`);

    for (let i = startYear; i <= endYear; i++) {
        let endMonth = i != endYear ? 11 : parseInt(end[0]) - 1;
        let startMon = i === startYear ? parseInt(start[0]) - 1 : 0;
        for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
            let month = j + 1;
            let displayMonth = month < 10 ? '0' + month : month;
            dates.push([displayMonth, '01', i].join('/'));
        }
    }
    return dates;
}

export function daysInMonth(month, year) {
    if (month > 12) { month = month % 12; }
    return new Date(year, month, 0).getDate();
}

export function addHoursDirectly(date, hours) {
    date.setHours(date.getHours() + hours);
    return date;
}

export function addHoursCopy(date, hours) {
    const dateCopy = new Date(date);
    dateCopy.setHours(dateCopy.getHours() + hours);
    return dateCopy;
}

export function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export function convertDateToUTC(date) { return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); }

export function convertToUtc(date) { return new Date(date.toUTCString().substr(0, 25)); }

export function getDay(date, delimeter) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let outputFormat = month + delimeter + day + delimeter + year; // 8-23-2022
    return outputFormat;
}

export function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
}

export function sameMonth(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth();
}
