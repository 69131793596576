import { similarity } from 'string-similarity';
import { api } from '../../env/connection';
import { tokenProvider } from '../../providers/tokenProvider';
import { getDay} from './report-utils';
import { PowellFlatRate } from '../../constants';
import { getActiveFees } from '../../utils/feeUtils';


export async function getManyTickets(startDate, endDate) {
    let chargeTickets = [];
    let chunkSize = 10000;

    const initialResponse = await getTickets(startDate, endDate, 0, 1);

    let count = initialResponse.ticketCount;
    let chunkCount = Math.ceil(count / chunkSize);

    let requests = [];
    for (let i = 0; i < chunkCount; i++) {
        console.log(`   Fetching charge ${chunkCount} tickets: ${i} => skip=${chunkSize * i} take=${chunkSize}...`);
        requests.push(getTickets(startDate, endDate, chunkSize * i, chunkSize));
    }
    const responses = await Promise.all(requests);
    console.log(`responses = `, responses);

    responses.forEach((datum) => {
        console.log(datum);

        let results = datum.chargeTickets;
        if (results.length) {
            console.log(`   Adding results (${results.length})...`);
            chargeTickets = chargeTickets.concat(results);
        }
    });
    return chargeTickets;
}

export async function getTickets(startDate, endDate, skip, take) {
    try {
        const rawResponse = await fetch(`${api}/api/analytics/charge-tickets/find`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokenProvider.token}`
            },
            body: JSON.stringify({
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                skip: skip,
                take: take
            })
        });
        const response = await rawResponse.json();
        // console.log(`getAnalyticsData response = `, response);

        return response.data;

    } catch (e) {
        console.log(`ERROR: `, e);
        return null;
    }
}

export async function getAnalyticsData(startDate, endDate) {
    try {
        const rawResponse = await fetch(`${api}/api/analytics/analytics_data`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokenProvider.token}`
            },
            body: JSON.stringify({ startDate: startDate.toISOString(), endDate: endDate.toISOString() })
        });
        const response = await rawResponse.json();
        // console.log(`getAnalyticsData response = `, response);

        return response.data;

    } catch (e) {
        console.log(`ERROR: `, e);
        return null;
    }
}

export function getSimilarTickets(tickets) {

    let similarList = [];

    for (let i = 0; i < tickets.length; i++) {
        for (let j = 0; j < tickets.length; j++) {
            if (i !== j) {

                if (tickets[i].id !== tickets[j].id) {

                    if (tickets[i].patientName && tickets[j].patientName) {
                        if (similarity.compareTwoStrings(tickets[i].patientName, tickets[j].patientName) > 0.1) {
                            similarList.push({
                                item1: tickets[i],
                                item2: tickets[j]
                            });
                        }
                    } else {
                        console.log(`ERROR: patient name is null => ${JSON.stringify(tickets[i])} | ${JSON.stringify(tickets[j])}`);
                    }

                }
            }
        }
    }

    return similarList;
}

export function exclude(arr1, arr2) { // arr1 items that are NOT in arr2
    return arr1.filter(o1 => !arr2.map(o2 => o2.key).includes(o1.key));
}

export function include(arr1, arr2) { // arr1 items that are in arr2
    return arr1.filter(o1 => arr2.map(o2 => o2.key).includes(o1.key));
}

export function mergeData(tickets, appointments) { // merge arrays by key
    let mergedData = [];

    for (let i = 0; i < tickets.length; i++) {
        for (let j = 0; j < appointments.length; j++) {
            if (tickets[i].key == appointments[j].key) {
                mergedData.push({
                    key: tickets[i].key,
                    ticket: tickets[i].data,
                    appointment: appointments[j].data
                })
            }
        }
    }

    return mergedData;
}

export function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export function getOrdinalSuffix(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

export function buildSharedContributionData(facilityTickets, rawData, contribution, option, LocationsUnitsProvider) {

    // PROCEDURES
    const procedureTickets = facilityTickets.filter(x => !JSON.stringify(x.chargeticketcpt).includes("\"code\":\"99"));
    contribution.procedureTotalPatients = procedureTickets.length;
    console.log(`procedureTickets = `, procedureTickets);



    let proceduresDates = {};
    let procedureUnitsInfo = {
        totalCount: 0,
        countPerDay: {}
    };
    for (let j = 0; j < procedureTickets.length; j++) {
        let chargeTicket = procedureTickets[j];
        const providerType = `${chargeTicket.user.role[0].id}` === '3' ? "CRNA" : "PA";

        let perVisitTotal = 0;
        for (let k = 0; k < chargeTicket.chargeticketcpt.length; k++) {
            let chargeTicketCpt = chargeTicket.chargeticketcpt[k];

            perVisitTotal += LocationsUnitsProvider.getUnits(chargeTicket, chargeTicketCpt, providerType, chargeTicket.location, false);
        }
        perVisitTotal = LocationsUnitsProvider.applyUnitCap(perVisitTotal, chargeTicket.location);
        procedureUnitsInfo.totalCount += parseFloat(`${perVisitTotal}`);
        
        // need to split out the calcs for days to exclude Daren Badura (he does Clinic and Procedures on same days)
        if (option === 'locations' && (`${chargeTicket.userId}` === '18' || `${chargeTicket.userId}` === '40')) {
            continue;
        }

        // calculate procedure days
        let dateKey = getDay(new Date(`${chargeTicket.date}`), '-');
        proceduresDates[`${dateKey}`] = true;
    }
    contribution.procedureUnits = procedureUnitsInfo.totalCount;
    contribution.procedureTotalDays = Object.keys(proceduresDates).length;
    contribution.procedureDailyPatients = Object.keys(proceduresDates).length ? ((1.0 * procedureTickets.length) / (1.0 * Object.keys(proceduresDates).length)) : 0;
    contribution.procedureDailyPatients = Math.round((contribution.procedureDailyPatients + Number.EPSILON) * 100) / 100; // round to 2 decimal places


    // CLINIC
    let clinicTickets = facilityTickets.filter(x => JSON.stringify(x.chargeticketcpt).includes("\"code\":\"99"));
    contribution.clinicalTotalPatients = clinicTickets.length;

    let clinicDates = {};
    let clinicUnitsInfo = {
        totalCount: 0,
        countPerDay: {}
    };
    for (let j = 0; j < clinicTickets.length; j++) {
        let chargeTicket = clinicTickets[j];
        const providerType = `${chargeTicket.user.role[0].id}` === '3' ? "CRNA" : "PA";

        let perVisitTotal = 0;
        for (let k = 0; k < chargeTicket.chargeticketcpt.length; k++) {
            let chargeTicketCpt = chargeTicket.chargeticketcpt[k];

            perVisitTotal += LocationsUnitsProvider.getUnits(chargeTicket, chargeTicketCpt, providerType, chargeTicket.location, true);

            // Check Powell clinic tickets for flat rate CPT
            if (chargeTicket.location === 'Powell') {
                for (let l = 0; l < chargeTicketCpt.cpt.locationcpts.length; l++) {
                    const locationCpt = chargeTicketCpt.cpt.locationcpts[l];
                    contribution.revenueFromFlatRates += extractFlatFee(locationCpt);
                }
            }
        }
        perVisitTotal = LocationsUnitsProvider.applyUnitCap(perVisitTotal, chargeTicket.location);
        clinicUnitsInfo.totalCount += parseFloat(`${perVisitTotal}`);

        // calculate clinic days
        let dateKey = getDay(new Date(`${chargeTicket.date}`), '-');
        clinicDates[`${dateKey}`] = true;
    }
    contribution.clinicUnits = clinicUnitsInfo.totalCount;
    contribution.clinicalTotalDays = Object.keys(clinicDates).length;
    contribution.clinicalDailyPatients = Object.keys(clinicDates).length ? ((1.0 * clinicTickets.length) / (1.0 * Object.keys(clinicDates).length)) : 0;
    contribution.clinicalDailyPatients = Math.round((contribution.clinicalDailyPatients + Number.EPSILON) * 100) / 100; // round to 2 decimal places

    // COST
    let proceduresByProvider = setupProviderTicketData(procedureTickets, rawData);
    let procedureCosts = calculateProcedureCosts(proceduresByProvider);
    contribution.directCostOfProcedures = procedureCosts;

    let clinicVisitByProvider = setupProviderTicketData(clinicTickets, rawData);
    let clinicCosts = calculateClinicCosts(clinicVisitByProvider);
    contribution.directCostOfClinic = clinicCosts;
    contribution.totalDirectCost = clinicCosts;
  };

export function buildLocationSpecificContributionData(location, contribution, startDate, endDate) {
    // fees AND apply effective dates for fees
    let managementFees = getActiveFees(location, 'ManagementFee', startDate, endDate);
    let equipmentFees = getActiveFees(location, 'EquipmentFee', startDate, endDate);
    let equipmentCost = getActiveFees(location, 'EquipmentCost', startDate, endDate);
    console.log(`[${location.practiceName}] :: equipmentCost = `, equipmentCost);

    // let equipmentCost = location.facilityfees.find(x => x.feeName === 'EquipmentCost');
    // if (equipmentCost && (new Date(`${equipmentCost.effectiveDate}`) > startDate)) {
    //     equipmentCost = null;
    // };

    const locationChargeRate = location.facilityfees.find(locationfee => locationfee.feeName === "ChargeRate");

    // REVENUE
    contribution.revenueFromManagement = parseFloat(`${managementFees.total}`);
    contribution.revenueFromEquipment = parseFloat(`${equipmentFees.total}`);
    contribution.revenueFromClinic = locationChargeRate && locationChargeRate.amount ? (contribution.clinicUnits * parseFloat(`${locationChargeRate.amount}`)) : 0;
    contribution.revenueFromClinic += contribution.revenueFromFlatRates;
    contribution.revenueFromProcedures = locationChargeRate && locationChargeRate.amount ? (contribution.procedureUnits * parseFloat(`${locationChargeRate.amount}`)) : 0;
    let totalRevenue = (contribution.revenueFromProcedures)
        + (contribution.revenueFromClinic)
        + (contribution.revenueFromManagement)
        + (contribution.revenueFromEquipment)
    contribution.totalRevenue = totalRevenue;

    // COST
    contribution.directCostOfEquipment = parseFloat(`${equipmentCost.total}`); // equipmentCost ? parseFloat(`${equipmentCost.amount}`) : 0;

    let totalCosts = contribution.directCostOfProcedures
        + contribution.directCostOfClinic
        + (contribution.directCostOfEquipment);
    contribution.totalDirectCost = totalCosts;

    contribution.contributionDifference = totalRevenue - totalCosts;
    let percentDecimal = (totalRevenue - totalCosts) / totalRevenue;
    contribution.contributionPercent = totalRevenue === 0 ? 0 : percentDecimal;
};

function calculateProcedureCosts(proceduresByProvider) {
    let procedureCosts = 0;
    let userIds = Object.keys(proceduresByProvider);
    for (let j = 0; j < userIds.length; j++) {
        let userData = proceduresByProvider[`${userIds[j]}`];

        let dates = Object.keys(userData.ticketDays); // get all of the DAYS that a provider has worked (based on ticket dates)
        for (let k = 0; k < dates.length; k++) {

            let totalPatientsForDay = userData.ticketDays[`${dates[k]}`].length; // get the total number of patients seen for a particular day
            // console.log(`${userData.user.firstName} ${userData.user.lastName} => totalPatientsForDay (${dates[k]}) = ${totalPatientsForDay}`);

            // add day rate for user
            if (userData.user.rates && userData.user.rates.length) {
                // console.log(`Day cost for ${userData.user.firstName} ${userData.user.lastName} = ${userData.user.rates[0].dayAmount}`);
                procedureCosts += parseFloat(`${userData.user.rates[0].dayAmount}`); // add the day rate for the provider
            }

            // if user met bonus, add cost
            if (userData.user.bonuses && userData.user.bonuses.length) {
                for (let i = 0; i < userData.user.bonuses.length; i++) {
                    // console.log(`Bonus cost for ${userData.user.firstName} ${userData.user.lastName} = ${userData.user.bonuses[i].amount} | ${userData.user.bonuses[i].rule}`);
                    if (userData.user.bonuses[i].bonusType === 'PatientBonus' && (totalPatientsForDay > userData.user.bonuses[i].rule)) {
                        let bonusPatientsCount = totalPatientsForDay - userData.user.bonuses[i].rule;
                        procedureCosts += (bonusPatientsCount * parseFloat(`${userData.user.bonuses[i].amount}`));
                    }
                    if (userData.user.bonuses[i].bonusType === 'HalfDay' && (totalPatientsForDay < 5)) {
                        procedureCosts += parseFloat(`${userData.user.bonuses[i].amount}`);
                    }
                }
            }
        }
    }
    return procedureCosts;
}

function calculateClinicCosts(clinicVisitsByProvider) {
    let clinicCosts = 0;
    let userIds = Object.keys(clinicVisitsByProvider);
    for (let j = 0; j < userIds.length; j++) {
        let userData = clinicVisitsByProvider[`${userIds[j]}`];

        let dates = Object.keys(userData.ticketDays);
        for (let k = 0; k < dates.length; k++) {

            let totalPatientsForDay = userData.ticketDays[`${dates[k]}`].length;
            // console.log(`${userData.user.firstName} ${userData.user.lastName} => totalPatientsForDay (${dates[k]}) = ${totalPatientsForDay}`);

            // add day rate for user
            if (userData.user.rates && userData.user.rates.length) {
                // console.log(`Day cost for ${userData.user.firstName} ${userData.user.lastName} = ${userData.user.rates[0].dayAmount}`);
                clinicCosts += parseFloat(`${userData.user.rates[0].dayAmount}`);
            }

            // if user met bonus, add cost
            if (userData.user.bonuses && userData.user.bonuses.length) {
                for (let i = 0; i < userData.user.bonuses.length; i++) {
                    // console.log(`Bonus cost for ${userData.user.firstName} ${userData.user.lastName} = ${userData.user.bonuses[i].amount} | ${userData.user.bonuses[i].rule}`);
                    if (userData.user.bonuses[i].bonusType === 'PatientBonus' && (totalPatientsForDay > userData.user.bonuses[i].rule)) {
                        clinicCosts += parseFloat(`${userData.user.bonuses[i].amount}`);
                    }
                    if (userData.user.bonuses[i].bonusType === 'HalfDay' && (totalPatientsForDay < 5)) {
                        clinicCosts += parseFloat(`${userData.user.bonuses[i].amount}`);
                    }
                }
            }
        }
    }
    return clinicCosts;
}

function extractFlatFee(locationCpt) {
    if (locationCpt.unitType === "FlatRate") {
        return PowellFlatRate;
    }

    return 0;
};

function setupProviderTicketData(ticketList, rawData) {
    let proceduresByProvider = {}; // groupBy(procedureTickets, 'userId');

    // set up each user with their own tickets
    for (let j = 0; j < ticketList.length; j++) {
        let chargeTicket = ticketList[j];

        if (!Object.hasOwn(proceduresByProvider, `${chargeTicket.userId}`)) {
            proceduresByProvider[`${chargeTicket.userId}`] = {
                user: rawData.users.find(x => `${x.id}` === `${chargeTicket.userId}`),//chargeTicket.user,
                ticketDays: {}
            };
        }

        let day = getDay(new Date(`${chargeTicket.date}`), '-');

        if (!Object.hasOwn(proceduresByProvider[`${chargeTicket.userId}`].ticketDays, `${day}`)) {
            proceduresByProvider[`${chargeTicket.userId}`].ticketDays[`${day}`] = [];
        }

        proceduresByProvider[`${chargeTicket.userId}`].ticketDays[`${day}`].push(chargeTicket);
    }

    return proceduresByProvider;
};