import React, { useEffect, useState } from 'react';
import SEO from "../seo";

// import TableauReport from 'tableau-react';
import { Helmet } from 'react-helmet';
import PermissionsProvider, { HPM_PERMISSIONS } from '../../providers/permissions-provider';
import { getUser } from '../../services/auth';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// import './dashboards.css';

const { api } = require('../../env/connection');
const { tokenProvider } = require('../../providers/tokenProvider');

// let viz;

// b3687e4e-70fc-444b-812d-c54bab306f6a
// https://us-west-2b.online.tableau.com/t/hpmservices/views/OperationsDashboard/OperationsDashboard


async function fetchToken() {
    console.log(`[ChargeClient] :: getDashboardToken :: ${api}/api/analytics/dashboards/auth`);
    let response = await fetch(`${api}/api/analytics/dashboards/auth`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenProvider.token}`
        }
    });

    if (response.status >= 400 && response.status < 600) {
        console.log(`Bad response from server (${response.status} | ${response.statusText}) => `, response);
        throw new Error(`Bad response from server (${response.status} | ${response.statusText})`);
    }

    let fetchTokenResponse = await response.json();
    console.log(`[ChargeClient] :: fetched token => `, fetchTokenResponse);
    return fetchTokenResponse.data.token;
}

setupVizErrorHandler();
function setupVizErrorHandler() {
    if (typeof window !== "undefined") {
        window.handleLoadError = function handleLoadError(e) {
            console.error(`Error loading Tableau data: `, e);
            const message = JSON.parse(e.detail.message);
            console.error(message);
            if (message && message.statusCode === 401) {
                let errorMessage = JSON.parse(message.errorMessage);
                if (errorMessage && errorMessage.result && errorMessage.result.errors && errorMessage.result.errors.length) {
                    alert(`Not authorized to view dashboard (CODE: ${errorMessage.result.errors[0].code})`);
                } else {
                    alert(`Not authorized to view dashboard`);
                }
            } else {
                alert(`Error loading dashboard`);
            }
        }
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ width: `100%`, height: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {/* <div>panel={`${value} ${index}`}</div> */}
            {value === index && (
                <Box p={0} sx={{ height: '100%' }}>
                    {children}
                </Box>
            )}

        </div>
    );
}

const dashboardOptions = [
    {
        label: 'Operations',
        permission: HPM_PERMISSIONS.DASHBOARDS_OPS,
        url: 'https://us-west-2b.online.tableau.com/t/hpmservices/views/OperationsDashboard-embed/OperationsDashboard'
    },
    {
        label: 'Executive',
        permission: HPM_PERMISSIONS.DASHBOARDS_EXECUTIVE,
        url: 'https://us-west-2b.online.tableau.com/t/hpmservices/views/ExecDashboard-Embed/ExecutiveDashboard'
    },
    {
        label: 'Regional',
        permission: HPM_PERMISSIONS.DASHBOARDS_REGIONAL,
        url: 'https://us-west-2b.online.tableau.com/t/hpmservices/views/RegionalManagerDashboard-Embed/RMDashboard'
    },
    {
        label: 'Care Coordinator',
        permission: HPM_PERMISSIONS.DASHBOARDS_CARECOORDINATOR,
        url: 'https://us-west-2b.online.tableau.com/t/hpmservices/views/RegionalManagerDashboard-Embed/CCCDashboard'
    },
];

const Dashboards = () => {

    const user = getUser();

    const [vizToken, setVizToken] = useState('');
    const [value, setValue] = React.useState(0);

    let availableDashboards = dashboardOptions.filter(x =>
        PermissionsProvider.hasPermission(user, x.permission)
    );

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {

        async function loadToken() {
            const token = await fetchToken();
            console.log(`[Dashboards] Setting viz token = `, token);
            setVizToken(token);
        }
        loadToken();

    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: `100%`, height: '100%' }}>
            <SEO title="Dashboards" />
            <h2 style={{ marginBottom: '4px' }}>Dashboards</h2>

            <Helmet>
                <script type='module' src='https://us-west-2b.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js'></script>
            </Helmet>

            <div style={{ display: 'flex', flexDirection: 'column', width: `100%`, height: '100%' }}>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange}>

                        {availableDashboards.map((option, index) =>
                            <Tab label={option.label} />
                        )}

                    </Tabs>
                </AppBar>

                <div style={{ flex: '1 1 auto', height: 'calc(100vh - 224px)' }}>
                    {!!vizToken && availableDashboards.map((option, index) =>
                        <TabPanel value={value} index={index}>
                            <tableau-viz id="tableauVizToken"
                                src={option.url}
                                token={vizToken}
                                toolbar="hidden"
                                onVizLoadError="handleLoadError"
                                hide-tabs
                                style={{ height: '100%', width: '100%' }}
                            >
                            </tableau-viz>
                        </TabPanel>
                    )}
                </div>
            </div>

        </div>
    )
}

export default Dashboards;
