import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { ChargeEvents, EventBus } from "../../../providers/event-bus";

import { getUser } from "../../../services/auth";

import { ChargeClient } from "../../../providers/charge-client";


const chargeClient = new ChargeClient();

async function setupRequestData(changeRequestId) {
    const changeRequests = await chargeClient.findTicketChangeRequests({
        ids: [changeRequestId]
    });

    console.log(`[UpdateChargeTicketRequest] :: API => Found request: `, changeRequests);
    if (changeRequests.length) {

        let result = changeRequests[0];

        if (result.ticketData.cpts.length !== result.ticketData.cptList.length) { // check if any CPT data is missing            
            const cpts = await chargeClient.getCpts(); // get CPTs from server
            for (let cpt of result.ticketData.cpts) {
                const cptObject = cpts.find(x => `${x.id}` === `${cpt.id}`);
                if (cptObject) {
                    const existingCptData = result.ticketData.cptList.find(x => `${x.id}` === `${cptObject.id}`);
                    if (!existingCptData) {
                        result.ticketData.cptList.push(cptObject);
                    }                        
                }
            }
        }

        return result;
    }
    return undefined;
}

async function setupTicketData(ticketId) {
    const tickets = await chargeClient.getTickets({
        ids: [ticketId]
    });
    console.log(`[UpdateChargeTicketRequest] :: API => Found ticket: `, tickets);
    if (tickets.length) {
        return tickets[0];
    }
    return undefined;
}

async function saveChangeRequest(data) {
    const result = await chargeClient.saveTicketChangeRequest(data);
    return result;
}

const useStyles = makeStyles(theme => ({
    root: {

    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    changeHeader: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    comparisionContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '16px',
        margin: '16px',
    },
    card: {
        minWidth: 320,
        maxWidth: 500,
        padding: '16px',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            flexWrap: 'wrap'
        },
        '& .MuiOutlinedInput-multiline': {
            flexWrap: 'wrap'
        }
    },
    ticketInfo: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: '16px',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25)
    },
    '.MuiInputBase-root': {
        flexWrap: 'wrap'
    }
}));

const clinicOptions = [
    'Blind Injection Performed',
    'Physical Therapy Ordered',
    'Labs Ordered',
    'Procedure Ordered',
    'Imaging Ordered',
];

function getTicketOptions(cpts, ids) {

    console.log(`[UpdateChargeTicketRequest] :: getTicketOptions => `, cpts);

    cpts = cpts.filter(x => !!x);

    let result = {
        isClinic: true,
        imagingOrdered: false,
        pxOrdered: false,
        labOrdered: false,
        ptOrdered: false,
        blindInj: false,
        optionsList: []
    };

    if (cpts) {
        result.isClinic = cpts.some(x => `${x.code}`.startsWith('99'));
        result.imagingOrdered = cpts.some(x => x.class.includes('MED IMAGING'));
        result.pxOrdered = cpts.some(x => x.class.includes('CRNA PAIN MANAGEMENT PROCEDURES'));
        result.labOrdered = cpts.some(x => `${x.id}` === `${149}`);
        result.ptOrdered = cpts.some(x => `${x.id}` === `${150}`);
        result.blindInj = cpts.some(x => `${x.id}` === `${161}`);
    }

    if (ids) {
        result.labOrdered = ids.some(x => `${x.id}` === `${149}`);
        result.ptOrdered = ids.some(x => `${x.id}` === `${150}`);
        result.blindInj = ids.some(x => `${x.id}` === `${161}`);
    }

    if (result.imagingOrdered) { result.optionsList.push('Imaging Ordered'); }
    if (result.pxOrdered) { result.optionsList.push('Procedure Ordered'); }
    if (result.labOrdered) { result.optionsList.push('Labs Ordered'); }
    if (result.ptOrdered) { result.optionsList.push('Physical Therapy Ordered'); }
    if (result.blindInj) { result.optionsList.push('Blind Injection Performed'); }

    console.log(`[UpdateChargeTicketRequest] :: getTicketOptions RESULT = `, result);

    return result;
}

// handles displaying update request for locked ticket
const UpdateChargeTicketRequest = (props) => {

    const classes = useStyles();
    const user = getUser();

    const [isLoading, setIsLoading] = useState(true);
    const [changeRequest, setChangeRequest] = useState(null);
    const [chargeTicket, setChargeTicket] = useState(null);

    const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
    const [responseNotes, setResponseNotes] = useState(undefined);
    const [submitRequestBtnDisabled, setSubmitRequestBtnDisabled] = useState(true);

    useEffect(() => {
        // fetch all of the data needed (existing ticket, change request)
        const id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
        console.log(`[UpdateChargeTicketRequest] :: Fetching request data for ${id}`);

        async function setupCurrent() {
            try {
                setIsLoading(true);
                const request = await setupRequestData(id);

                if (request) {

                    const ticket = await setupTicketData(parseInt(request.ticketData.id));

                    if (ticket) {
                        // set up the ticket data
                        ticket.date = new Date(`${ticket.date}`);
                        ticket.cptList = ticket.chargeticketcpt
                            .sort((a, b) => a.sequence - b.sequence)
                            .map(x => x.cpt);

                        const ticketOptions = getTicketOptions(ticket.cptList);
                        ticket.isClinic = ticketOptions.isClinicChecked || ticketOptions.isClinic;
                        ticket.optionsList = ticketOptions.optionsList;

                        console.log(`[UpdateChargeTicketRequest] :: ticket options: `, ticketOptions);
                        console.log(`[UpdateChargeTicketRequest] :: ticket: `, ticket);


                        // set up the request data
                        request.requestDate = new Date(`${request.requestDate}`);
                        console.log(`[UpdateChargeTicketRequest] :: Found request: `, request);

                        const requestOptions = getTicketOptions(request.ticketData.cptList, request.ticketData.cpts);
                        console.log(`[UpdateChargeTicketRequest] :: request options: `, requestOptions);

                        request.ticketData.isClinic = requestOptions.isClinicChecked || requestOptions.isClinic;
                        request.ticketData.cptList = request.ticketData.cptList.filter(x => !!x).sort((a, b) => a.sequence - b.sequence);
                        request.ticketData.imagingOrdered = requestOptions.imagingOrdered;
                        request.ticketData.pxOrdered = requestOptions.pxOrdered;
                        request.ticketData.labOrdered = requestOptions.labOrdered;
                        request.ticketData.ptOrdered = requestOptions.ptOrdered;
                        request.ticketData.blindInj = requestOptions.blindInj;
                        request.ticketData.optionsList = requestOptions.optionsList;

                        console.log(`[UpdateChargeTicketRequest] :: Found request UPDATED: `, request);

                        setChargeTicket(ticket);
                        setChangeRequest(request);
                    }
                }
            }
            catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        }
        setupCurrent();

    }, []);

    async function submit(option) {
        try {
            setIsLoading(true);

            let changeRequestData = {
                id: changeRequest.id,
                chargeTicketId: changeRequest.chargeTicketId,
                requestByUserId: changeRequest.requestByUserId,
                requestDate: changeRequest.requestDate,
                requestReason: changeRequest.requestReason,
                ticketData: changeRequest.ticketData,
                response: option,
                responseNotes: responseNotes,
                responseDate: new Date(),
                responseByUserId: user.id
            };

            // save the ticket changes
            if (option === 'Accept') {
                // let chargeTicket = {...changeRequestData.ticketData};
                await chargeClient.saveTicket(changeRequestData.ticketData);
            }

            // save the request data changes
            await saveChangeRequest(changeRequestData);

            EventBus.dispatch(ChargeEvents.EDITED, changeRequestData.ticketData);

            navigate(`/app/chargesheet`);

        } catch (err) {

            console.error(err);

        } finally {
            setIsLoading(false);
        }
    }

    const handleCancelReject = () => {
        setRejectDialogOpen(false);
    };

    const handleSubmitReject = (event) => {
        setRejectDialogOpen(false);
        submit('Reject');
    };

    const handleRequestInputChange = (e) => {
        console.log(`handleRequestInputChange`);
        e.stopPropagation();
        setResponseNotes(e.target.value);
        setSubmitRequestBtnDisabled(!e.target.value);
        // setRejectDialogOpen(false);
    }

    return (
        <>
            <div className={classes.container}>

                {isLoading &&
                    <CircularProgress />
                }

                {!isLoading && changeRequest !== null &&

                    <>

                        <div className={classes.changeHeader}>
                            <Typography variant="h5" component="h1">
                                Change Request for Locked Ticket
                            </Typography>
                            <div>Requested by: <strong>{changeRequest?.requestByUser?.firstName} {changeRequest?.requestByUser?.lastName}</strong></div>
                            <div>on {changeRequest?.requestDate.toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })}</div>
                        </div>


                        <div className={classes.comparisionContainer}>

                            <Card className={classes.card} variant="outlined">
                                <Typography variant="h5" component="h2">
                                    Original Ticket
                                </Typography>

                                <div className={classes.ticketInfo}>
                                    <TextField
                                        label="Type"
                                        defaultValue={chargeTicket.isClinic ? 'Clinic' : 'Procedure'}
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="Provider"
                                        variant="outlined"
                                        size="small"
                                        defaultValue={`${chargeTicket.user?.firstName} ${chargeTicket.user?.lastName}`}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="Date"
                                        variant="outlined"
                                        size="small"
                                        defaultValue={new Date(`${chargeTicket.date}`).toLocaleDateString('en-us')}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="Patient"
                                        variant="outlined"
                                        size="small"
                                        defaultValue={chargeTicket.patientName}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="Location"
                                        variant="outlined"
                                        size="small"
                                        defaultValue={chargeTicket.location}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="MRN"
                                        variant="outlined"
                                        size="small"
                                        defaultValue={chargeTicket.mrn}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="Insurance"
                                        variant="outlined"
                                        multiline
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: chargeTicket.insurance.split(',').map(item => (
                                                <Chip
                                                    className={classes.chip}
                                                    color="primary"
                                                    variant="outlined"
                                                    size="small"
                                                    key={item}
                                                    tabIndex={-1}
                                                    label={item}
                                                />
                                            )),
                                        }}
                                    />

                                    <TextField
                                        label="Referred"
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        defaultValue={chargeTicket.referredTo ? `YES: ${chargeTicket.referredTo}` : 'NO'}
                                    />

                                    <TextField
                                        label="Description"
                                        variant="outlined"
                                        defaultValue={chargeTicket.procedure}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        multiline
                                        minRows={4}
                                    />

                                    <TextField
                                        label="Clinic Orders"
                                        variant="outlined"
                                        multiline
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: chargeTicket.optionsList.map(item => (
                                                <Chip
                                                    className={classes.chip}
                                                    color="primary"
                                                    variant="outlined"
                                                    key={item}
                                                    tabIndex={-1}
                                                    label={item}
                                                    size="small"
                                                />
                                            )),
                                        }}
                                    />

                                    <TextField
                                        label="CPTs"
                                        variant="outlined"
                                        multiline
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: chargeTicket.cptList.filter(x => !!x).map(item => (
                                                <Chip
                                                    className={classes.chip}
                                                    color="primary"
                                                    variant="outlined"
                                                    key={item.id}
                                                    tabIndex={-1}
                                                    label={item.code}
                                                    size="small"
                                                />
                                            )),
                                        }}
                                    />

                                </div>

                                {/* <CardContent>
                    <Typography variant="h5" component="h2">
                        Existing Ticket
                    </Typography>

                </CardContent> */}
                                {/* <CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions> */}
                            </Card>

                            <Card className={classes.card} variant="outlined">
                                <Typography variant="h5" component="h2">
                                    Updated Ticket
                                </Typography>

                                <div className={classes.ticketInfo}>
                                    <TextField
                                        label="Type"
                                        defaultValue={changeRequest.ticketData.isClinic ? 'Clinic' : 'Procedure'}
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="Provider"
                                        variant="outlined"
                                        size="small"
                                        defaultValue={`${changeRequest.ticketData.user?.firstName} ${changeRequest.ticketData.user?.lastName}`}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="Date"
                                        variant="outlined"
                                        size="small"
                                        defaultValue={new Date(`${changeRequest.ticketData.date}`).toLocaleDateString('en-us')}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="Patient"
                                        variant="outlined"
                                        size="small"
                                        defaultValue={changeRequest.ticketData.patientName}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="Location"
                                        variant="outlined"
                                        size="small"
                                        defaultValue={changeRequest.ticketData.location}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="MRN"
                                        variant="outlined"
                                        size="small"
                                        defaultValue={changeRequest.ticketData.mrn}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="Insurance"
                                        variant="outlined"
                                        multiline
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: changeRequest.ticketData.insurance.split(',').map(item => (
                                                <Chip
                                                    className={classes.chip}
                                                    color="primary"
                                                    variant="outlined"
                                                    size="small"
                                                    key={item}
                                                    tabIndex={-1}
                                                    label={item}
                                                />
                                            )),
                                        }}
                                    />

                                    <TextField
                                        label="Referred"
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        defaultValue={changeRequest.ticketData.referredTo ? `YES: ${changeRequest.ticketData.referredTo}` : 'NO'}
                                    />

                                    <TextField
                                        label="Description"
                                        variant="outlined"
                                        defaultValue={changeRequest.ticketData.procedure}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        multiline
                                        minRows={4}
                                    />

                                    <TextField
                                        label="Clinic Orders"
                                        variant="outlined"
                                        multiline
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: changeRequest.ticketData.optionsList.map(item => (
                                                <Chip
                                                    className={classes.chip}
                                                    color="primary"
                                                    variant="outlined"
                                                    key={item}
                                                    tabIndex={-1}
                                                    label={item}
                                                    size="small"
                                                />
                                            )),
                                        }}
                                    />

                                    <TextField
                                        label="CPTs"
                                        variant="outlined"
                                        multiline
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: changeRequest.ticketData.cptList.filter(x => !!x).map(item => (
                                                <Chip
                                                    className={classes.chip}
                                                    color="primary"
                                                    variant="outlined"
                                                    key={item.id}
                                                    tabIndex={-1}
                                                    label={item.code}
                                                    size="small"
                                                />
                                            )),
                                        }}
                                    />

                                </div>

                                {/* <CardContent>
                    <Typography variant="h5" component="h2">
                        Existing Ticket
                    </Typography>

                </CardContent> */}
                                {/* <CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions> */}
                            </Card>

                        </div>

                        <div className={classes.comparisionContainer}>
                            <Button onClick={(event) => setRejectDialogOpen(true)} color="secondary" variant="contained">Reject</Button>
                            <Button onClick={(event) => submit('Accept')} color="primary" variant="contained">Accept</Button>
                        </div>
                    </>
                }


            </div>

            <Dialog open={rejectDialogOpen}>
                {/* <form> */}
                <DialogTitle>Reject Change Request</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please describe details about why you are rejecting the request.
                    </DialogContentText>

                    <TextField
                        autoFocus
                        id="requestDetails"
                        name="requestDetails"
                        required
                        variant="outlined"
                        margin="dense"
                        label="Details"
                        fullWidth
                        multiline
                        minRows={3}
                        onChange={(e) => handleRequestInputChange(e)}
                        value={responseNotes}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelReject}>
                        Cancel
                    </Button>
                    <Button disabled={submitRequestBtnDisabled} onClick={(event) => handleSubmitReject(event)} color="primary">
                        Reject Request
                    </Button>
                </DialogActions>
                {/* </form> */}
            </Dialog>


        </>
    );
}

export default UpdateChargeTicketRequest;
