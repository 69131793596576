import React, { useState } from "react"
import { pxBundles } from '../../env/connection'
import { RFA_EQUIPMENT_KEY } from '../../constants'
import { getUser } from "../../services/auth"
import PermissionsProvider from "../../providers/permissions-provider";

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HelpIcon from '@material-ui/icons/Help';

const CptSearch = ({procedures, completedProcedures, setCompletedProcedures, location, imagingCpts, setImaging, setImagingCptSelected, getMatchingNotifications, warningNotifications, setWarningNotifications, mbbCount, setmbbCount, mbbCptSelected, setmbbCptSelected, isClinicChecked, isTrainingChecked, handleClose}) => {
  const [filteredList, setFilteredList] = useState([])
  const [isMultiSelect, setIsMultiSelect] = useState(false)
  const [isRerender, setIsRerender] = useState(true);

  const user = getUser();
  const procedureList = procedures.data.cpts.cpts.concat(pxBundles);

  function handleSearch(e) {
    setIsMultiSelect(true);
    var newList = [];
    const filter = e.target.value.toLowerCase();

    if (e.target.value !== "") {
      newList = procedureList.filter(item => {
        const desc = item.description.toLowerCase();
        const cpt = item.code.toLowerCase();
        if ((desc.includes(filter) || cpt.includes(filter))) { // && item.type === location.chargeType) {
          return true;
        }
        return false;
      })
    } else {
      newList = [];
    }

    setFilteredList(newList);
  }

  function handleDone(e) {
    e.preventDefault()
    setIsMultiSelect(false)
    setFilteredList([])
    const newItem = document.getElementById("addInput")
    const form = document.getElementById("addItemForm")
    newItem.classList.remove("is-danger")
    newItem.value = null
    form.reset()
  }

  async function addItem(e, item) {
    e.preventDefault()
    const newProcedures = completedProcedures.filter(x => !!x);
    if (item.code === "Bundle") {
      item.codes.map((cptID) => {
        procedures.data.cpts.cpts
          .filter(x => x.type === (location && location.chargeType !== 'Legacy') ? 'Default' : 'Legacy')
          .map((cpt) => {
            if (cpt.id === cptID.toString()) {
              newProcedures.push(cpt)
            }
            return false;
          })
        return false;
      })
    }
    else {
      newProcedures.push(item)
    }
    setCompletedProcedures(newProcedures)
    await setIsRerender(!isRerender);

    console.log(`new procedures = `, newProcedures);
    if (newProcedures.some(x => imagingCpts.includes(`${x.code.trim().split('-')[0]}`))) {
      if (!newProcedures[0].code.startsWith('99')) {
        console.log(`Imaging CPT selected`);
        setImaging(localStorage.getItem(RFA_EQUIPMENT_KEY));
        setImagingCptSelected(true);
      } else {
        setImaging(null);
        setImagingCptSelected(false);
      }
    } else {
      setImaging(null);
      setImagingCptSelected(false);
    }

    // check if any added procedures have a mbb code
    if (newProcedures.some((x) => {
      const slicedCode = parseInt(x.code.trim().split('-')[0]);
      return slicedCode >= 64490 && slicedCode <= 64495;
    })) {
      setmbbCptSelected(true);
    }

    // check if any added procedures require notification
    let matchingProcedures = getMatchingNotifications(newProcedures);
    console.log(`Notification needed: `, matchingProcedures);
    setWarningNotifications(Object.values(matchingProcedures)); // matchingProcedures);
  }

  function removeItem(e, item) {
    e.preventDefault()
    const list = completedProcedures.slice()
    list.some((el, i) => {
      if (el === item) {
        list.splice(i, 1)
        return true
      }
      return false
    })
    setCompletedProcedures(list);

    if (list.some(x => imagingCpts.includes(`${x.code}`))) {
      if (!list[0].code.startsWith('99')) {
        console.log(`Imaging CPT selected`);
        setImaging(localStorage.getItem(RFA_EQUIPMENT_KEY));
        setImagingCptSelected(true);
      } else {
        setImaging(null);
        setImagingCptSelected(false);
      }
    } else {
      setImaging(null);
      setImagingCptSelected(false);
    }

    // check if any added procedures have a mbb code
    if (!list.some((x) => {
      const slicedCode = parseInt(x.code.trim().split('-')[0]);
      return slicedCode >= 64490 && slicedCode <= 64495;
    })) {
      setmbbCptSelected(false);
      setmbbCount(null);
    }


    // check if any added procedures require notification
    let matchingProcedures = getMatchingNotifications(list);
    console.log(`Notification needed: `, matchingProcedures);
    setWarningNotifications(Object.values(matchingProcedures)); // matchingProcedures);
  }


  // todo: move this to separate component?
  const [warningNotificationOpen, setWarningNotificationOpen] = useState(false);
  const [selectedWarnNotification, setSelectedWarnNotification] = useState(null);
  function openWarningNotificationDialog() {
    setWarningNotificationOpen(true);
  };
  const handleWarningNotificationDialogClose = () => {
    setWarningNotificationOpen(false);
  };
  function showNotificationInfo(notification) {
    console.log(`notification info = `, notification);
    setSelectedWarnNotification(notification);
    openWarningNotificationDialog();
  }

  function handlembbSelection(num) {
    console.log(`MBB Selection Count - ${num}`);
    setmbbCount(num);
  };

  return (
    <form className="mt-4" id="addItemForm">

      {/* <div>{["3", "4"].includes(user.role) ? "Associated CPTs:" : "CPTs associated with this clinic visit:"}</div> */}
      <div>{PermissionsProvider.hasRoles(user, ['Procedure', 'Training']) ? "Associated CPTs:" : "CPTs associated with this clinic visit:"}</div>
      <input aria-label="search" type="text" className="w-full shadow appearance-none border rounded max-w-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="addInput" onChange={(event) => handleSearch(event)} placeholder="Search..." />
      <ol className="list-decimal mt-4">
        {completedProcedures.map((item, index) => (
          <li key={index}>
            {item.description + " (" + item.code + ")"} &nbsp;
            <button
              className="bg-red-500 text-white font-bold px-2 rounded-full"
              onClick={(event) => removeItem(event, item)}
            >x</button>
          </li>
        ))}
      </ol>
      <div>
        {isRerender ? <></> : ''}
      </div>
      <div className="mt-4">
        {isMultiSelect ? <button className="button rounded py-2 px-4 text-white bg-red-500" onClick={(event) => handleDone(event)}>Done</button> : ''}
      </div>
      <div className="mt-4" style={{ "display": filteredList.length === 0 ? "none" : "" }}>
        {filteredList.map((item, index) => (
          <button key={index} href="javascript.void(0)" className="py-2 px-4 border border-green-500 rounded" onClick={(event) => addItem(event, item)}>{item.description + " (" + item.code + ")"}</button>
        ))}
      </div>

      {mbbCptSelected ?
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Chip
            icon={<ErrorOutlineIcon />}
            label={'A MBB Code was selected. Please designate below if this is the first or second for this patient.'}
            color="primary"
            variant="outlined"
            style={{ marginBottom: '10px' }}
          />
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button
              onClick={() => handlembbSelection(1)}
              variant={mbbCount === 1 ? 'contained' : 'outlined'}
            >First MBB
            </Button>
            <Button
              onClick={() => handlembbSelection(2)}
              variant={mbbCount === 2 ? 'contained' : 'outlined'}
            >Second MBB
            </Button>
          </ButtonGroup>
        </div>
        : null
      }

      <div>
        {warningNotifications.map(notification => (
          <Chip
            icon={<ErrorOutlineIcon />}
            label={`${notification.message} (${notification.code})`}
            color="secondary"
            variant="outlined"
            onClick={() => showNotificationInfo(notification)}
            onDelete={() => showNotificationInfo(notification)}
            deleteIcon={<HelpIcon />}
          />
        ))}
        {selectedWarnNotification &&
          <Dialog
            open={warningNotificationOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{selectedWarnNotification.group}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <p>Due to insurance policy rules, this procedure should only be performed <strong>{selectedWarnNotification.count} times</strong> in a <strong>{selectedWarnNotification.cadence}</strong> day period.</p>

                {(isClinicChecked || PermissionsProvider.hasRole(user, 'Clinic')) && !selectedWarnNotification.message.toLowerCase().includes('reminder') &&
                  <p>This patient can have this procedure after <strong>{selectedWarnNotification.nextDate.toLocaleDateString("en-US")}</strong></p>
                }

                {(PermissionsProvider.hasRoles(user, ['Corporate', 'Procedure', 'Training']) && !isClinicChecked && !isTrainingChecked) && !selectedWarnNotification.message.toLowerCase().includes('reminder') &&
                  <p>Please contact HPM's <strong>HIM department</strong> for assistance.</p>
                }

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleWarningNotificationDialogClose} color="primary">
            Disagree
          </Button> */}
              <Button onClick={handleWarningNotificationDialogClose} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        }

      </div>
    </form>
  )
};

export default CptSearch;