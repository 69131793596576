import React, { forwardRef } from 'react'
import SEO from "./seo"
import { gql } from "apollo-boost"
import { useQuery } from 'react-apollo'
import MaterialTable from "material-table"
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { getUser } from "../services/auth"
import { pxBundles } from '../env/connection'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const GET_PROCEDURES = gql`
    query cpts {
      cpts(orderBy: { code: desc }) {
        cpts {
          id
          class
          description
          code
          primaryUnits
          secondaryUnits
        }
      }
    }
`

const HPM_CDM = () => {
    const procedures = useQuery(GET_PROCEDURES);
    const user = getUser();
    let columns = [{ title: "Description", field: "description" }, { title: "Code", field: "code" }]
    if (user.role === "1") { // TODO: moe this to permissions
        columns.push({ title: "Primary Units", field: "primaryUnits", type: "numeric" })
        columns.push({ title: "Secondary Units", field: "secondaryUnits", type: "numeric" })
    }

    if (procedures.loading) return (
        <div>
            <SEO title="HPM CDM" />
            <h1>HPM CDM</h1>
            <h2>Loading...</h2>
        </div>
    )

    if (procedures.error) return (
        <div>
            <SEO title="HPM CDM" />
            <h1>HPM CDM</h1>
            <h2>ERROR: {procedures.error.message}</h2>
        </div>
    )

    return (
        <div className="mb-20">
            <SEO title="HPM CDM" />
            <h1>HPM CDM</h1>
            <div>
                <MaterialTable style={{ padding: "10px" }}
                    icons={tableIcons}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} row(s) selected'
                        },
                        header: {
                            actions: 'Actions'
                        },
                        body: {
                            emptyDataSourceMessage: 'No records to display',
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        }
                    }}
                    columns={columns}
                    data={procedures.data.cpts.cpts.concat(pxBundles)}
                    title="CPT Code Lookup"
                />
            </div>
        </div>
    )
}

export default HPM_CDM
