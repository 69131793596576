import React, { Component } from 'react'
import SEO from "../components/seo"

export default class FacilityDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: this.props.facility.name,
            state: this.props.facility.state,
            selectedEquipment: null,
            status: this.props.facility.status,
            nda: this.props.facility.nda,
            proforma: this.props.facility.proforma,
            contract: this.props.facility.contract,
            startDate: this.props.facility.startDate,
            notes: this.props.facility.notes,
            unitRate: this.props.facility.unitRate,
            equipment: this.props.facility.equipment
        }
    }

    handleAddEquipment(event) {
        event.preventDefault()
        let newEquipment = this.state.equipment
        if (this.state.selectedEquipment) {
            this.props.equipmentList.data.equipments.map(equipment => {
                if (equipment.name === this.state.selectedEquipment) {
                    newEquipment.push(equipment)
                    this.setState({ selectedEquipment: '' })
                    this.setState({ equipment: newEquipment })
                }
                return true
            })
        }
    }

    handleRemoveEquipment(equipment) {
        let newEquipment = this.state.equipment
        newEquipment.splice(newEquipment.indexOf(equipment), 1)
        this.setState({ equipment: newEquipment })
    }

    deleteFacility = async (id) => {
        await this.props.setSelectedFacility(null)
        await this.props.setFacilities(null)
        await this.props.deleteFacility({ variables: { id: id } })
        await this.props.refetch()
            .then((data) => {
                data.data.facilitys.facilities.map((facility, index) => {
		    let updatedFacility = facility
		    delete updatedFacility["__typename"]
		    updatedFacility["sequence"] = index + 1
                    this.props.updateFacility({ variables: { facility: updatedFacility } })
                    return false
                })
                return false
            })
        await this.props.refetch().then((data) => this.props.setFacilities(data.data.facilitys.facilities))
    }

    updateFacility = () => {
        let newEquipment = []

        this.state.equipment.map(equipment => newEquipment.push({id:equipment.id}))
        this.props.facility["name"] = this.state.name
        this.props.facility["state"] = this.state.state
        this.props.facility["status"] = this.state.status
        this.props.facility["nda"] = this.state.nda
        this.props.facility["proforma"] = this.state.proforma
        this.props.facility["contract"] = this.state.contract
        this.props.facility["startDate"] = this.state.startDate
        this.props.facility["notes"] = (this.state.notes ? this.state.notes : null)
        this.props.facility["unitRate"] = this.state.unitRate
        this.props.facility["equipment"] = newEquipment
	
	let updatedFacility = this.props.facility
	delete updatedFacility["__typename"]
        this.props.updateFacility({ variables: { facility: updatedFacility } })
        this.props.facility["equipment"] = this.state.equipment
        this.props.setSelectedFacility(null)
    }

    render = () => {
        if (this.state.showWarning) {
            return (
                <div className="w-full max-w-xs">
                    <div style={{
                             position: "fixed",
                             zIndex: "1040",
                             top: "0",
                             bottom: "0",
                             left: "0",
                             right: "0",
                             backgroundColor: "#000",
                             opacity: "0.5"
			 }}></div>
                    <div style={{ zIndex: "1040", position: "absolute" }} className="bg-white max-w-sm rounded overflow-hidden shadow-lg px-8 pt-6 pb-8 mb-4">
                        <div className="px-4 py-4">
                            <div className="mb-4">
                                <p className="block text-gray-700 text-sm font-bold mb-2">
                                    Are you sure?
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <button onClick={() => this.deleteFacility(this.props.facility.id)} className="bg-green-500 hover:bg-green-300 text-white font-bold py-2 px-4 m-2 rounded">Yes</button>
                            <button onClick={() => this.setState({ showWarning: false })} className="bg-red-500 hover:bg-red-300 text-white font-bold py-2 px-4 m-2 rounded">No</button>
                        </div>
                    </div>
                </div>
            )
        }
        else if (this.props.facility) {
            return (
                <div>
                    <SEO title={this.props.facility.name} />
                    {
                        <div key={this.props.facility.id}>
                            <div className="mb-4">
                                <div className="block text-gray-700 text-sm font-bold mb-2">
                                    Facility Name
                                </div>
                                <input value={this.state.name} aria-label="name" className="mr-2 leading-tight" onChange={(e) => this.setState({ name: e.target.value })} />
                            </div>
                            <div className="mb-4">
                                <div className="block text-gray-700 text-sm font-bold mb-2">
                                    State
                                </div>
                                <input value={this.state.state} aria-label="state" className="mr-2 leading-tight" onChange={(e) => this.setState({ state: e.target.value })} />
                            </div>
                            <div className="mb-4">
                                <div className="block text-gray-700 text-sm font-bold mb-2">
                                    Equipment
                                </div>
                                <div>
                                    <input value={this.state.selectedEquipment ? this.state.selectedEquipment : undefined} onChange={(event) => this.setState({ selectedEquipment: event.target.value })} aria-label="equipment" className="mt-1 mr-2 shadow appearance-none border rounded w-full max-w-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" list="equipment" />
                                    <datalist id="equipment">
                                        {this.props.equipmentList.data.equipments.map(equipment => {
                                            let show = true
                                            this.state.equipment.map(eq => {
                                                if (eq.id === equipment.id) {
                                                    show = false
                                                }
                                                return true
                                            })
                                            if (show) {
                                                return (<option aria-label={equipment.name} key={equipment.id} value={equipment.name} />)
                                            }
                                            return null
                                        })}
                                    </datalist>
                                    <button onClick={(event) => this.handleAddEquipment(event)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                        Add
                                    </button>
                                    <div>
                                        <ol className="list-decimal">
                                            {this.state.equipment.map((equipment, index) => (
                                                <li key={index}>
                                                    <div>
                                                        {equipment.name}
                                                        <button onClick={() => this.handleRemoveEquipment(equipment)} className="px-2 text-red-500">Remove</button>
                                                    </div>
                                                </li>
                                            ))}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="block text-gray-700 text-sm font-bold mb-2">
                                    Notes
                                </div>
                                <textarea onChange={(e) => this.setState({ notes: e.target.value })} className="resize border rounded focus:outline-none focus:shadow-outline h-40 w-64" value={this.state.notes ? this.state.notes : undefined} aria-label="notes" placeholder="Type your notes here..." />
                            </div>
                        </div>
                    }
                    <button onClick={() => this.updateFacility()} className="bg-green-500 hover:bg-green-300 text-white font-bold py-2 px-4 mr-2 rounded">Save</button>
                    <button onClick={() => this.setState({ showWarning: true })} className="bg-red-500 hover:bg-red-300 text-white font-bold py-2 px-4 mr-2 rounded">Delete</button>
                    <button onClick={() => this.props.setSelectedFacility(null)} className="bg-blue-500 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded">
                        Back
                    </button>
                </div>
            )
        }
        return null
    }
}
