import React, { useState } from 'react'
import SEO from "./seo"
import { gql } from "apollo-boost"
import { ApolloProvider, useMutation, useQuery } from 'react-apollo'
import FacilityList from './facility-list'
import FacilityModal from './facility-modal'
import FacilityDetails from './facility-details'

const GET_FACILITIES = gql`
    query facilitys {
        facilitys (orderBy: {sequence: asc}) {
            facilities {
                id
                name
                nda
                status
                state
                sequence
                proforma
                contract
                startDate
                notes
                unitRate
                contractCfo
                contractCeo
                cdm
                equipment {
                    id
                    name
                }
            }
        }
    }
`

const CREATE_FACILITY = gql`
	mutation createFacility($facility: FacilityInput!) {
		createFacility(facility: $facility) {
            id
		}
	}
`

const UPDATE_FACILITY = gql`
	mutation updateFacility($facility: FacilityUpdateInput!) {
		updateFacility(facility: $facility) {
            id
		}
	}
`

const DELETE_FACILITY = gql`
    mutation deleteFacility($id: ID!) {
        deleteFacility(id: $id) {
            id
        }
    }
`

const GET_EQUIPMENT = gql`
    query equipments {
        equipments {
            id
            name
        }
    }
`

const IndexPage = () => {
    const [show, setShow] = useState(false)
    const [selectedFacility, setSelectedFacility] = useState(null)
    const [facilities, setFacilities] = useState(null)
    const { refetch } = useQuery(GET_FACILITIES, { variables: { orderBy: `sequence_ASC`, fetchPolicy: 'network-only' }, onCompleted: (data) => setFacilities(data.facilitys.facilities) })
    const [createFacility] = useMutation(CREATE_FACILITY)
    const [updateFacility] = useMutation(UPDATE_FACILITY)
    const [deleteFacility] = useMutation(DELETE_FACILITY)
    const equipmentList = useQuery(GET_EQUIPMENT)

    if (selectedFacility) {
        return (
            <FacilityDetails refetch={refetch} setFacilities={setFacilities} equipmentList={equipmentList} setSelectedFacility={setSelectedFacility} updateFacility={updateFacility} deleteFacility={deleteFacility} facilities={facilities} facility={selectedFacility} />
        )
    }
    else {
        return (
            <ApolloProvider>
                <FacilityModal facilities={facilities} refetch={refetch} setFacilities={setFacilities} createFacility={createFacility} setShow={setShow} show={show} />
                <SEO title="Outreach" />
                <h1>Facility Outreach</h1>
                <FacilityList
                    facilities={facilities}
                    setFacilities={setFacilities}
                    updateFacility={updateFacility}
                    setSelectedFacility={setSelectedFacility}
                />
                <button onClick={() => setShow(true)} className="bg-blue-500 hover:bg-blue-300 text-white font-bold py-2 px-4 mt-4 rounded">Add</button>
            </ApolloProvider>
        )
    }
}

export default IndexPage
