import { LocationsUnitsProvider } from '../../utils/unitUtils';
import { getManyTickets } from './report-data-utils';

import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';


import { saveAs } from "file-saver";

import { monthFormatter, dateFormatter } from './report-utils';

import { useQuery } from 'react-apollo';
import { GET_KAREO_LOCATIONS } from '../queries/queries';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        marginRight: 0
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
        flexGrow: 1,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    flexContainer: {
        display: 'flex'
    },
    flexItem: {
        width: '100%',
        flexGrow: 1,
        margin: 8,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

function writeToTemplate(rows) {
    let csvContent = rows.map(e => e.join(",")).join("\n");

    csvContent = csvContent.replace(/""/g, '');

    // console.log(`csvContent = `, csvContent);

    let csvData = new Blob([csvContent], { type: 'text/csv' });
    let csvUrl = URL.createObjectURL(csvData);
    let link = document.createElement("a");
    link.setAttribute("href", csvUrl);
    link.setAttribute("download", `HPM-Charge-Tickets-Report_${(new Date()).toLocaleDateString('en-US')}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
}


export default function ChargeTicketsReportDialog() {
    const [open, setOpen] = React.useState(false);
    const [isBuilding, setIsBuilding] = React.useState(false);
    const [trainingChecked, setTrainingChecked] = React.useState(false);
    const kareoLocationsQuery = useQuery(GET_KAREO_LOCATIONS, { variables: { filter: "" } })
    const [kareoFacilityStatePairs, setKareoFacilityStatePairs] = useState({});

    useEffect(() => {
        getKareoLocationPairs();
    }, [])

    async function getKareoLocationPairs() {
        try {
            const response = await kareoLocationsQuery.refetch()
            const locationPairs = response.data.kareoLocations
                .filter(x => x.id !== "0")
                .reduce((pairs, current) => {
                    pairs[current.practiceName.trim()] = current.facilityState;
                    return pairs;
                }, {});
            setKareoFacilityStatePairs(locationPairs);
        } catch (error) {
            console.log(error);
        }
    }

    const handleTrainingChange = (event) => {
        setTrainingChecked(event.target.checked);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();

    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    async function buildReport() {
        setIsBuilding(true);

        await LocationsUnitsProvider.refreshData();

        console.log(`Building Report...`);

        selectedStartDate.setHours(0, 0, 0, 1);
        selectedEndDate.setHours(23, 59, 59, 0);

        // adjust query time to UTC offset, ticket dates are all UTC @ midnight w.r.t. TZ where they were created
        var offsetTime = new Date(selectedStartDate.getTime() - selectedStartDate.getTimezoneOffset() * 60 * 1000);

        console.log(`START DATE = ${selectedStartDate}`);
        console.log(`EFEECTIVE START DATE = ${offsetTime}`);
        console.log(`END DATE = ${selectedEndDate}`);

        let reportData
        try {
            let chargeTickets = await getManyTickets(offsetTime, selectedEndDate);
            for (let i = 0; i < chargeTickets.length; i++) {
                const currentFacility = chargeTickets[i].location.trim()
                chargeTickets[i]["state"] = kareoFacilityStatePairs[currentFacility]
            }
            reportData = await buildReportData(chargeTickets);
            // console.log(`reportData = `, reportData);
        } catch (e) {
            setIsBuilding(false);
            throw e;
        }

        console.log(`Writing report data to file...`);
        try {
            writeToTemplate(reportData);
        } catch (e) {
            setIsBuilding(false);
            throw e;
        }

        console.log(`Done!`);

        setIsBuilding(false);
        handleClose();
    }

    function buildReportData(tickets) {

        console.log(`Building report for (${tickets.length}) charge tickets...`);

        let chargeTickets = tickets;
        if (!trainingChecked) {
            // console.log(`charge tickets (${chargeTickets.length}) =`, chargeTickets);
            chargeTickets = tickets.filter(x => !x.location.toLowerCase().includes('training') && !x.patientName.toLowerCase().includes('training') && !x.location.toLowerCase().includes('test'));
            // console.log(`charge tickets without training data (${chargeTickets.length}) =`, chargeTickets);
        }

        let csv = [];
        let byMonth = {};
        let totalUnits = 0;
        csv.push(['HPM ID', 'Procedure Date', 'Provider', 'Location', 'Service Location Name', 'State',
            'Patient Name', 'MRN', 'Insurance', 'Procedures',
            'CPT 1', 'CPT 2', 'CPT 3', 'CPT 4', 'CPT 5', 'CPT 6', 'CPT 7', 'CPT 8',
            'CPT Units 1', 'CPT Units 2', 'CPT Units 3', 'CPT Units 4', 'CPT Units 5', 'CPT Units 6', 'CPT Units 7', 'CPT Units 8',
            'Total Units', 'Excess Units',
            'Created Date', 'Modified Date',
            'Blind Injection Performed', 'Physical Therapy Ordered', 'Labs Ordered', 'Procedure Ordered', 'Imaging Ordered', // checkboxes
            'Ord CPT 1', 'Ord CPT 2', 'Ord CPT 3', 'Ord CPT 4', 'Ord CPT 5', 'Ord CPT 6', 'Ord CPT 7', 'Ord CPT 8', 'Ord CPT 9', 'Ord CPT 10', // additional orders during clinic visit
            'Ord CPT 11', 'Ord CPT 12', 'Ord CPT 13', 'Ord CPT 14', 'Ord CPT 15', // additional orders during clinic visit
            'Type', 'Days',
            'Imaging Equipment'
        ]);

        if (trainingChecked) {
            csv[0].push('Trainer');
            csv[0].push('Student Type');
        }

        chargeTickets.map((chargeTicket => {
            let row = []
            let codes = new Array(8).fill('');
            let csvUnits = new Array(8).fill('');
            let additionalCodes = new Array(15).fill('');
            const isClinic = JSON.stringify(chargeTicket.chargeticketcpt).includes("\"code\":\"99")
            const providerType = `${chargeTicket.user.role[0].id}` === '3' ? "CRNA" : "PA"
            let month = monthFormatter.format(new Date(chargeTicket.date))
            let units = 0
            let excessUnits = 0;
            let imageOrd, procOrd, ptOrd, labOrd, blindInjOrd = false;

            chargeTicket.chargeticketcpt.map((chargeTicketCpt) => {
                const cptUnits = LocationsUnitsProvider.getUnits(chargeTicket, chargeTicketCpt, providerType, chargeTicket.location, isClinic);
                units += cptUnits;

                if (!isClinic) {
                    codes[chargeTicketCpt.sequence - 1] = chargeTicketCpt.cpt.code
                    csvUnits[chargeTicketCpt.sequence - 1] = cptUnits
                }

                else if (chargeTicketCpt.cpt.code.startsWith("99")) {
                    // clinic visits should only have one "MAIN" charge code
                    codes[0] = chargeTicketCpt.cpt.code;
                    csvUnits[0] = cptUnits;
                }

                if (isClinic) {
                    // additional codes for clinic visits
                    if (chargeTicketCpt.sequence > 1) {
                        additionalCodes[chargeTicketCpt.sequence - 2] = chargeTicketCpt.cpt.code;
                    }

                    if (chargeTicketCpt.cpt.id === "161") blindInjOrd = true;
                    if (chargeTicketCpt.cpt.id === "150") ptOrd = true;
                    if (chargeTicketCpt.cpt.id === "149") labOrd = true;
                    if (chargeTicketCpt.cpt.class === "CRNA PAIN MANAGEMENT PROCEDURES") procOrd = true;
                    if (chargeTicketCpt.cpt.class === "MED IMAGING") imageOrd = true;
                }

                return true;
            })

            if (units > 0) {
                // if (units > 40) {
                //     excessUnits = units - 40
                //     units = 40
                // }
                units = LocationsUnitsProvider.applyUnitCap(units, chargeTicket.location);

                byMonth[month] ? byMonth[month] += units : byMonth[month] = units
                totalUnits += units
            }

            row.push(chargeTicket.id);
            row.push(dateFormatter.format(new Date(chargeTicket.date)));
            row.push(chargeTicket.user.firstName + " " + chargeTicket.user.lastName);
            row.push(`"${chargeTicket.location}"`);
            row.push(`"${chargeTicket.serviceLocationName === 'N/A' ? chargeTicket.location : chargeTicket.serviceLocationName}"`);
            row.push(`"${chargeTicket.state}"`)
            row.push(`"${chargeTicket.patientName}"`);
            row.push(chargeTicket.mrn);
            row.push(`"${chargeTicket.insurance}"`);
            row.push(chargeTicket.procedures === "null" ? null : `"${chargeTicket.procedures}"`);
            row.push(codes.join(','));
            row.push(csvUnits.join(','));
            row.push(units);
            row.push(excessUnits);
            row.push(dateFormatter.format(new Date(chargeTicket.createdDate)));
            row.push(chargeTicket.createdDate === chargeTicket.modifiedDate ? '' : dateFormatter.format(new Date(chargeTicket.modifiedDate)));

            // checkboxes
            row.push(blindInjOrd ? 1 : '');
            row.push(ptOrd ? 1 : '');
            row.push(labOrd ? 1 : '');
            row.push(procOrd ? 1 : '');
            row.push(imageOrd ? 1 : '');

            // additional rows for clinic visits that also have other CPTs ordered:
            row.push(additionalCodes.join(','));

            row.push(isClinic ? 'Clinic' : 'Procedure');
            row.push(`${chargeTicket.user.firstName}  ${chargeTicket.user.lastName} ${dateFormatter.format(new Date(chargeTicket.date))}`);
            row.push(`${chargeTicket.imagingEquipment ? chargeTicket.imagingEquipment : ''}`);

            if (trainingChecked) {
                row.push(`${chargeTicket.trainer ? chargeTicket.trainer : ''}`);
                row.push(`${chargeTicket.studentType ? chargeTicket.studentType : ''}`);
            }

            csv.push(row);

            return true;
        }));

        console.log(`units analytics: `, { total: totalUnits, byMonth: byMonth });

        return csv;
    }


    if (isBuilding) {
        return (
            <div>
                <Card className={classes.root}>
                    <CardActionArea onClick={handleClickOpen}>
                        <img src={require('../../images/Charge-Tickets-Report.png').default} />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Charge Tickets
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Provides the charge tickets over some date range.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>


                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Building Charge Tickets Report</DialogTitle>
                    <DialogContent>
                        <div className={classes.flexCenter}>
                            <CircularProgress />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

    return (
        <div>
            <Card className={classes.root}>
                <CardActionArea onClick={handleClickOpen}>
                    <img src={require('../../images/Charge-Tickets-Report.png').default} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Charge Tickets
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Provides the charge tickets over some date range.
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create Charge Tickets Report</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The Charge Tickets report shows all of the charge tickets over a specified time.
                    </DialogContentText>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={trainingChecked}
                            onChange={handleTrainingChange}
                            />}
                            label="Include Training Tickets?" />
                    </FormGroup>
                    <div className={classes.flexContainer}>
                        <div className={classes.flexItem}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Start Date"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={classes.flexItem}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.flexItem}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="End Date"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => buildReport()} color="primary" variant="contained">
                        Build Report
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
